import { useEffect, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "./Reducers/ErrorReducer";
import { QuizArr } from "../components/Quiz/main-quiz";

const useFetch = () => {
	let location = useLocation(),
		navigate = useNavigate(),
		{ auth, category } = useSelector(s => s),
		[examData, setExamData] = useState(null),
		[examLoad, setExamLoad] = useState(true),
		dispatch = useDispatch(),
		[isQuestion, setIsQuestion] = useState(0),
		[isSelected, setIsSelected] = useState(null),
		[answerArr, setAnswerArr] = useState(null),
		[newAnswer, setNewAnswer] = useState([]),
		[loading, setLoading] = useState(false),
		[result, setResult] = useState(null),
		[quizId, setQuizId] = useState(null),
		[options, setOptions] = useState(null),
		[k, setK] = useState(false),
		[duration] = useState(
			category?.quizSettings?.docs?.find(
				it => it?.type === location?.state?.type
			)?.quizTime ||
				category?.config?.docs?.[0]?.timerPerQuestion ||
				10
		),
		handleOptions = (d, ty) => {
			if (d?.skipQuestion && ty === "skipQuestion") {
				if (isQuestion !== examData?.length - 1) {
					setIsSelected(null);
					setIsQuestion(isQuestion + 1);
					setK(i => !i);
				} else handleSubmit();
			}
			if (d?.resetTime) setK(i => !i);
			setOptions({ ...d, [`${ty}No`]: isQuestion });
		},
		handleTimeUp = () => {
			if (isQuestion !== examData?.length - 1) {
				setIsSelected(null);
				setK(i => !i);
				setIsQuestion(isQuestion + 1);
				setNewAnswer([]);
			} else handleSubmit();
		};

	let handleSubmit = async e => {
		try {
			let res;
			// console.log({ query });
			let { type, typeID, key } = location?.state;
			let d = examData?.[isQuestion];
			let findQue = [
				...answerArr?.map(item =>
					item?.questionID === d?._id
						? {
								...item,
								[type === "guessTheWord" ? "answer" : "option"]:
									type === "guessTheWord"
										? newAnswer?.map(item => item?.item)?.join("")
										: isSelected?.answer,
						  }
						: item
				),
			];
			console.log({ findQue, d });
			setLoading(true);
			let dd = {
				questions: findQue,
			};
			if (type === "contest") dd.typeID = typeID;
			else if (key) dd.key = key?.toString();
			else {
				dd.type = type;
			}
			res = await axios.post(
				`/api/v1/${
					["exam", "contest"]?.includes(type)
						? `${type}Submission`
						: `submission`
				}`,
				{ ...dd }
			);

			toast.success(res?.data?.message);
			setLoading(false);
			setIsSelected(null);
			setResult(res?.data?.data?.questions);
			setQuizId(res?.data?.data?._id || res?.data?._id);
			setIsQuestion(isQuestion + 1);
			setK(i => !i);
			console.log({ result: res?.data });
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
			setLoading(false);
		}
	};

	const handleNext = useCallback(() => {
		if (isSelected) {
			let d = examData?.[isQuestion];
			let findQue = [
				...answerArr?.map(item =>
					item?.questionID === d?._id
						? {
								...item,
								option: isSelected?.answer,
						  }
						: item
				),
			];
			setAnswerArr(findQue);
			if (isQuestion !== examData?.length - 1) {
				setIsSelected(null);
				setK(i => !i);
				setIsQuestion(isQuestion + 1);
			} else handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelected, isQuestion, examData, answerArr]);

	const handleNextQuess = () => {
		if (newAnswer?.length !== examData?.[isQuestion]?.answer?.length) return;
		let newArr = examData;
		newArr[isQuestion] = { ...examData?.[isQuestion], newAnswer };
		setExamData(newArr);
		let d = examData?.[isQuestion];
		let findQue = [
			...answerArr?.map(item =>
				item?.questionID === d?._id
					? {
							...item,
							answer: newAnswer?.map(item => item?.item)?.join(""),
					  }
					: item
			),
		];
		setAnswerArr(findQue);
		if (isQuestion !== examData?.length - 1) {
			setIsQuestion(isQuestion + 1);
			setNewAnswer([]);
		} else handleSubmit();
	};

	const handlePrev = () => {
		if (isQuestion > 0) {
			setIsQuestion(isQuestion - 1);
			setIsSelected(null);
			setK(i => !i);
		}
	};

	// console.log({ state: location?.state, answerArr, isSelected });

	useEffect(() => {
		if (!location?.state || !auth?.isAuth) return navigate(-1);
		if (location?.state) {
			if (!QuizArr?.includes(location?.state?.type)) return navigate(-1);
			let { subCategory, type, level, typeID, key, category } = location?.state;
			let getQuestions = async () => {
				let query = `${
					type === "exam"
						? ""
						: `?_populate=image&_populate=category&_populate=subCategory`
				}${key ? `?key=${key}` : ""}${
					!typeID && !key ? `&_populate=language&_populate=audioFile` : ""
				}
				${category ? `&category=${category}` : ""}
				${subCategory ? `&subCategory=${subCategory}` : ""}${
					level ? `&level=${level}` : ""
				}${typeID ? `&typeID=${typeID}` : ""}`;
				try {
					let res;
					// console.log({ query });
					res = await axios.get(
						`/api/v1/${
							type === "exam"
								? "examQuestion"
								: type === "contest"
								? "contestQuestions"
								: type === "funAndLearn"
								? "funAndLearnQuestion"
								: type
						}${query}`
					);
					if (res?.data?.data?.docs?.length > 0) {
						setExamData(res?.data?.data?.docs);
						setAnswerArr([
							...res?.data?.data?.docs?.map(item => {
								return { questionID: item?._id };
							}),
						]);
						console.log({ dd: res?.data?.data });
						setExamLoad(false);
						toast.success(res?.data?.message);
						setLoading(false);
						setIsQuestion(0);
						setK(i => !i);
					} else {
						toast.info("No question(s) found for selection");
						navigate("/");
					}
					// console.log({ result: res?.data });
				} catch (err) {
					if (err) console.log({ error: err.response?.data, err });
					if (err?.response?.status === 429) toast.error(err?.response?.data);
					let error = err.response?.data?.error;
					if (error) {
						dispatch(returnErrors({ error, status: err?.response?.status }));
					} else {
						toast.error(err?.response?.data?.message);
					}
					navigate("/");
				}
			};
			getQuestions();
		}
	}, [location?.state, navigate, auth?.isAuth, dispatch]);

	useEffect(() => {
		if (isSelected && examData?.length - 1 !== isQuestion) {
			const timer = setTimeout(() => handleNext(), 1000);
			return () => clearTimeout(timer);
		}
	}, [isSelected, handleNext, isQuestion, examData]);

	// useEffect(() => {
	// 	if (!isSelected) {
	// 		let d = examData?.[isQuestion];
	// 		if (d) {
	// 			let findQue = answerArr?.find(it => it?.questionID === d?._id);
	// 			if (findQue) {
	// 				let newAns = {};
	// 				newAns.answer = findQue?.option;
	// 				if (findQue?.option)
	// 					newAns.option =
	// 						d?.[findQue?.option - 1]?.value || d?.[findQue?.option - 1];

	// 				if (d?.type === "guessTheWord") {
	// 					if (findQue?.answer) setNewAnswer(findQue?.answer?.split(""));
	// 				} else setIsSelected(newAns);
	// 			}
	// 		}
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isSelected]);

	return {
		examData,
		isQuestion,
		handleNext,
		isSelected,
		answerArr,
		setAnswerArr,
		setIsSelected,
		examLoad,
		loading,
		handlePrev,
		result,
		handleNextQuess,
		newAnswer,
		setNewAnswer,
		type: location?.state?.type,
		options,
		setOptions: handleOptions,
		handleTimeUp,
		duration,
		k,
		quizId,
	};
};

export default useFetch;
