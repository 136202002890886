import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ExamQuizDuetMode } from "../../components/Quiz/exam-quiz";
// import MainQuiz from "../../components/Quiz/main-quiz";

const DuetQuiz = () => {
	return (
		<section>
			<Header />
			<ExamQuizDuetMode />
			<Footer />
		</section>
	);
};

export default DuetQuiz;
