import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Star from "../assets/star.png";
import Back from "../assets/back.svg";
import Review from "../assets/review.svg";
import Share from "../assets/share.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { returnErrors } from "../data/Reducers/ErrorReducer";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

const Scores = () => {
  return (
    <div>
      <Header />
      <MainSection />
      <Footer />
    </div>
  );
};

const MainSection = () => {
  const infos = [
    {
      title: "Score accuracy",
      value: `89%`,
    },
    {
      title: "Total Question",
      value: "09",
    },
    {
      title: "Correct",
      value: "02",
    },
    {
      title: "Wrong",
      value: "02",
    },
  ];

  return (
    <div className="tw-min-h-screen tw-flex tw-justify-center tw-items-center tw-py-12 gamebg tw-w-full">
      <div className="tw-p-10 tw-rounded-xl category-bg container">
        <div className="tw-w-5/6 tw-mx-auto tw-grid tw-h-full tw-items-center lg:tw-grid-cols-2 tw-gap-8">
          <img src={Star} alt="" className="" />
          <div>
            <h1 className="tw-text-5xl tw-text-black tw-font-normal nexa">
              Results
            </h1>
            <h2 className="tw-text-3xl tw-text-[#004994] tw-pt-4 nexa tw-font-bold">
              HURRAY!!!
            </h2>
            <div className="tw-mt-4">
              <h6 className="tw-text-2xl tw-text-plain tw-font-normal nexa">
                Congratulations lola Aisha
              </h6>
              <h6 className="tw-text-2xl tw-text-plain tw-font-normal nexa">
                You Performed Excellently!
              </h6>
            </div>
            <div className="tw-mt-6 tw-grid tw-grid-cols-2 tw-gap-4">
              {infos?.map((it, i) => (
                <div
                  key={i}
                  style={{
                    boxShadow: "0px 0px 6.7px 0px rgba(0, 0, 0, 0.15)",
                  }}
                  className="tw-rounded-md tw-h-16 tw-px-3 tw-flex tw-gap-3 tw-items-center tw-relative tw-bg-white"
                >
                  <div>
                    <strong
                      className={`tw-text-3xl tw-font-normal nexa ${
                        it?.title === "Score accuracy"
                          ? "tw-text-[#31BF51]"
                          : it?.title === "Total Question"
                          ? "tw-text-[#3E91E6]"
                          : it?.title === "Correct"
                          ? "tw-text-[#3E91E6]"
                          : "tw-text-[#F04F23]"
                      }`}
                    >
                      {it?.value}
                    </strong>
                  </div>
                  <div className="">
                    <small className="tw-text-sm nexa tw-font-medium tw-text-plain">
                      {it?.title}
                    </small>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.25",
              }}
              className="tw-w-full tw-py-3 tw-flex tw-justify-between tw-mt-10 tw-rounded-2xl tw-px-5"
            >
              <div className="tw-w-">
                <img src={Back} alt="" className="-tw-mb-2 tw-cursor-pointer" />
                <span className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                  Back
                </span>
              </div>
              <div>
                <img
                  src={Review}
                  alt=""
                  className="-tw-mb-2 tw-cursor-pointer"
                />
                <span className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                  Review answer
                </span>
              </div>
              <div>
                <img
                  src={Share}
                  alt=""
                  className="-tw-mb-2 tw-cursor-pointer"
                />
                <span className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                  Share score
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ScoreComp = ({ info, examData, realtime, quizId }) => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    { state } = useLocation(),
    [loading, setLoading] = useState(null);

  let { auth } = useSelector((s) => s),
    // eslint-disable-next-line no-unused-vars
    [total, setTotal] = useState(null),
    [percent, setPercent] = useState(null);
  // console.log({ info, examData, localState: state });
  useEffect(() => {
    if (info) {
      let r = info?.filter((it) => it?.correct)?.length,
        t = (Number(r) / Number(info?.length)) * 100,
        p =
          Number(auth?.user?.points) +
          Number(info?.reduce((ac, i) => (ac += i?.points), 0));
      setPercent(t);
      setTotal(p);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const infos = [
      {
        title: "Score accuracy",
        value: `${Number(percent || 0).toFixed(0) || ""}%`,
      },
      {
        title: "Total Question",
        value: info?.length || 0,
      },
      {
        title: "Correct",
        value: info?.filter((it) => it?.correct)?.length || 0,
      },
      {
        title: "Wrong",
        value: info?.filter((it) => !it?.correct)?.length || 0,
      },
    ],
    { width, height } = useWindowSize();
  return (
    <div className="tw-p-10 tw-rounded-xl category-bg container">
      {percent && Number(percent).toFixed(0) >= 50 ? (
        <Confetti width={width} height={height} />
      ) : null}
      <div className="tw-w-5/6 tw-mx-auto tw-grid tw-h-full tw-items-center lg:tw-grid-cols-2 tw-gap-8">
        <img src={Star} alt="" className="" />
        <div>
          <h1 className="tw-text-5xl tw-text-black tw-font-normal nexa">
            Results
          </h1>
          {percent && Number(percent).toFixed(0) >= 50 ? (
            <h2 className="tw-text-3xl tw-text-[#004994] tw-pt-4 nexa tw-font-bold">
              HURRAY!!!
            </h2>
          ) : null}
          {!realtime && (
            <>
              <div className="tw-mt-4">
                <h6 className="tw-text-2xl tw-text-plain tw-font-normal nexa">
                  {percent && Number(percent).toFixed(0) >= 50
                    ? "Congratulations"
                    : "Hey,"}{" "}
                  {auth?.user?.lastName}
                </h6>
                <h6 className="tw-text-2xl tw-text-plain tw-font-normal nexa">
                  You Performed{" "}
                  {percent && Number(percent).toFixed(0) >= 50
                    ? "Excellently"
                    : percent && Number(percent).toFixed(0) >= 40
                    ? "Averagely"
                    : "Poorly"}
                  !
                </h6>
              </div>
              <div className="tw-mt-6 tw-grid lg:tw-grid-cols-2 tw-gap-4">
                {infos?.map((it, i) => (
                  <div
                    key={i}
                    style={{
                      boxShadow: "0px 0px 6.7px 0px rgba(0, 0, 0, 0.15)",
                    }}
                    className="tw-rounded-md tw-h-16 tw-px-3 tw-flex tw-gap-3 tw-items-center tw-relative tw-bg-white"
                  >
                    <div>
                      <strong
                        className={`tw-text-3xl tw-font-normal nexa ${
                          it?.title === "Score accuracy"
                            ? "tw-text-[#31BF51]"
                            : it?.title === "Total Question"
                            ? "tw-text-[#3E91E6]"
                            : it?.title === "Correct"
                            ? "tw-text-[#3E91E6]"
                            : "tw-text-[#F04F23]"
                        }`}
                      >
                        {it?.value}
                      </strong>
                    </div>
                    <div className="">
                      <small className="tw-text-sm nexa tw-font-medium tw-text-plain">
                        {it?.title}
                      </small>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {realtime && (
            <>
              <div className="tw-grid lg:tw-grid-cols-2 tw-gap-6">
                {info?.map((it, x) => (
                  <div
                    key={x}
                    style={{
                      boxShadow: "0px 0px 6.7px 0px rgba(0, 0, 0, 0.15)",
                    }}
                    className="tw-rounded-md tw-min-h-16 tw-px-3 tw-flex tw-gap-3 tw-items-center tw-relative tw-bg-white tw-w-full"
                  >
                    <div className="">
                      <h6 className={`tw-text-3xl k2d tw-font-semibold`}>
                        {it.total}
                      </h6>
                      <h6 className="tw-text-base k2d tw-pb-2 tw-font-semibold tw-text-[#508991]">
                        {it?.user?.[0]?.lastName} {it?.user?.[0]?.firstName}{" "}
                        {auth?.user?._id === it?.user?.[0]?._id && <>(You)</>}
                      </h6>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <div
            style={{
              boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.25",
            }}
            onClick={() =>
              navigate(
                state &&
                  ![
                    "oneOnone",
                    "selfChallenge",
                    "trueOrFalse",
                    "battleMode",
                  ]?.includes(state?.type)
                  ? "/levels"
                  : "/",
                { state }
              )
            }
            className="tw-w-full tw-py-3 tw-flex tw-justify-between tw-mt-10 tw-rounded-2xl tw-px-5"
          >
            <div className="tw-w-">
              <img src={Back} alt="" className="-tw-mb-2 tw-cursor-pointer" />
              <span className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                Back
              </span>
            </div>
            {!realtime && (
              <>
                <div
                  onClick={
                    !loading && quizId
                      ? async () => {
                          try {
                            setLoading(true);
                            let res = await axios.get(
                              `/api/v1/submission/review/${quizId || ""}`
                            );

                            console.log({ res: res?.data });

                            toast.success(res?.data?.message);
                            setLoading(false);
                            navigate("/daily-quiz/review", {
                              state: {
                                info,
                                examData,
                                res: res?.data,
                                prevState: state,
                              },
                            });
                          } catch (err) {
                            setLoading(false);
                            if (err)
                              console.log({ error: err.response?.data, err });
                            if (err?.response?.status === 429)
                              toast.error(err?.response?.data);
                            let error = err.response?.data?.error;
                            if (error) {
                              dispatch(
                                returnErrors({
                                  error,
                                  status: err?.response?.status,
                                })
                              );
                            } else {
                              toast.error(err?.response?.data?.message);
                            }
                          }
                        }
                      : null
                  }
                >
                  {!loading ? (
                    <img
                      src={Review}
                      alt=""
                      className="-tw-mb-2 tw-cursor-pointer"
                    />
                  ) : (
                    <ClipLoader size={30} />
                  )}
                  <span className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                    Review answer
                  </span>
                </div>
              </>
            )}
            <div>
              <img src={Share} alt="" className="-tw-mb-2 tw-cursor-pointer" />
              <span className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                Share score
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scores;
