import { useEffect, useRef, useState } from "react";
import ScoreBoard from "../../Scoreboard";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button, { LoadingLoader } from "../../buttons/button";
import useFetch from "../../../data/useFetch";
// import Letsplay from "../../../assets/letsplay.svg";
// import Ico from "../../../assets/mathico.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../../data/Reducers/ErrorReducer";
// import { QuessComponent } from "../guessTheWord";
import { getUser } from "../../../data/Reducers/UserReducer";
import Countdown from "react-countdown";
import moment from "moment";
import { QuizBtn, QuizMainComponent } from "../../../screens/new-quiz";

const ExamQuiz = () => {
	let location = useLocation(),
		navigate = useNavigate(),
		{ auth } = useSelector(s => s);

	useEffect(() => {
		if (!location?.state || !auth?.isAuth) navigate(-1);
	}, [location?.state, navigate, auth?.isAuth]);

	let {
		examData,
		handleNext,
		isQuestion,
		setIsSelected,
		isSelected,
		examLoad,
		loading,
		handlePrev,
		result,
	} = useFetch();

	if (examLoad && !examData) return <LoadingLoader />;

	// const data = [
	// 	{
	// 		question: "What is a Fraction ?",
	// 		options: [
	// 			"A. A type of maths",
	// 			"B. A type of trigonometry",
	// 			"C. A type of calculation ",
	// 			"D. A type of internet connection",
	// 		],
	// 		answer: "answer1",
	// 	},
	// 	{
	// 		question: "what is a fraction ?",
	// 		options: [
	// 			"A. A type of maths",
	// 			"B. A type of trigonometry",
	// 			"C. A type of calculation ",
	// 			"D. A type of internet connection",
	// 		],
	// 		answer: "answer1",
	// 	},
	// 	{
	// 		question: "what is a fraction ?",
	// 		options: [
	// 			"A. A type of maths",
	// 			"B. A type of trigonometry",
	// 			"C. A type of calculation ",
	// 			"D. A type of internet connection",
	// 		],
	// 		answer: "answer1",
	// 	},
	// ];
	return (
		<section className="tw-bg-[#F5F5F5] tw-py-20 tw-w-full">
			<div className="container tw-bg-[#FAFAFA] tw-p-8">
				{/* <h1 className="tw-text-xl segoe tw-font-bold tw-text-[#1b1b1b]">
					{isQuestion < examData?.length ? "Questions" : ""}
				</h1> */}
				{isQuestion < examData?.length ? (
					<div>
						<h3 className="inter tw-pt-2 tw-text-base tw-font-normal tw-text-[#162023] tw-text-opacity-70">
							Question {isQuestion + 1}
						</h3>
						<div className="lg:tw-w-3/4 tw-mx-auto tw-mt-6 myShadow tw-py-8 tw-px-4 tw-rounded-lg tw-bg-[#fdfdfd]">
							<h2 className="tw-text-xl tw-font-semibold poppins tw-text-[#162023]">
								Q.{isQuestion + 1} /{" "}
								<span className="tw-text-xl tw-font-semibold tw-text-opacity-70 poppins tw-text-[#162023]">
									{examData?.length}
								</span>
							</h2>
							<h3 className="tw-text-xl segoe tw-font-semibold tw-text-[#162023] tw-pt-3">
								{examData?.[isQuestion]?.question}
							</h3>
							<div className="tw-space-y-5 tw-mt-6">
								{examData?.[isQuestion]?.options?.map((option, i) => (
									<div
										onClick={() =>
											setIsSelected({
												answer: i + 1,
												option: option?.value || option,
											})
										}
										key={i}
										className={
											isSelected?.option === (option?.value || option) &&
											isSelected?.answer - 1 === i
												? "tw-bg-[#008080] tw-py-3 tw-w-full tw-rounded-2xl tw-px-6"
												: " tw-py-3 tw-w-full tw-rounded-2xl hover:tw-bg-[#008080] tw-px-6 tw-bg-[#F0F7F9]"
										}>
										<h4
											className={`tw-text-sm poppins tw-pt-1 tw-font-medium ${
												isSelected?.option === (option?.value || option) &&
												isSelected?.answer - 1 === i
													? "tw-text-white"
													: "tw-text-[#162023]"
											}`}>
											{option?.value || option}
										</h4>
									</div>
								))}
							</div>
							<div className="tw-flex tw-mt-10 tw-justify-center tw-gap-8">
								<div>
									{isQuestion >= 1 && (
										<button
											onClick={handlePrev}
											className={`tw-h-14 tw-w-44 tw-bg-[#F0F7F9] myShadow tw-rounded-2xl tw-text-[#373535] poppins tw-font-semibold tw-text-base ${
												isQuestion === 0 ? "tw-hidden" : "tw-block"
											}`}>
											Previous
										</button>
									)}
								</div>
								{/* <button
									onClick={handleNext}
									className={`tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB] 
         
                  `}>
									{isQuestion === examData?.length - 1 ? "Submit" : "Next"}
								</button> */}
								<Button
									onClick={handleNext}
									loading={loading}
									title={
										isQuestion === examData?.length - 1 ? "Submit" : "Next"
									}
									width={
										"tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB]"
									}
								/>
							</div>
						</div>
					</div>
				) : (
					<ScoreBoard info={result} examData={examData} />
				)}
			</div>
		</section>
	);
};

export default ExamQuiz;

export const ExamQuizDuetMode = () => {
	let location = useLocation(),
		navigate = useNavigate(),
		{ auth, mainSocket, category } = useSelector(s => s),
		[isSelected, setIsSelected] = useState(null),
		[loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		[newAnswer, setNewAnswer] = useState([]),
		[k, setK] = useState(false),
		[disabled, setIsDisabled] = useState(null),
		[duration] = useState(
			category?.quizSettings?.docs?.find(
				it => it?.type === location?.state?.type
			)?.quizTime ||
				category?.config?.docs?.[0]?.timerPerQuestion ||
				10
		),
		handleTimeUp = () => {
			let dd = {};
			dd.code = location?.state?.code;
			dd.questionID = currentQuestion?._id;
			mainSocket?.socket?.emit("time-up", dd);
			console.log({ duration, currentQuestion, dd }, "time-up");
		},
		audioRef = useRef();

	let handleSubmitEvent = async () => {
		if (currentQuestion?.type !== "guessTheWord") {
			if (disabled) return;
			if (!isSelected) return;
		} else if (newAnswer?.length === 0) return;
		// console.log({ query });
		let { code, type } = location?.state;
		let send = {
			// questions: findQue,
			code: `${code}`,
			questionID: currentQuestion?._id,
		};
		if (currentQuestion?.type === "guessTheWord")
			send.answer = newAnswer?.map(item => item?.item)?.join("");
		else send.optionIndex = isSelected?.answer;

		mainSocket?.socket?.emit(
			location?.state?.type === "groupBattle" ? "group-submit" : "submit",
			send
		);
		setIsDisabled(true);

		console.log(
			{
				type,
				send,
				event:
					location?.state?.type === "groupBattle" ? "group-submit" : "submit",
			},
			"submit-event"
		);
		// setK(i => !i);
	};

	useEffect(() => {
		if (isSelected) handleSubmitEvent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelected]);

	useEffect(() => {
		if (!location?.state || !auth?.isAuth) navigate(-1);
	}, [location?.state, navigate, auth?.isAuth]);

	useEffect(() => {
		if (mainSocket?.quiz) {
			setK(i => !i);
			setIsDisabled(null);
			setIsSelected(null);
			setNewAnswer([]);
		}
	}, [mainSocket?.quiz]);

	if (!mainSocket?.quiz && !mainSocket?.complete) return <LoadingLoader />;

	const currentQuestion = mainSocket?.quiz;

	if (currentQuestion)
		if (
			(!currentQuestion?.question || !currentQuestion?._id) &&
			currentQuestion?.totalTime
		) {
			toast.info("No question(s) found");
			navigate("/");
		}

	let handleSubmit = async e => {
		try {
			let res;
			if (currentQuestion?.type !== "guessTheWord") {
				if (!isSelected) return;
			} else if (newAnswer?.length === 0) return;
			// console.log({ query });
			let { code, type } = location?.state;
			// let d = examData?.[isQuestion];
			// let findQue = [
			// 	...answerArr?.map(item =>
			// 		item?.questionID === d?._id
			// 			? {
			// 					...item,
			// 					[type === "guessTheWord" ? "answer" : "option"]:
			// 						type === "guessTheWord"
			// 							? newAnswer?.map(item => item?.item)?.join("")
			// 							: isSelected?.answer,
			// 			  }
			// 			: item
			// 	),
			// ];
			// console.log({ findQue, d });
			setLoading(true);
			let send = {
				// questions: findQue,
				code: `${code}`,
				questionID: currentQuestion?._id,
			};
			if (currentQuestion?.type === "guessTheWord")
				send.answer = newAnswer?.map(item => item?.item)?.join("");
			else send.optionIndex = isSelected?.answer;
			res = await axios.post(`/api/v1/realTimeSubmission`, send);

			toast.success(res?.data?.message);
			setLoading(false);
			setIsSelected(null);
			setNewAnswer([]);
			console.log({ result: res?.data }, type);
			// if (Array?.isArray(res?.data?.data)) navigate(-1);
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
			// navigate(-1);
			setLoading(false);
		}
	};
	return (
		<QuizMainComponent
			currentQuestion={currentQuestion}
			duration={duration}
			handleNext={handleSubmitEvent}
			// handlePrev={handlePrev}
			handleTimeUp={handleTimeUp}
			// isQuestion={isQuestion}
			// examData={examData}
			k={k}
			audioRef={audioRef}
			isSelected={isSelected}
			setIsSelected={setIsSelected}
			newAnswer={newAnswer}
			setNewAnswer={setNewAnswer}
			// options={options}
			loading={loading}
			// type={type}
			// setOptions={setOptions}
			result={mainSocket?.complete?.data}
			realtime
			disabled={disabled}
			handleSubmit={handleSubmit}
		/>
	);
};

// export const NewIndex = () => {
// 	return (
// 		<section className="tw-bg-[#F5F5F5] tw-py-20 tw-w-full">
// 			<div className="container tw-bg-[#FAFAFA] tw-p-8">
// 				{/* {currentQuestion ? (
// 					<img src={Letsplay} alt="" className="tw-mx-auto" />
// 				) : (
// 					""
// 				)} */}
// 				{currentQuestion ? (
// 					<div className="lg:tw-w-3/4 tw-mx-auto tw-mt-6 myShadow tw-py-8 tw-px-4 tw-rounded-lg tw-bg-[#fdfdfd]">
// 						{!disabled && (
// 							<MainTimer
// 								duration={duration || 10}
// 								handleNext={handleTimeUp}
// 								k={k}
// 							/>
// 						)}
// 						<div className="tw-relative tw-flex tw-justify-center">
// 							{/* <h2 className="tw-text-6xl tw-font-normal segoe tw-uppercase tw-text-[#1b1b1b]">
// 								Question {isQuestion + 1}
// 							</h2> */}
// 							{/* <img
// 								src={Ico}
// 								alt=""
// 								className="tw-absolute tw-right-0 tw-hidden lg:tw-block"
// 							/> */}
// 						</div>
// 						{currentQuestion?.type === "guessTheWord" ? (
// 							<QuessComponent
// 								newAnswer={newAnswer}
// 								setNewAnswer={setNewAnswer}
// 								currentQuestion={currentQuestion}
// 								isQuestion={0}
// 							/>
// 						) : (
// 							<div className="lg:tw-w-96 tw-mx-auto">
// 								<div className="tw-h-24 tw-w-full tw-flex tw-items-center tw-justify-center tw-mt-6 tw-mx-auto tw-bg-[#F3F7FA]">
// 									<h3 className="tw-text-xl segoe tw-font-semibold tw-text-[#162023] tw-rounded-xl  tw-pt-3">
// 										{currentQuestion?.image?.url && (
// 											<img
// 												src={currentQuestion?.image?.url}
// 												alt="Question"
// 												className="tw-mx-auto tw-h-52"
// 											/>
// 										)}
// 									</h3>
// 								</div>
// 								<h4 className="tw-text-sm tw-py-4 tw-font-semibold krub tw-text-center tw-text-[#1b1b1b]">
// 									{currentQuestion?.question}
// 								</h4>
// 								<div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-6">
// 									{currentQuestion?.options?.map((item, i) => (
// 										<div
// 											key={i}
// 											onClick={
// 												!disabled
// 													? () => {
// 															setIsSelected({
// 																answer: i + 1,
// 																option: item?.value,
// 															});
// 														}
// 													: null
// 											}
// 											className={`tw-py-6 tw-cursor-pointer tw-rounded-xl myShadow ${
// 												isSelected?.option === (item?.value || item) &&
// 												isSelected?.answer - 1 === i
// 													? "tw-bg-[#03d8d1]"
// 													: "tw-bg-white"
// 											}`}>
// 											<h5 className="tw-text-base krub tw-font-semibold tw-text-center tw-text-[#1b1b1b]">
// 												{item?.value}
// 											</h5>
// 										</div>
// 									))}
// 								</div>
// 							</div>
// 						)}
// 						{/* <LifeLineOption updateLifeLine={updateLifeLine} /> */}
// 						{currentQuestion?.type === "guessTheWord" && (
// 							<div className="tw-flex tw-mt-10 tw-justify-center tw-gap-8">
// 								<div>
// 									{/* {isQuestion >= 1 && (
// 									<button
// 										onClick={handlePrev}
// 										className={`tw-h-14 tw-w-44 tw-bg-[#F0F7F9] myShadow tw-rounded-2xl tw-text-[#373535] poppins tw-font-semibold tw-text-base ${
// 											isQuestion === 0 ? "tw-hidden" : "tw-block"
// 										}`}
// 									>
// 										Previous
// 									</button>
// 								)} */}
// 								</div>
// 								{/* <button
// 								onClick={handleNext}
// 								className={`tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB]

// 										`}>
// 								{isQuestion === examData.length - 1 ? "Submit" : "Next"}
// 							</button> */}
// 								<Button
// 									onClick={handleSubmitEvent}
// 									loading={loading}
// 									title={"Submit"}
// 									width={
// 										"tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB]"
// 									}
// 								/>
// 								<Button
// 									onClick={handleSubmit}
// 									loading={loading}
// 									title={"Submit"}
// 									width={
// 										"tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB] tw-hidden"
// 									}
// 								/>
// 							</div>
// 						)}
// 					</div>
// 				) : (
// 					<ScoreBoard info={mainSocket?.complete?.data} examData realtime />
// 				)}
// 			</div>
// 		</section>
// 	);
// }

export const LifeLineOption = ({ setOptions }) => {
	let [lifeLine, setLifeLine] = useState({
			fiftyFifty: false,
			audiencePoll: false,
			resetTime: false,
			skipQuestion: false,
		}),
		[points, setPoints] = useState(0),
		{ auth } = useSelector(s => s),
		dispatch = useDispatch(),
		updateLifeLine = line => {
			let deduct = 10;
			if (Number(points) < deduct) return toast.error("Insufficient coin");
			if (lifeLine?.[line]) return toast.warn(`Life line already used`);
			let options = { ...lifeLine, [line]: true };
			setLifeLine(options);
			setOptions(options, line);
			handleDeductPoint(line);
		},
		handleDeductPoint = async line => {
			try {
				let res = await axios.post(`/api/v1/points/deductPoints`, {
					type: line,
				});
				console.log({ da: res?.data });
				dispatch(getUser(res.data));
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
			}
		};

	useEffect(() => {
		setPoints(auth?.user?.points);
	}, [auth?.user]);

	return (
		<>
			<div className="tw-pt-20 tw-w-5/6 tw-mx-auto">
				<div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-4">
					<QuizBtn
						bg={"linear-gradient(180deg, #00474C 0%, #005F65 100%)"}
						onClick={() => updateLifeLine("fiftyFifty")}
						// loading={loading}
						text={"50/50"}
						css={
							"0px 28.341px 56.682px 0px rgba(0, 17, 18, 0.24), 0px 8.817px 8.817px 0px #006368 inset, 0px -8.817px 8.817px 0px #004448 inset"
						}
					/>
					<QuizBtn
						// loading={loading}
						text={"Audience poll"}
						onClick={() => updateLifeLine("audiencePoll")}
					/>
					<QuizBtn
						bg={"linear-gradient(180deg, #B6152B 0%, #F51C3A 100%)"}
						css={
							"0px 28.341px 56.682px 0px rgba(44, 5, 10, 0.25), 0px 9.006px 9.006px 0px #FE1D3D inset, 0px -9.006px 9.006px 0px #AD1429 inset"
						}
						// loading={loading}
						text={"Reset time"}
						onClick={() => updateLifeLine("resetTime")}
					/>
					<QuizBtn
						bg={"linear-gradient(180deg, #FF7F00 0%, #FF7F00 100%)"}
						css={
							"0px 28.341px 56.682px 0px rgba(255, 127, 0, 0.25) 0px 9.006px 9.006px 0px #FE1D3D inset 0px -9.006px 9.006px 0px #AD1429 inset"
						}
						// loading={loading}
						text={"Skip Question"}
						onClick={() => updateLifeLine("skipQuestion")}
					/>
				</div>
			</div>
		</>
	);
};

const Completionist = () => <small>Time up!</small>;
const Renderer = ({ hours, minutes, seconds, completed, handleNext }) => {
	useEffect(() => {
		if (completed) {
			handleNext();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [completed]);

	if (completed) {
		// Render a completed state
		return <Completionist />;
	} else {
		// Render a countdown
		return (
			<small>
				{hours}h:{minutes}m:{seconds}s
			</small>
		);
	}
};

export const MainTimer = ({ duration, handleNext, k }) => {
	return (
		<>
			{duration && (
				<div
					style={{
						boxShadow:
							"0px 28.341px 56.682px 0px rgba(10, 24, 33, 0.24), 0px 8.817px 8.817px 0px #00555A inset, 0px -8.817px 8.817px 0px #00555A inset",
						background: "linear-gradient(180deg, #00555A 0%, #00555A 100%)",
					}}
					className="lg:tw-absolute tw-mb-5 lg:tw-mb-0 lg:tw-top-6 tw-rounded-xl tw-h-10 tw-px-4 tw-flex tw-justify-center tw-right-3">
					<div className="tw-flex tw-h-full lg:tw-w-full tw-justify-center tw-items-center tw-text-white tw-font-bold tw-capitalize tw-text-xl">
						<Countdown
							key={k}
							date={moment().add(duration, "seconds")}
							renderer={da => <Renderer {...da} handleNext={handleNext} />}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export const MainCoinPoints = () => {
	let { auth } = useSelector(s => s);
	return (
		<>
			<div
				style={{
					boxShadow:
						"0px 28.341px 56.682px 0px rgba(10, 24, 33, 0.24), 0px 8.817px 8.817px 0px #00555A inset, 0px -8.817px 8.817px 0px #00555A inset",
					background: "linear-gradient(180deg, #00555A 0%, #00555A 100%)",
				}}
				className="lg:tw-absolute tw-mb-5 lg:tw-mb-0 lg:tw-top-6 tw-rounded-xl tw-h-10 tw-px-4 tw-flex tw-justify-center tw-left-3">
				<div className="tw-flex tw-h-full lg:tw-w-full tw-justify-center tw-items-center tw-text-white tw-font-bold tw-capitalize tw-text-xl">
					Coins left: {auth?.user?.points}
				</div>
			</div>
		</>
	);
};
