import React from "react";
import Header from "../../components/Header";
import { Explore } from "../Home";
import Footer from "../../components/Footer";
import { InviteSection } from "../authhome";

const Invite = () => {
  return (
    <div>
      <div className="tw-overflow-x-hidden">
        <Header />
        <InviteSection />
        <Explore />
        {/* <Testimonial /> */}
        <Footer />
      </div>
    </div>
  );
};
// const Hero = () => {
//   return (
//     <section className="tw-bg-[#fff] tw-w-full lg:tw-h-screen">
//       <div className="container tw-py-16 lg:tw-py-0 tw-flex tw-flex-col lg:tw-flex-row tw-justify-start tw-gap-10 lg:tw-justify-between tw-h-full lg:tw-items-center">
//         <div className="lg:tw-border-l-2 tw-pl-8 tw-border-l-white tw-h-40">
//           <h1 className="tw-text-4xl nexa tw-font-extrabold tw-tracking-[-1px] tw-leading-10 tw-text-[#002224]">
//             Refer And Earn
//           </h1>
//           <p className="tw-pt-6 lg:tw-text-xl tw-font-light sansation tw-max-w-3xl tw-text-plain tw-capitalize tw-leading-6">
//             Your friends will also get is used. Your friends will also get 50
//             Coins by using your Referral Code. You will get 50 Coins Every time
//             your Referral Code is used. Refer your Friends & Family.
//           </p>
//         </div>
//         <div>
//           <img src={require("../../assets/invite.png")} alt="" className="" />
//         </div>
//       </div>
//     </section>
//   );
// };

// const InviteSection = () => {
//   return (
//     <section className="tw-bg-main tw-w-full tw-py-10">
//       <div className="container">
//         <h2 className="tw-text-2xl tw-text-white tw-font-bold nexa">
//           Share Your Referrral Code
//         </h2>
//         <p className="tw-text-base tw-pt-4 tw-text-white tw-font-medium nexa tw-uppercase">
//           Your REFERRAL CODE
//         </p>
//         <div className="tw-flex tw-gap-8 tw-items-center tw-mt-4">
//           <input
//             type="text"
//             value="QRE123"
//             className="tw-h-12 tw-uppercase tw-pl-4 tw-bg-[#FCFDFE] lg:tw-w-96 tw-w-48 tw-border tw-border-[#cdcdcd] tw-rounded-lg tw-text-base tw-text-black tw-font-normal mulish"
//           />
//           <button className="tw-h-10 lg:tw-w-20 tw-w-16 tw-bg-[#F7631B] tw-text-white tw-rounded-lg tw-font-normal tw-text-base">
//             Copy
//           </button>
//         </div>
//         <div className="tw-flex tw-justify-center tw-gap-3 tw-mt-6">
//           <IconContext.Provider value={{ color: "white" }}>
//             <AiFillFacebook size={20} />
//           </IconContext.Provider>
//           <IconContext.Provider value={{ color: "white" }}>
//             <AiFillTwitterSquare size={20} />
//           </IconContext.Provider>
//           <IconContext.Provider value={{ color: "white" }}>
//             <AiFillInstagram size={20} />
//           </IconContext.Provider>
//         </div>
//       </div>
//     </section>
//   );
// };

export default Invite;
