import { useState } from "react";
import TrueImg from "../../../assets/true1.png";
import ScoreBoard from "../../Scoreboard";

const TrueFalseQuiz = () => {
  const [isQuestion, setIsQuestion] = useState(0),
    [isSelected, setIsSelected] = useState(null),
    [isAnswer, setIsAnswer] = useState(null);
  const QuestionsArr = [
    {
      img: TrueImg,
      question: "Is this a bed",
      options: ["true", "false"],
      isCorrect: "true",
    },
    {
      img: TrueImg,
      question: "Is this a bed",
      options: ["true", "false"],
      isCorrect: "true",
    },
    {
      img: TrueImg,
      question: "Is this a bed",
      options: ["true", "false"],
      isCorrect: "true",
    },
  ];
  const currentQuestion = QuestionsArr[isQuestion];
  const handleSelect = (item, isCorrect) => {
    setIsSelected(item);
    setIsAnswer(item === isCorrect);
  };
  const handleNext = () => {
    setIsSelected(null);
    setIsQuestion(isQuestion + 1);
  };
  const handlePrev = () => {
    if (isQuestion > 0) {
      setIsQuestion(isQuestion - 1);
      setIsSelected(null);
    }
  };
  return (
    <section className="tw-bg-[#F5F5F5] tw-py-20 tw-w-full">
      <div className="container tw-bg-[#FAFAFA] tw-p-8">
        <h1 className="tw-text-xl segoe tw-font-bold tw-text-[#1b1b1b]">
          {isQuestion < QuestionsArr.length ? "Questions" : ""}
        </h1>
        {currentQuestion ? (
          <div className="lg:tw-w-3/4 tw-mx-auto tw-mt-6 myShadow tw-py-8 tw-px-4 tw-rounded-lg tw-bg-[#fdfdfd]">
            <h2 className="tw-text-xl tw-font-semibold poppins tw-text-[#162023]">
              Question {isQuestion + 1}
            </h2>
            <div className="lg:tw-w-96 tw-mx-auto">
              <div className="tw-w-full tw-flex tw-items-center tw-justify-center tw-mt-6 tw-mx-auto tw-bg-[#F3F7FA]">
                <img
                  src={currentQuestion.img}
                  alt="quiz_img"
                  className="tw-w-full tw-rounded-xl"
                />
              </div>
              <h3 className="tw-text-xl segoe tw-font-semibold tw-text-[#162023] tw-text-center tw-mt-3">
                {currentQuestion.question}
              </h3>
              <h3 className="tw-text-sm krub tw-font-semibold tw-text-[#162023] tw-text-center tw-mt-3">
                True or False?
              </h3>
              <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-6">
                {currentQuestion.options.map((item) => (
                  <div
                    key={item}
                    onClick={() =>
                      handleSelect(item, currentQuestion.isCorrect)
                    }
                    className={`tw-py-6 tw-rounded-xl myShadow ${
                      isSelected === item
                        ? isAnswer
                          ? "tw-bg-[#E9FFE7]"
                          : "tw-bg-[#FFE5FB]"
                        : "tw-bg-white"
                    }`}
                  >
                    <h5 className="tw-text-base tw-uppercase krub tw-font-semibold tw-text-center tw-text-[#1b1b1b]">
                      {item}
                    </h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="tw-flex tw-mt-10 tw-justify-center tw-gap-8">
              <div>
                <button
                  onClick={handlePrev}
                  className={`tw-h-14 tw-w-44 tw-bg-[#F0F7F9] myShadow tw-rounded-2xl tw-text-[#373535] poppins tw-font-semibold tw-text-base ${
                    isQuestion === 0 ? "tw-hidden" : "tw-block"
                  }`}
                >
                  Previous
                </button>
              </div>
              <button
                onClick={handleNext}
                className={`tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB] 
           
                    `}
              >
                {isQuestion === QuestionsArr.length + 1 ? "Submit" : "Next"}
              </button>
            </div>
          </div>
        ) : (
          <ScoreBoard />
        )}
      </div>
    </section>
  );
};

export default TrueFalseQuiz;
