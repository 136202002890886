import Header from "../../components/Header";
import Img from "../../assets/heroimg2.png";
import Quiz from "../../assets/quiz-zone.svg";
import Duet from "../../assets/duet-mode.svg";
import Daily from "../../assets/daily-quiz.svg";
import Battle from "../../assets/group-battle.svg";
import Fun from "../../assets/fun-n-learn.svg";
import { useNavigate } from "react-router-dom";
import Exam from "../../assets/exam.png";
import Challenge from "../../assets/self.png";
import Math from "../../assets/math.png";
import True from "../../assets/true.png";
import Guess from "../../assets/guess.png";
import Audio from "../../assets/quiz.png";
import { Explore } from "../Home";
import Footer from "../../components/Footer";
import { useEffect, useState } from "react";
import {
  QuizCategoryModal,
  SubCategory,
} from "../../components/Modals/quiz-category-modal";
import DuetModal, {
  ComingSoon,
} from "../../components/Modals/duet-category-modal";
import ContestModal from "../../components/Modals/contestmodal";
import ExamRulesModal from "../../components/Modals/exam-rules";
import AudioModal from "../../components/Modals/audio-modal";
import ChallengeModal from "../../components/Modals/challenge-modal";
import { useSelector } from "react-redux";
import Logo from "../../assets/logolight.svg";
import Appstore from "../../assets/appstore.svg";
import Playstore from "../../assets/playstore.svg";
import Gk from "../../assets/gk-contest.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { IconContext } from "react-icons";
// import {
//   AiOutlineFacebook,
//   AiOutlineInstagram,
//   AiOutlineTwitter,
//   AiOutlineWhatsApp,
// } from "react-icons/ai";
// import { FaShareNodes } from "react-icons/fa6";
import { Link as ScrollLink } from "react-scroll";
import { toast } from "react-toastify";

const AuthHome = () => {
  let { auth } = useSelector((s) => s),
    navigate = useNavigate();

  useEffect(() => {
    if (!auth?.isAuth) navigate("/");
  }, [auth?.isAuth, navigate]);

  return (
    <div>
      <div className="overflow">
        <Header />
        <Hero />
        {/* <ShowSection /> */}
        <Mainsection />
        <InviteSection />
        <Explore />
        <Footer />
      </div>
    </div>
  );
};
const Hero = () => {
  return (
    <section className="maingradient tw-h-screen md:tw-h-screen md:tw-py-10 tw-w-full">
      <div className="container tw-h-full">
        <div className="tw-grid lg:tw-grid-cols-2 lg:tw-gap-32 lg:tw-justify-between tw-items-center tw-h-full">
          <div className="">
            <img src={Img} alt="" className="" />
          </div>
          <div className="-tw-mt-14 lg:-tw-mt-0 ">
            <img
              data-aos="zoom-in"
              data-aos-duration="1000"
              src={Logo}
              alt=""
              className="tw-h-24 tw-mx-auto lg:tw-mx-0"
            />{" "}
            <div className="tw-flex tw-gap-2 tw-justify-center lg:tw-justify-start">
              <div
                data-aos="fade-up"
                data-aos-duration="1500"
                className="tw-flex tw-items-center tw-gap-6"
              >
                <h5 className="tw-text-[32px] tw-font-semibold tw-text-main tw-uppercase">
                  play
                </h5>
                <h5 className="tw-text-[32px] tw-font-semibold tw-text-main tw-uppercase">
                  |
                </h5>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1500"
                className="tw-flex tw-items-center tw-gap-6 tw-pl-4 tw"
              >
                <h5 className="tw-text-[32px] tw-font-semibold tw-text-main tw-uppercase">
                  learn
                </h5>
                <h5 className="tw-text-[32px] tw-font-semibold tw-text-main tw-uppercase">
                  |
                </h5>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1500"
                className="tw-pl-4 tw"
              >
                <h5 className="tw-text-[32px] tw-font-semibold tw-text-main tw-uppercase">
                  win
                </h5>
              </div>
            </div>
            <ScrollLink
              style={{
                textDecoration: "none !important",
              }}
              to="main"
              smooth={true}
              duration={500}
            >
              <div className="tw-my-3 tw-cursor-pointer tw-flex tw-justify-center lg:tw-justify-start tw-items-center tw-gap-2">
                <div>
                  <small className="tw-text-2xl tw-font-normal tw-text-main nexa">
                    Start now
                  </small>
                </div>
                <IconContext.Provider value={{ color: "#00555A" }}>
                  <FaRegArrowAltCircleRight size={20} />
                </IconContext.Provider>
              </div>
            </ScrollLink>
            <div className="tw-flex tw-gap-1 lg:tw-justify-start tw-justify-center">
              <img src={Appstore} alt="" className="tw-w-44" />
              <img src={Playstore} alt="" className="tw-w-44" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className=" tw-bg-transparent lg:tw-mt-8">
				<ShowSection />
			</div> */}
    </section>
  );
};

// const ShowSection = () => {
// 	const [swiperDirection, setSwiperDirection] = useState("horizontal");

// 	const handleSlideChange = swiper => {
// 		const isLastSlide = swiper.activeIndex === swiper.slides.length - 1;

// 		if (isLastSlide) {
// 			setSwiperDirection("horizontal-reversed");
// 		} else {
// 			setSwiperDirection("horizontal");
// 		}
// 	};
// 	const Arr = [
// 		{
// 			avatar: Quiz,
// 			title: "Quiz Zone",
// 			desc: "It’s a group battle quiz",
// 			btnbg: "linear-gradient(90deg, #F04F23 4.14%, #FCB215 96.82%)",
// 		},
// 		{
// 			avatar: Duet,
// 			title: "Duo Mode",
// 			desc: "Battle with one on one",
// 			btnbg: "linear-gradient(90deg, #2097AF 4.14%, #083A45 96.82%)",
// 		},
// 		{
// 			avatar: Daily,
// 			title: "Daily Quiz",
// 			desc: "Daily basic new quiz guide",
// 			btnbg: "linear-gradient(90deg, #962381 4.14%, #590349 96.82%)",
// 		},
// 		{
// 			avatar: Battle,
// 			title: "Group Battle",
// 			desc: "It’s a group battle quiz",
// 			btnbg: "linear-gradient(92deg, #1E59B1 1.59%, #04275B 82.14%)",
// 		},
// 		{
// 			avatar: Fun,
// 			title: "Play & Learn",
// 			desc: "It’s like  a comprehension..",
// 			btnbg: "linear-gradient(90deg, #2094AC 4.14%, #03424F 96.82%)",
// 		},
// 		{
// 			avatar: Gk,
// 			title: "Brain Tutor",
// 			desc: "It’s a group battle quiz",
// 			btnbg: "linear-gradient(90deg, #2482D7 4.14%, #073E73 96.82%)",
// 		},
// 		{
// 			avatar: Exam,
// 			title: "Exam",

// 			desc: "Give Exam",
// 		},
// 		{
// 			avatar: Challenge,
// 			title: "Self Challenge",

// 			desc: "Challenge Yourself",
// 		},
// 		{
// 			avatar: Math,
// 			title: "Math Mania",

// 			desc: "It's math quiz",
// 		},
// 		{
// 			avatar: True,
// 			title: "True | False",

// 			// action: () => navigate("/true-false"),
// 			desc: "True / False questions",
// 		},
// 		{
// 			avatar: Guess,
// 			title: "Guess The Word",

// 			desc: "It's a full vocabulary game",
// 		},
// 		{
// 			avatar: Audio,
// 			title: "Audio & Quiz",

// 			desc: "Quiz with audio",
// 		},
// 	];
// 	return (
// 		<section className="tw-w-full tw-hidden lg:tw-block tw-bg-white">
// 			<div className="container">
// 				<Swiper
// 					className="tw-w-full"
// 					slidesPerView={4}
// 					spaceBetween={8}
// 					pagination={{ clickable: true }}
// 					direction={swiperDirection}
// 					autoplay={{ delay: 2000 }}
// 					effect={"flip"}
// 					flipEffect={{
// 						slideShadows: false,
// 					}}
// 					loop={true}
// 					onSlideChange={swiper => handleSlideChange(swiper)}
// 					modules={[Autoplay, Pagination]}>
// 					{Arr?.slice(0, 4)?.map((item, i) => (
// 						<SwiperSlide className="tw-py-10">
// 							<div
// 								key={i}
// 								className="tw-rounded-xl tw-h-56 tw-shadow-2xl tw-relative tw-w-60 tw-bg-white tw-p-2">
// 								<img src={item.avatar} alt="" className="tw-mx-auto" />
// 								<h3 className="tw-text-base tw-pt-3 krub tw-font-semibold tw-text-[#1b1b1b] tw-text-opacity-90 tw-text-center">
// 									{item.title}
// 								</h3>
// 								<div className="tw-absolute tw-flex tw-justify-cente tw-w-full tw-bottom-4">
// 									<div className=" tw-w-full tw-flex tw-justify-center">
// 										<a href="#main">
// 											<button className="tw-h-9 tw-mr-4 tw-bg-[#00849F] tw-w-28 tw-capitalize tw-rounded poppins tw-text-xs tw-font-semibold tw-text-white">
// 												Play now
// 											</button>
// 										</a>
// 									</div>
// 								</div>
// 							</div>
// 						</SwiperSlide>
// 					))}
// 				</Swiper>
// 			</div>
// 		</section>
// 	);
// };

const Mainsection = () => {
  const [modal, setModal] = useState("");
  const navigate = useNavigate();
  const Arr = [
      {
        avatar: Quiz,
        title: "Quiz Zone",
        action: () => {
          // setModal("quiz-options");
          // setType("quizZone");
          navigate("/categories", { state: { type: "quizZone" } });
        },
        desc: "It’s a group battle quiz",
        btnbg: "linear-gradient(90deg, #F04F23 4.14%, #FCB215 96.82%)",
      },
      {
        avatar: Duet,
        title: "Duet Mode",
        action: () => {
          setModal("duet-options");
          setType("oneOnone");
        },

        desc: "Battle with one on one",
        btnbg: "linear-gradient(90deg, #2097AF 4.14%, #083A45 96.82%)",
      },
      {
        avatar: Daily,
        title: "Daily Quiz",
        action: () => {
          // setModal("quiz-options");
          // setType("dailyQuiz");
          navigate("/categories", { state: { type: "dailyQuiz" } });
        },
        // action: () => navigate("/daily-quiz"),
        desc: "Daily basic new quiz guide",
        btnbg: "linear-gradient(90deg, #962381 4.14%, #590349 96.82%)",
      },
      {
        avatar: Battle,
        title: "Group Battle",
        action: () => {
          setModal(
            // process.env.REACT_APP_ENV !== "test" ? "coming-soon" :
            "group-modal"
          );
          setType("groupBattle");
        },
        // action: () => setModal("group-modal"),
        desc: "It’s a group battle quiz",
        btnbg: "linear-gradient(92deg, #1E59B1 1.59%, #04275B 82.14%)",
      },
      {
        avatar: Fun,
        title: "Fun ‘N’ Learn",
        action: () => {
          setModal("gk-contest");
          setType("funAndLearn");
        },
        desc: "It’s like  a comprehension..",
        btnbg: "linear-gradient(90deg, #2094AC 4.14%, #03424F 96.82%)",
      },
      {
        avatar: Gk,
        title: "Brain Tutor",
        action: () => {
          setModal("gk-contest");
          setType("contest");
        },
        desc: "It’s a group battle quiz",
        btnbg: "linear-gradient(90deg, #2482D7 4.14%, #073E73 96.82%)",
      },
    ],
    Arr2 = [
      {
        avatar: Exam,
        title: "Exam",
        action: () => {
          setModal("gk-contest");
          setType("exam");
        },
        desc: "Give Exam",
      },
      {
        avatar: Challenge,
        title: "Self Challenge",
        // action: () => setModal(""),
        // action: () => setModal("challenge-modal"),
        action: () =>
          navigate("/new-quiz", { state: { type: "selfChallenge" } }),
        desc: "Challenge Yourself",
      },
      {
        avatar: Math,
        title: "Math Mania",
        action: () => {
          // setModal("math");
          // setType("mathQuiz");
          navigate("/categories", { state: { type: "mathQuiz" } });
        },
        desc: "It's math quiz",
      },
      {
        avatar: True,
        title: "True | False",
        action: () => navigate("/new-quiz", { state: { type: "trueOrFalse" } }),
        // action: () => navigate("/true-false"),
        desc: "True / False questions",
      },
      {
        avatar: Guess,
        title: "Guess The Word",
        action: () => {
          // setModal("guess-modal");
          // setType("guessTheWord");
          navigate("/categories", { state: { type: "guessTheWord" } });
        },
        desc: "It's a full vocabulary game",
      },
      {
        avatar: Audio,
        title: "Audio & Quiz",
        action: () => {
          // setModal("audio-modal");
          // setType("audioQuiz");
          navigate("/categories", { state: { type: "audioQuiz" } });
        },
        desc: "Quiz with audio",
      },
    ];

  let { auth, category } = useSelector((s) => s),
    [state, setState] = useState({}),
    [type, setType] = useState("");

  return (
    <section
      id="main"
      className="tw-w-full gamebg tw-bg-[#FFF8F2] tw-py-10 md:tw-py-24"
    >
      <div className="container">
        {/* <img src={ImgMain} alt="" className="tw-mx-auto tw-my-10" /> */}
        <div className="tw-grid lg:tw-grid-cols-4 tw-grid-cols-2 md:tw-grid-cols-2 lg:tw-gap-10 tw-gap-4">
          {Arr?.slice(0, 4)?.map((item, i) => (
            <div
              style={{
                borderRadius: "10px",
                background: "#FFF",
                boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.05)",
              }}
              key={i}
              data-aos="flip-up"
              data-aos-duration="2000"
              className="tw-py-5"
            >
              <img src={item?.avatar} alt="" className="tw-mx-auto tw-h-12" />
              <h3 className="tw-text-base tw-font-semibold tw-text-center tw-mt-8 krub tw-text-[#1b1b1b] tw-text-opacity-80">
                {item?.title}
              </h3>

              <div className="tw-flex tw-justify-center tw-mt-4">
                <button
                  onClick={
                    auth?.isAuth ? item?.action : () => navigate("/login")
                  }
                  style={{
                    background: item?.btnbg,
                  }}
                  className="tw-h-9 tw-animate-pulse hover:tw-animate-none lg:tw-w-28 tw-w-20 tw-rounded-xl poppins tw-text-xs tw-font-semibold tw-text-white"
                >
                  Play Now
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="tw-mt-10 tw-hidden">
          <div className="tw-mt-10 tw-grid lg:tw-grid-cols-6 tw-grid-cols-2 md:tw-grid-cols-2 tw-gap-4">
            {Arr2.map((item, i) => (
              <div
                style={{
                  boxShadow: "0px 7px 40px -20px rgba(0, 0, 0, 0.30)",
                }}
                key={i}
                // data-aos="flip-up"
                // data-aos-duration="2000"
                className="tw-rounded-xl tw-bg-white tw-p-2"
              >
                <img src={item.avatar} alt="" className="tw-mx-auto" />
                <h3 className="tw-text-base tw-pt-3 krub tw-font-semibold tw-text-[#1b1b1b] tw-text-opacity-90 tw-text-center">
                  {item.title}
                </h3>
                <div className="tw-mt-4 tw-flex tw-justify-center">
                  <button
                    onClick={
                      auth?.isAuth ? item.action : () => navigate("/login")
                    }
                    className="tw-h-9 tw-bg-[#00849F] tw-animate-pulse hover:tw-animate-none lg:tw-w-28 tw-w-20 tw-capitalize tw-rounded poppins tw-text-xs tw-font-semibold tw-text-white"
                  >
                    Play now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {modal === "quiz-options" && (
        <QuizCategoryModal
          handleClose={() => setModal("")}
          state={state}
          setState={setState}
          type={type}
          category={category}
        />
      )}
      {modal === "duet-options" && (
        <DuetModal
          handleClose={() => setModal("")}
          onClick={() => navigate("/duet-quiz", { state: { ...state, type } })}
          state={state}
          setState={setState}
          type={type}
          category={category}
        />
      )}
      {modal === "gk-contest" && (
        <ContestModal
          handleClose={() => setModal("")}
          state={state}
          setState={setState}
          type={type}
          category={category}
          setModal={setModal}
        />
      )}
      {modal === "exam-category" && (
        <SubCategory
          handleClose={() => setModal("")}
          onclick={() => setModal("exam-rules")}
          state={state}
          setState={setState}
          type={type}
          category={category}
        />
      )}
      {modal === "exam-rules" && (
        <ExamRulesModal
          handleClose={() => setModal("")}
          state={state}
          setState={setState}
          type={type}
          category={category}
        />
      )}
      {modal === "math" && (
        <QuizCategoryModal
          handleClose={() => setModal("")}
          state={state}
          setState={setState}
          type={type}
          category={category}
        />
      )}
      {modal === "group-modal" && (
        <DuetModal
          handleClose={() => setModal("")}
          onClick={() => navigate("/group-quiz", { state: { ...state, type } })}
          state={state}
          setState={setState}
          type={type}
          category={category}
        />
      )}
      {modal === "coming-soon" && (
        <ComingSoon handleClose={() => setModal("")} />
      )}
      {modal === "guess-modal" && (
        <QuizCategoryModal
          handleClose={() => setModal("")}
          state={state}
          setState={setState}
          type={type}
          category={category}
        />
      )}
      {modal === "audio-modal" && (
        <AudioModal
          handleClose={() => setModal("")}
          state={state}
          setState={setState}
          category={category}
          type={type}
        />
      )}
      {modal === "challenge-modal" && (
        <ChallengeModal
          handleClose={() => setModal("")}
          state={state}
          setState={setState}
          type={type}
          category={category}
        />
      )}
    </section>
  );
};

// const Cta = () => {
//   return (
//     <section className="tw-w-full ctaBg tw-py-20">
//       <div className="container tw-grid lg:tw-grid-cols-2 tw-items-center tw-gap-10">
//         <div>
//           <h2 className="tw-text-5xl clash tw-font-medium textColor2 tw-leading-[56px] tw-tracking-[-1.5px]">
//             Fun and Easy To Use Trivia Platform
//           </h2>
//           <p className="tw-text-lg poppins tw-font-medium tw-py-4 tw-text-[#1b1b1b] tw-text-opacity-90">
//             Lorem ipsum dolor sit amet consectetur. Morbi faucibus ut quis nisl
//             vel aliquet vulputate. Eget tempus est fames ut a sed nunc at
//             facilisis. Aenean sapien urna congue consequat vel.
//           </p>
//           <button className="tw-h-16 tw-w-56 gradient2 tw-rounded-full tw-mt-8 inter tw-text-xl tw-font-bold tw-uppercase tw-text-white">
//             get started
//           </button>
//         </div>
//         <div>
//           <img src={Img2} alt="" className="" />
//         </div>
//       </div>
//     </section>
//   );
// };

export const SwiperComp = ({ images, slidesPerView, children }) => {
  return (
    <div>
      <Swiper
        className="tw-w-full"
        slidesPerView={slidesPerView}
        autoplay={{
          delay: 2000,
        }}
        pagination={true}
        modules={[Autoplay, Pagination]}
      >
        {images?.map((it) => (
          <SwiperSlide className="tw-pb-12">{children}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export const InviteSection = () => {
  let { auth } = useSelector((s) => s);
  return (
    <section className="tw-bg-[#fff] tw-w-full">
      <div className="container lg:tw-py-0 tw-flex tw-flex-col lg:tw-flex-row tw-justify-start tw-gap-10 lg:tw-justify-between tw-h-full lg:tw-items-center">
        <div className=" tw-border-l-white tw-h-40">
          <h1
            data-aos="fade-up"
            data-aos-duration="1500"
            className="tw-text-4xl tw-pt-10 sansation tw-font-extrabold tw-leading-10 tw-text-[#002224]"
          >
            Refer and Earn
          </h1>
          <p
            data-aos="fade-up"
            data-aos-duration="1500"
            className="tw-pt-4 lg:tw-text-base tw-font-light sansation tw-max-w-3xl tw-text-plain tw-capitalize tw-leading-6"
          >
            Refer your Friends & Family. You will get 50 Coins Every time your
            Referral Code is used: Your friends will also get 50 Coins by using
            your Referral Code
          </p>
          <h2
            data-aos="fade-up"
            data-aos-duration="1500"
            className="tw-text-2xl tw-text-black lg:tw-py-8 tw-pb-10 tw-font-bold sansation"
          >
            Share Your Referral Code Now!
          </h2>
        </div>
        <div className="tw-relative tw-h-full tw-mt-8 lg:tw-mt-0 tw-w-full">
          <img
            src={require("../../assets/invite.png")}
            alt=""
            className="lg:tw-h-96"
          />
        </div>
        {/* <h2 className="tw-text-2xl tw-text-black tw-py-4 tw-font-bold nexa">
            Share Your Referral Code Now!
          </h2> */}
      </div>
      <section className="tw-bg-main tw-w-full tw-py-4">
        <div className="container tw-flex tw-justify-between tw-items-center tw-h-full">
          <div>
            <p className="tw-text-base tw-text-white tw-font-medium nexa tw-uppercase">
              Your REFERRAL CODE
            </p>
            <div className="tw-flex tw-gap-8 tw-items-center tw-mt-4">
              <input
                type="text"
                value={auth?.user?.referralCode || "QRE123"}
                readOnly
                className="tw-h-12 tw-uppercase tw-pl-4 tw-bg-[#FCFDFE] lg:tw-w-96 tw-w-48 tw-border tw-border-[#cdcdcd] tw-rounded-lg tw-text-base tw-text-black tw-font-normal mulish"
              />
              <div className="tw-flex tw-gap-3">
                <button
                  onClick={
                    auth?.user?.referralCode
                      ? () => {
                          navigator.clipboard
                            .writeText(auth?.user?.referralCode)
                            .then(
                              () => {
                                toast.info("Copied", { autoClose: 2000 });
                              },
                              (err) => {
                                toast.warn(`Could not copy: ${err}`, {
                                  autoClose: 2000,
                                });
                              }
                            );
                        }
                      : null
                  }
                  className="tw-h-10 lg:tw-w-20 tw-w-16 tw-bg-[#F7631B] tw-text-white tw-rounded-lg tw-font-normal tw-text-base"
                >
                  Copy
                </button>
                {/* <div className="tw-size-10 tw-w-16 tw-bg-[#31BF51] tw-text-white tw-rounded-lg tw-flex tw-justify-center tw-items-center">
                  <IconContext.Provider value={{ color: "white" }}>
                    <FaShareNodes size={15} />
                  </IconContext.Provider>
                </div> */}
              </div>
            </div>
            {/* <div className="tw-flex tw-justify-center tw-gap-3 tw-mt-6">
              <div>
                <IconContext.Provider value={{ color: "#fff" }}>
                  <AiOutlineFacebook size={15} />
                </IconContext.Provider>
              </div>
              <div>
                <IconContext.Provider value={{ color: "#fff" }}>
                  <AiOutlineInstagram size={15} />
                </IconContext.Provider>
              </div>
              <div>
                <IconContext.Provider value={{ color: "#fff" }}>
                  <AiOutlineTwitter size={15} />
                </IconContext.Provider>
              </div>
              <div>
                <IconContext.Provider value={{ color: "#fff" }}>
                  <AiOutlineWhatsApp size={15} />
                </IconContext.Provider>
              </div>
            </div> */}
          </div>
          <img
            src={require("../../assets/mainlogo.png")}
            alt="logo"
            className="tw-h-12 tw-hidden lg:tw-block"
          />
        </div>
      </section>
    </section>
  );
};

export default AuthHome;
