import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Avatar from "../../assets/avatar.svg";
import { QuizBtn } from "../new-quiz";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../data/Context";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";

const Plans = () => {
	let { country } = useContext(GlobalState),
		navigate = useNavigate();

	useEffect(() => {
		if (country) {
			if (country?.toLowerCase() !== "nigeria") navigate("/pricing");
		}
	}, [country, navigate]);

	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	const Plans = [
		{
			name: "Silver",
			bg: "#ccc",
			features: [
				"One Day Free Trial",
				"100",
				"24 Hours Access",
				"Full Access To All Features",
				"70 Bonus Coins",
				"Eligibility To Win Cash Prizes",
			],
			currency: "₦",
			amount: 100,
			plan_id: "daily",
		},
		{
			name: "Gold",
			bg: "#D9C880",
			features: [
				"One Day Free Trial",
				"200",
				"7 Days Access",
				"Full Access To All Features",
				"175 Bonus Coins",
				"Eligibility To Win Cash Prizes",
			],
			amount: 200,
			currency: "₦",
			paymentType: "any",
			plan_id: "weekly",
		},
		{
			name: "Platinum",
			bg: "#86DFD9",
			features: [
				"One Day Free Trial",
				"500",
				"30 Days Access",
				"Full Access To All Features",
				"480 Bonus Coins",
				"Eligibility To Win Cash Prizes",
			],
			currency: "₦",
			amount: 500,
			paymentType: "any",
			plan_id: "monthly",
		},
		{
			name: "Diamond",
			bg: "#A0C7E4",
			currency: "₦",
			amount: 4500,
			features: [
				"One Day Free Trial",
				"4500",
				"365 Days Access",
				"Full Access To All Features",
				"5000 Bonus Coins",
				"Eligibility To Win Cash Prizes",
			],
			paymentType: "not-airtime",
			plan_id: "yearly",
		},
	];

	let { auth } = useSelector(s => s),
		dispatch = useDispatch(),
		[loading, setLoading] = useState(false),
		navigate = useNavigate();

	let subscribeCoin = async item => {
		setLoading(item?.plan_id);
		try {
			var res = await axios.post("/api/v1/subscription", {
				plan_id: item?.plan_id,
			});
			console.log({ res });
			navigate("/payment", {
				state: {
					amount: item?.amount,
					currency: "naira",
					paymentType: item?.paymentType,
				},
			});
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
			// navigate(-1);
			setLoading(false);
		}
	};

	return (
		<section>
			<div className="tw-w-full tw-min-h-screen tw-py-10 gamebg">
				<div className="tw-p-12 tw-rounded-xl category-bg container">
					<div className="tw-mx-auto">
						<div className="tw-flex tw-justify-between tw-items-center">
							<div className="tw-h-14 tw-min-w-40 tw-rounded-3xl tw-ring tw-ring-[#ccc] tw-flex tw-justify-between tw-items-center tw-px-4">
								<img src={Avatar} alt="" className="" />
								<div>
									<small className="tw-text-base tw-pt-1 tw-font-normal satoshi tw-text-[#06094F]">
										{auth?.user?.lastName} {auth?.user?.firstName}
									</small>
								</div>
							</div>
							<QuizBtn text={"Get in touch"} />
						</div>
						<div className="tw-w-full tw-rounded-2xl tw-mt-10 tw-bg-main tw-p-10">
							<div className="tw-w-full tw-grid lg:tw-grid-cols-4 tw-gap-5">
								{Plans?.map((it, i) => (
									<PricingCard2
										key={i}
										plan={true}
										bg={it?.bg}
										duration={it?.name}
										features={it?.features}
										currency={it?.currency}
										amount={it?.amount}
										paymentType={it?.paymentType}
										loading={loading}
										handleSubmit={subscribeCoin}
										item={it}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export const PricingCard2 = ({
	bg,
	duration,
	features,
	plan,
	currency,
	loading,
	handleSubmit,
	item,
}) => {
	let { numberWithCommas } = useContext(GlobalState);
	let navigate = useNavigate();
	return (
		<div>
			<div
				style={{
					background: bg,
				}}
				className="lg:tw-h-[480px] tw-relative tw-py-8 tw-px-4 tw-rounded-3xl">
				<h3 className="tw-text-2xl tw-font-bold nexa tw-text-plain">
					{duration} {plan ? "Plan" : "Payment"}
				</h3>
				<ul className="tw-mt-6 tw-list-disc tw-space-y-2 tw-pl-4">
					{features.map((it, i) => (
						<li
							key={i}
							className="tw-text-base tw-text-plain tw-font-bold nexa">
							{!isNaN(it) ? `${currency} ` : ""}
							{!isNaN(it) ? numberWithCommas(Number(it).toFixed(2)) : it}
						</li>
					))}
				</ul>
				<div className="lg:tw-absolute tw-mt-4 lg:tw-mt-0 tw-w-full lg:tw-right-0 lg:tw-bottom-8 lg:tw-flex lg:tw-justify-center">
					<QuizBtn
						text={"Pay Now"}
						loading={loading === item?.plan_id}
						onClick={
							item?.plan_id === "yearly"
								? () => {}
								: () =>
										navigate("/payment", {
											state: {
												amount: item?.amount,
												currency: "naira",
												paymentType: item?.paymentType,
												item,
											},
										})
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default Plans;
