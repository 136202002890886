import React from "react";
import Header from "../components/Header";
import { MainSection } from "./categories";
import Footer from "../components/Footer";

const SubCategories = () => {
	return (
		<div>
			<Header />
			<MainSection type="subCategory" title="Sub Categories" />
			<Footer />
		</div>
	);
};

export default SubCategories;
