
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	socket: null,
	user: [],
	quiz: null,
	complete: null,
	player: null,
};

export const socketSlice = createSlice({
	name: "socket",
	initialState,
	reducers: {
		getSocket: (state, { payload }) => {
			state.socket = payload;
		},
		getSocketQuiz: (state, { payload }) => {
			state.quiz = payload;
		},
		getSocketPlayerJoin: (state, { payload }) => {
			state.player = payload;
		},
		getSocketComplete: (state, { payload }) => {
			state.complete = payload;
			state.quiz = null;
			state.player = null;
		},
		getSocketUser: (state, { payload }) => {
			state.user = EditSocketUser(state?.user, payload);
		},
		logoutSocket: state => {
			state.user = [];
			state.socket = null;
		},
	},
});
export const {
	getSocket,
	logoutSocket,
	getSocketUser,
	getSocketQuiz,
	getSocketComplete,
	getSocketPlayerJoin,
} = socketSlice.actions;


export const EditSocketUser = (data, payload) => {
	var returnItem;
	if (data?.length === 0) returnItem = [payload];
	else {
		let findItem = data?.find(item => item?.userId === payload?.user);

		if (findItem)
			returnItem = data.map(item =>
				item.userId !== payload.userId ? item : payload
			);
		else returnItem = [payload, ...data];
	}
	return returnItem;
};

