import { useNavigate } from "react-router-dom";
import ScoreGif from "../../assets/score.gif";
import Back from "../../assets/back.svg";
import Review from "../../assets/review.svg";
import Share from "../../assets/share.svg";
import TrophyGif from "../../assets/trophygif.gif";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

const ScoreBoard = ({ info, examData, realtime }) => {
	const navigate = useNavigate(),
		{ width, height } = useWindowSize();
	let { auth } = useSelector(s => s),
		[total, setTotal] = useState(null),
		[percent, setPercent] = useState(null);
	console.log({ info });
	useEffect(() => {
		if (info) {
			let r = info?.filter(it => it?.correct)?.length,
				t = (Number(r) / Number(info?.length)) * 100,
				p =
					Number(auth?.user?.points) +
					Number(info?.reduce((ac, i) => (ac += i?.points), 0));
			console.log({ r, t });
			console.log({ r, t, p });
			setPercent(t);
			setTotal(p);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [info]);

	const infos = [
		{
			title: "Score accuracy",
			value: `${Number(percent || 0).toFixed(0) || ""}%`,
		},
		{
			title: "Total Question",
			value: info?.length || 0,
		},
		{
			title: "Correct",
			value: info?.filter(it => it?.correct)?.length || 0,
		},
		{
			title: "Wrong",
			value: info?.filter(it => !it?.correct)?.length || 0,
		},
	];

	return (
		<section className="lg:tw-w-3/4 myShadow tw-mx-auto tw-py-16 tw-flex tw-justify-center tw-bg-[#fafafa] tw-overflow-x-hidden">
			<Confetti width={width} height={height} />
			<div className="tw-w-full">
				<h1 className="lg:tw-text-[130px] tw-text-4xl tw-uppercase tw-text-[#3BB70F] tw-font-extrabold tw-text-center inter">
					amazing
				</h1>
				{!realtime && (
					<div className="tw-mt-8 tw-relative">
						<img
							src={ScoreGif}
							alt=""
							className="tw-absolute tw-hidden lg:tw-block tw-left-72 tw-top-28 tw-h-64"
						/>
						<img
							src={TrophyGif}
							alt=""
							className="tw-absolute tw-left-96 lg:tw-block tw-hidden tw-top-24"
						/>
						<h2 className="tw-text-center tw-text-[#1b1b1b] tw-text-opacity-80 k2d tw-text-lg tw-font-bold tw-uppercase">
							hurray!!!
						</h2>
						<p className="tw-text-center tw-text-[#1b1b1b] tw-text-opacity-80 k2d tw-text-lg tw-font-medium tw-w-72 tw-mx-auto">
							Congratulations dear {auth?.user?.lastName}, You scored
							excellently!
						</p>
						<div className="tw-mt-10 tw-w-40 tw-mx-auto tw-items-center tw-flex tw-justify-center tw-h-40 gradient2 tw-rounded-full">
							<div>
								{" "}
								<h4 className="tw-text-3xl tw-font-semibold tw-text-center k2d tw-text-white k2d">
									{Number(total || 0).toFixed(0)} pts
								</h4>
								<h6 className="tw-text-base tw-text-center tw-font-semibold tw-text-[#EBEFEF] k2d">
									Your point(s)
								</h6>
							</div>
						</div>
					</div>
				)}
				{!realtime && (
					<div className="tw-mt-10 lg:tw-w-1/2 tw-px-8 tw-mx-auto">
						<div className="tw-grid lg:tw-grid-cols-2 tw-gap-6">
							{infos?.map(item => (
								<div
									key={item}
									className="tw-bg-white tw-h-12 tw-rounded-xl tw-px-5 tw-py-2 tw-flex tw-justify-between myShadow2">
									<div>
										<h6
											className={`tw-text-xs k2d tw-font-semibold ${
												item.title === "Score accuracy"
													? "tw-text-[#E5B331]"
													: item.title === "Total Question"
													? "tw-text-[#621BD4]"
													: item.title === "Correct"
													? "tw-text-[#62E148]"
													: "tw-text-[#E31E1E]"
											}`}>
											{item.value}
										</h6>
										<h6 className="tw-text-xs k2d tw-pb-2 tw-font-semibold tw-text-[#508991]">
											{item.title}
										</h6>
									</div>
									<div
										className={`tw-h-3 tw-w-3 tw-rounded-full ${
											item.title === "Score accuracy"
												? "tw-bg-[#F9991C]"
												: item.title === "Total Question"
												? "tw-bg-[#BC95Fc]"
												: item.title === "Correct"
												? "tw-bg-[#62E148]"
												: "tw-bg-[#E34546]"
										} `}></div>
								</div>
							))}
						</div>
						<div
							style={{
								boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.25",
							}}
							className="tw-w-full tw-py-3 tw-flex tw-justify-between tw-mt-10 tw-rounded-2xl tw-px-5 tw-bg-white">
							<div className="">
								<img
									onClick={() => navigate("/")}
									src={Back}
									alt=""
									className="-tw-mb-2 tw-cursor-pointer"
								/>
								<span className="tw-text-[8px] tw-font-semibold tw-w-10 tw-mx-auto tw-text-center tw-text-[#508991] k2d">
									Back
								</span>
							</div>
							<div>
								<img
									onClick={() =>
										navigate("/daily-quiz/review", {
											state: { info, examData },
										})
									}
									src={Review}
									alt=""
									className="-tw-mb-2 tw-cursor-pointer"
								/>
								<spam className="tw-text-[8px] tw-font-semibold tw-text-[#508991] k2d">
									Review answer
								</spam>
							</div>
							<div>
								<img
									src={Share}
									alt=""
									className="-tw-mb-2 tw-cursor-pointer"
								/>
								<span className="tw-text-[8px] tw-font-semibold tw-text-center tw-text-[#508991] k2d">
									Share score
								</span>
							</div>
						</div>
					</div>
				)}
				{realtime && (
					<>
						<div className="tw-mt-16 lg:tw-w-1/2 tw-px-8 tw-mx-auto">
							<div className="tw-grid lg:tw-grid-cols-2 tw-gap-6">
								{info?.map((it, x) => (
									<div
										key={x}
										className="tw-bg-white tw-h-min-12 tw-rounded-xl tw-px-5 tw-py-2 tw-flex tw-justify-between myShadow2">
										<div className="">
											<h6 className={`tw-text-3xl k2d tw-font-semibold`}>
												{it.total}
											</h6>
											<h6 className="tw-text-base k2d tw-pb-2 tw-font-semibold tw-text-[#508991]">
												{it?.user?.[0]?.lastName} {it?.user?.[0]?.firstName}{" "}
												{auth?.user?._id === it?.user?.[0]?._id && <>(You)</>}
											</h6>
										</div>
									</div>
								))}
							</div>
							<div
								style={{
									boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.25",
								}}
								className="tw-w-full tw-py-3 tw-flex tw-justify-between tw-mt-10 tw-rounded-2xl tw-px-5 tw-bg-white">
								<div className="">
									<img
										onClick={() => navigate("/")}
										src={Back}
										alt=""
										className="-tw-mb-2 tw-cursor-pointer"
									/>
									<span className="tw-text-[8px] tw-font-semibold tw-w-10 tw-mx-auto tw-text-center tw-text-[#508991] k2d">
										Back
									</span>
								</div>
								<div>
									<img
										src={Share}
										alt=""
										className="-tw-mb-2 tw-cursor-pointer"
									/>
									<span className="tw-text-[8px] tw-font-semibold tw-text-center tw-text-[#508991] k2d">
										Share score
									</span>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</section>
	);
};

export default ScoreBoard;
