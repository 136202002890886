import React from "react";
import { useSwiper } from "swiper/react";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { IconContext } from "react-icons";

const SwiperNav = () => {
  const swiper = useSwiper();
  return (
    <div>
      <div className="">
        <button
          onClick={() => swiper.slidePrev()}
          className="tw-h-14 tw-w-14 tw-bg-[#F1F1F1] tw-flex tw-justify-center tw-items-center tw-border-2 tw-border-[#FFDF00] tw-rounded-full tw-absolute tw-top-52 tw-bottom-52 tw-z-50 tw-left-24"
        >
          <IconContext.Provider value={{ color: "#FFDF00" }}>
            <AiOutlineLeft size={40} />
          </IconContext.Provider>
        </button>
        <button
          onClick={() => swiper.slideNext()}
          className="tw-h-14 tw-w-14 tw-bg-[#F1F1F1] tw-flex tw-justify-center tw-items-center tw-border-2 tw-border-[#FFDF00] tw-rounded-full tw-absolute tw-top-52 tw-bottom-52 tw-z-50 tw-right-24"
        >
          <IconContext.Provider value={{ color: "#FFDF00" }}>
            <AiOutlineRight size={40} />
          </IconContext.Provider>
        </button>
      </div>
    </div>
  );
};

export default SwiperNav;
