import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";

const Comprehension = () => {
  return (
    <div>
      <Header />
      <MainSection />
      <Footer />
    </div>
  );
};
const MainSection = () => {
  const navigate = useNavigate();
  return (
    <section className="tw-w-full tw-py-20 tw-bg-[#f5f5f5]">
      <div className="container myShadow tw-bg-[#FAFAFA] tw-py-10 ">
        <h1 className="tw-text-center tw-font-semibold segoe tw-text-lg tw-text-[#1b1b1b] tw-text-opacity-90">
          Comprehension on Evolution: Read carefully
        </h1>
        <div className="tw-mt-10 tw-px-10 tw-space-y-6">
          <p className="tw-text-[15px] segoe tw-font-normal tw-text-[#1b1b1b] tw-text-opacity-60">
            Lorem ipsum dolor sit amet consectetur. Dolor rutrum diam vel
            sodales. Ac auctor vestibulum tincidunt nibh vulputate morbi.
            Dignissim neque aenean convallis arcu varius consequat ut lobortis.
            Eu id cursus sed blandit pharetra at ac. Ultricies amet risus cursus
            sit eu gravida suspendisse magna blandit. Velit enim in egestas
            risus consectetur malesuada. Faucibus lectus enim facilisi urna.{" "}
          </p>
          <p className="tw-text-[15px] segoe tw-font-normal tw-text-[#1b1b1b] tw-text-opacity-60">
            Scelerisque in leo curabitur purus vitae dolor elit. Tellus nisl
            enim malesuada nisl. Sapien quis dictumst aliquam faucibus tempus
            ac. Eu congue purus ultrices ut volutpat. Potenti aliquam et in
            amet. Ultrices mattis ac lorem adipiscing urna arcu aenean arcu. Sit
            sed mauris tempus id ipsum fusce tincidunt vulputate faucibus.{" "}
          </p>
          <p className="tw-text-[15px] segoe tw-font-normal tw-text-[#1b1b1b] tw-text-opacity-60">
            Elit cursus enim ac et semper et ipsum sit quis. Tincidunt faucibus
            quis lectus velit. Tellus est bibendum feugiat in. Purus sed in
            mattis placerat felis laoreet tincidunt nulla. Sit elementum arcu et
            sit turpis quis etiam. Urna mauris suspendisse amet integer ut eu eu
            ultrices. Nec platea sit phasellus nec posuOdio laoreet sollicitudin
            pretium enim integer non. Consectetur felis in sem convallis eu elit
            sapien cursus volutpat. Arcu purus etiam odio pretium viverra vitae.
            Nunc eget.
          </p>
        </div>
        <div className="tw-flex tw-justify-center tw-mt-6">
          <button
            onClick={() => navigate("/challenge-quiz")}
            className="tw-h-12 tw-w-80 btngradient myShadow2 tw-rounded-xl tw-text-base k2d tw-text-white tw-font-semibold"
          >
            Start Now
          </button>
        </div>
      </div>
    </section>
  );
};
export default Comprehension;
