import React from "react";
import ExamQuiz from "../exam-quiz";

const Challengequiz = () => {
  return (
    <section>
      <ExamQuiz />
    </section>
  );
};

export default Challengequiz;
