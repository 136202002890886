import ScienceImg from "../../../assets/science.svg";
import HistoryImg from "../../../assets/history.svg";
import GeographyImg from "../../../assets/geography.svg";
import { useState } from "react";
import SubIco from "../../../assets/sub.svg";
// import SubIcoLight from "../../../assets/sublight.svg";
import { useNavigate } from "react-router-dom";
import Lock from "../../../assets/levelslock.svg";
import { AiFillCloseSquare } from "react-icons/ai";
// import { IconContext } from "react-icons";
import { useSelector } from "react-redux";
import Go from "../../../assets/go.svg";
import Go2 from "../../../assets/go2.svg";

export const QuizCategoryModal = ({
	handleClose,
	category,
	type,
	state,
	setState,
}) => {
	const [modal, SetModal] = useState("");
	const navigate = useNavigate();
	const OptionArr = [
		{
			img: ScienceImg,
			bg: "linear-gradient(90deg, #FF512F 0%, #F09819 100%)",
			title: "sciences",
		},
		{
			img: HistoryImg,
			bg: "linear-gradient(90deg, #E55D87 0%, #5FC3E4 100%)",
			title: "History",
		},
		{
			img: GeographyImg,
			bg: "linear-gradient(90deg, #24C6DC 0%, #514A9D 100%)",
			title: "Geography",
		},
		{
			img: HistoryImg,
			bg: "linear-gradient(90deg, #E55D87 0%, #5FC3E4 100%)",
			title: "History",
		},
		{
			img: GeographyImg,
			bg: "linear-gradient(90deg, #24C6DC 0%, #514A9D 100%)",
			title: "Geography",
		},
	];
	return (
		<section className="tw-fixed tw-bg-opacity-30 tw-z-[1000] tw-flex tw-justify-center tw-items-center tw-inset-0 tw-bg-black ">
			<div
				style={{
					boxShadow: "0px 2px 10px -6px rgba(0, 0, 0, 0.25)",
				}}
				className="container categorybg tw-py-8 tw-bg-primary">
				<div onClick={handleClose} className="tw-float-right tw-cursor-pointer">
					<AiFillCloseSquare size={20} />
				</div>
				<div className="tw-flex tw-mt-8 tw-justify-center tw-gap-6 tw-items-center">
					<h2 className="tw-font-bold  tw-text-[#1b1b1b] lg:tw-text-5xl tw-text-2xl poppins tw-uppercase tw-text-center">
						Categories
					</h2>
					<img src={Go} alt="" className="tw-h-10 lg:tw-h-full" />
				</div>

				<h3 className="tw-text-base tw-uppercase tw-text-[#1b1b1b] tw-text-opacity-80 k2d tw-font-medium">
					Select Category
				</h3>
				<div className="tw-grid md:tw-grid-cols-2 tw-grid-cols-2 tw-px-8 tw-gap-10  lg:tw-grid-cols-5 tw-mt-5">
					{category?.data?.docs
						?.filter(it => {
							return !it?.parentCategory && it?.type === type;

							// let typeID = state?.typeID || state?.examID || state?.contestID;
							// return typeID
							// 	? !it?.parentCategory && it?.type === type
							// 	: !it?.parentCategory &&
							// 			it?.type === type &&
							// 			typeID === it?.typeID;
						})
						?.map((item, i) => (
							<div
								onClick={i => {
									SetModal("sub-category");
									setState({ ...state, category: item?._id });
								}}
								key={i}
								style={{
									background: OptionArr?.[i % OptionArr?.length]?.bg,
								}}
								className="lg:tw-h-36 tw-h-20 tw-rounded-xl myShadow2 tw-cursor-pointer tw-relative">
								<img
									src={
										item?.image?.url || OptionArr?.[i % OptionArr?.length]?.img
									}
									alt=""
									className="tw-mx-auto lg:tw-h-20 tw-h-16 tw-mt-2"
								/>
								<div
									style={{
										background: "rgba(255, 255, 255, 0.50)",
									}}
									className="tw-absolute tw-bottom-3 tw-flex tw-justify-center tw-items-center lg:tw-h-10 tw-h-6 tw-w-full">
									<h6
										style={{
											textShadow: "0px 4px 4px rgba(0, 0, 0, 0.20)",
										}}
										className="tw-text-sm k2d tw-font-bold tw-text-white tw-capitalize">
										{item?.name}
									</h6>
								</div>
							</div>
						))}
				</div>
			</div>
			{modal === "sub-category" && (
				<SubCategory
					handleClose={() => SetModal("")}
					onclick={() => SetModal("levels-modal")}
					type={type}
					category={category}
					setState={setState}
					state={state}
				/>
			)}
			{modal === "levels-modal" && (
				<LevelsModal
					onClick={() => navigate("/new-quiz", { state: { ...state, type } })}
				/>
			)}
		</section>
	);
};
export const SubCategory = ({
	onClick,
	handleClose,
	type,
	category,
	setState,
	state,
}) => {
	const [modal, setModal] = useState(false),
		navigate = useNavigate();

	return (
		<section className="tw-fixed tw-bg-opacity-20 tw-flex tw-justify-center tw-items-center tw-inset-0 tw-bg-black ">
			<div className="container tw-py-8 categorybg tw-bg-primary">
				<div onClick={handleClose} className="tw-float-right tw-cursor-pointer">
					<AiFillCloseSquare size={20} />
				</div>
				<div className="tw-flex tw-justify-center tw-gap-10 tw-items-center">
					<h2 className="tw-font-bold tw-mt-8 tw-text-[#1b1b1b] lg:tw-text-5xl tw-text2xl krud tw-uppercase tw-text-center">
						sub-Categories
					</h2>
					<img src={Go2} alt="" className="tw-h-10 lg:tw-h-full" />
				</div>
				<h3 className="tw-text-base tw-uppercase tw-text-[#1b1b1b] tw-text-opacity-80 k2d tw-font-medium">
					Select sub-Category
				</h3>
				<div className="tw-grid lg:tw-grid-cols-9 md:tw-grid-cols-3 tw-grid-cols-2 tw-gap-4 tw-mt-4">
					{category?.data?.docs
						?.filter(it => {
							return (
								it?.parentCategory &&
								it?.type === type &&
								it?.parentCategory === state?.category
							);
							// let typeID = state?.typeID || state?.examID || state?.contestID;
							// return typeID
							// 	? it?.parentCategory &&
							// 			it?.type === type &&
							// 			it?.typeID === typeID &&
							// 			it?.parentCategory === state?.category
							// 	: it?.parentCategory &&
							// 			it?.type === type &&
							// 			it?.parentCategory === state?.category;
						})
						?.map((item, i) => (
							<div
								key={i}
								onClick={() => {
									setModal(true);
									setState({ ...state, subCategory: item?._id });
								}}
								className="tw-rounded-lg tw-h-24 tw-cursor-pointer tw-bg-[#FAFAFA] tw-py-6 tw-shadow-md">
								<img
									src={item?.image?.url || SubIco}
									alt=""
									className=" tw-mx-auto tw-h-8"
								/>
								<h6 className="krub tw-text-[#1b1b1b] tw-capitalize tw-pt-4 tw-font-medium tw-text-sm tw-text-center">
									{item?.name}
								</h6>
							</div>
						))}
				</div>
			</div>
			{modal && (
				<LevelsModal
					Close={() => setModal(false)}
					onClick={data =>
						navigate(
							type === "guessTheWord"
								? "/guess-the-word"
								: // : type === "contest"
								  // ? "/gk-quiz"
								  "/new-quiz",
							{
								state: { ...state, type, ...data },
							}
						)
					}
					state={state}
					setState={setState}
				/>
			)}
		</section>
	);
};

const LevelsModal = ({ onClick, Close, setState, state }) => {
  let { auth } = useSelector((s) => s);
  const Arr = [
    { show: auth?.user?.quizZoneLevel >= 1, name: "level 1" },
    { show: auth?.user?.quizZoneLevel >= 2, name: "level 2" },
    { show: auth?.user?.quizZoneLevel >= 3, name: "level 3" },
  ];

  return (
    <ModalContainer>
      <div className="lg:tw-w-1/3 tw-rounded-xl tw-px-6  tw-bg-white tw-py-8">
        <div
          onClick={Close}
          className="tw-float-right tw-pl-8 tw-cursor-pointer"
        >
          <AiFillCloseSquare size={20} />
        </div>
        <div className="tw-grid lg:tw-grid-cols-3 tw-mt-10 tw-gap-4 tw-mx-auto">
          {Arr.map((item, i) => (
            <div
              key={i}
              onClick={
                item?.show
                  ? () => {
                      setState({ ...state, level: i + 1 });
                      onClick({ level: i + 1 });
                    }
                  : null
              }
              className={`tw-h-8 tw-items-center tw-cursor-pointer tw-rounded tw-bg-[${
                !item?.show ? "#FAFAFA" : "#DFE1E1"
              }] tw-gap-4 tw-flex tw-justify-center`}
            >
              <h6
                className={` segoe tw-text-sm tw-capitalize tw-pt-2 tw-font-normal tw-text-[#1b1b1b]`}
              >
                {item?.name}
              </h6>
              <img src={Lock} alt="" className="" />
            </div>
          ))}
        </div>
      </div>
    </ModalContainer>
  );
};
export const ModalContainer = ({ children }) => {
  return (
    <section className="tw-fixed tw-z-[1000] tw-bg-opacity-30 tw-flex tw-justify-center tw-items-center  tw-backdrop-blur-sm tw-inset-0 tw-bg-black ">
      {children}
    </section>
  );
};
