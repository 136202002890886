import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Profile from "../../assets/profile.png";
import Name from "../../assets/name.svg";
import Number from "../../assets/number.svg";
import Bookmark from "../../assets/bookmark.svg";
import Coin from "../../assets/coin.svg";
import Invite from "../../assets/invite.svg";
import Delete from "../../assets/delete.svg";
import Logout from "../../assets/logout.svg";
import Badges from "../../assets/badges.svg";
import Share from "../../assets/sharea.svg";
import { logout, logoutMain, setUser } from "../../data/Reducers/UserReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/Modals/delete-modal";
import { manageCategory } from "../../data/Reducers/CategoryReducer";
import Button from "../../components/buttons/button";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";

const MyAccount = () => {
	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	const dispatch = useDispatch(),
		[modal, setModal] = useState(""),
		navigate = useNavigate();
	const Arr = [
			{
				icon: Coin,
				name: "Buy coin",
				action: () => navigate("/buy-coin"),
			},
			{
				icon: Coin,
				name: "coin history",
				action: () => navigate("/coin-history"),
			},
			{
				icon: Bookmark,
				name: "Bookmarks",
				action: () => navigate("/bookmarks"),
			},
			{
				icon: Invite,
				name: "invite friends",
				action: () => navigate("/invite"),
			},
			{
				icon: Badges,
				name: "Badges",
				action: () => navigate("/leader"),
			},
			{
				icon: Delete,
				name: "Delete account",
				action: () => setModal("delete"),
			},
			{
				icon: Share,
				name: "share app",
				action: () => {},
			},
			{
				icon: Logout,
				name: "Logout",
				action: () => {
					dispatch(logoutMain());
					navigate("/");
				},
			},
		],
		{ auth } = useSelector(s => s),
		[loading, setLoading] = useState(null),
		[state, setState] = useState({}),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		};

	let handleSubmit = async e => {
		e?.preventDefault();
		setLoading("update");
		try {
			let res;
			// console.log({ query });
			res = await axios.put(`/api/v1/user/profile`, state);
			if (state?.phone) res = await axios.put(`/api/v1/user`, state);

			toast.success(res?.data?.message);
			setState({});
			res = await axios.get(`/api/v1/user/profile?_populate=createdBy`);
			dispatch(setUser(res?.data));
			// console.log({ result: res?.data });
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
		setLoading(false);
	};
	let handleDelete = async e => {
		e?.preventDefault();
		setLoading("delete");
		try {
			let res;
			res = await axios.delete(`/api/v1/user`);
			toast.success(res?.data?.message);

			dispatch(logout());
			navigate("/");
			// console.log({ result: res?.data });
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		dispatch(manageCategory("get", "coinHistory"));
		dispatch(manageCategory("get", "badges"));
	}, [dispatch]);

	return (
		<div>
			<div className="tw-min-h-screen tw-flex tw-justify-center tw-items-center tw-py-12 gamebg tw-w-full">
				<div className="category-bg container tw-p-10 tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-gap-10 tw-rounded-2xl">
					<div className="lg:tw-w-[20%]">
						<div className="tw-flex tw-justify-center">
							<img
								src={auth?.user?.image?.url || Profile}
								alt=""
								className="tw-ml-6"
							/>
						</div>
						<h1 className="tw-text-sm tw-font-normal tw-text-center tw-text-black nexa">
							{auth?.user?.lastName} {auth?.user?.firstName}
						</h1>
						<h1 className="tw-text-sm tw-font-normal tw-text-center tw-text-black nexa">
							{auth?.user?.phone || auth?.user?.createdBy?.phone}
						</h1>
						<h1 className="tw-text-sm tw-font-normal tw-text-center tw-text-black nexa">
							{auth?.user?.email || auth?.user?.createdBy?.email}
						</h1>
					</div>
					<div className="lg:tw-w-[80%] tw-w-full">
						<form className="">
							<div className="tw-grid tw-w-full lg:tw-grid-cols-2 tw-gap-8">
								<div className="tw-flex tw-gap-2 tw-items-center">
									<img src={Name} alt="" className="tw-flex-0" />
									<input
										type="text"
										name="lastName"
										placeholder="Enter your name"
										className="tw-flex-1 tw-w-full tw-h-full tw-bg-[#86DFD9] tw-rounded-xl tw-pl-6 tw-text-sm tw-font-normal nexa tw-text-black"
										id=""
										value={state?.lastName || auth?.user?.lastName}
										onChange={textChange}
									/>
								</div>
								<div className="tw-flex tw-gap-2 tw-items-center">
									<img src={Name} alt="" className="tw-flex-0" />
									<input
										type="text"
										name="firstName"
										placeholder="Enter your name"
										className="tw-flex-1 tw-w-full tw-h-full tw-bg-[#86DFD9] tw-rounded-xl tw-pl-6 tw-text-sm tw-font-normal nexa tw-text-black"
										id=""
										value={state?.firstName || auth?.user?.firstName}
										onChange={textChange}
									/>
								</div>
								<div className="tw-flex tw-gap-2 tw-items-center">
									<img src={Number} alt="" className="tw-flex-0" />
									<input
										type="tel"
										name="phone"
										placeholder="Enter your name"
										className="tw-flex-1 tw-w-full tw-h-full tw-bg-[#86DFD9] tw-rounded-xl tw-pl-6 tw-text-sm tw-font-normal nexa tw-text-black"
										id=""
										value={
											state?.phone ||
											auth?.user?.phone ||
											auth?.user?.createdBy?.phone
										}
										onChange={textChange}
										maxLength={11}
									/>
								</div>
							</div>
							<Button
								style={{
									boxShadow: "0px 9px 14.199999809265137px 0px #00000040",
								}}
								width={
									"tw-w-full tw-mt-10 tw-rounded-xl tw-bg-main tw-h-12 tw-text-white tw-text-xl nexa tw-font-semibold"
								}
								title={"Submit"}
								loading={loading === "update"}
								onClick={handleSubmit}
							/>
						</form>
						<div className="tw-mt-10 tw-w-full tw-grid lg:tw-grid-cols-2 tw-gap-8">
							{Arr?.map((it, i) => (
								<div key={i} className="tw-flex tw-gap-2 tw-items-center">
									<img src={it?.icon} alt="" className="tw-flex-0" />
									<div
										onClick={it?.action}
										className="tw-flex-1 tw-cursor-pointer tw-w-full tw-capitalize tw-h-full tw-bg-[#EAFFFE] tw-rounded-xl tw-pl-6 tw-text-sm nexa tw-text-[#1b1b1b] tw-font-light tw-flex tw-justify-center tw-items-center">
										<strong>{it?.name}</strong>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			{modal === "delete" && (
				<DeleteModal
					handleClose={() => setModal("")}
					loading={loading === "delete"}
					handleDelete={handleDelete}
				/>
			)}
		</div>
	);
};

export default MyAccount;
