import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Avatar from "../../assets/avatar.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { manageLeaderboard } from "../../data/Reducers/LeaderboardReducer";
import axios from "axios";

const Bookmarks = () => {
	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	let { auth, leaderboard } = useSelector(s => s),
		navigate = useNavigate(),
		dispatch = useDispatch(),
		[data, setData] = useState(null);

	useEffect(() => {
		dispatch(manageLeaderboard("get", { type: "all" }));
	}, [dispatch]);

	useEffect(() => {
		if (!auth?.isAuth) navigate(-1);
	}, [auth?.isAuth, navigate]);

	useEffect(() => {
		if (auth?.user?._id) {
			let getStat = async () => {
				try {
					let res5 = await axios.get(`/api/v1/userStatistics`);
					setData(res5?.data?.data);
					console.log({ userStatistics: res5?.data });
				} catch (error) {
					console.log({ error }, "user-stat");
				}
			};
			getStat();
		}
	}, [auth?.user]);

	const quizDetails = [
		{
			title: "Rank",
			value:
				leaderboard?.data?.findIndex(
					it => it?.user[0]?._id === auth?.user?._id
				) + 1 || "nil",
		},
		{
			title: "Score",
			value: leaderboard?.data?.find(it => it?.user[0]?._id === auth?.user?._id)
				?.totalPoints,
		},
		{
			title: "Coins",
			value: auth?.user?.points,
		},
	];
	const questDetails = [
		{
			title: "Attempted",
			value: data?.totalQuestionsAnswered || "nil",
		},
		{
			title: "Correct",
			value: data?.totalCorrectQuestions || "nil",
		},
		{
			title: "Incorrect",
			value: data?.totalInCorrectQuestions || "nil",
		},
	];
	return (
		<section>
			<div className="tw-min-h-screen tw-flex tw-justify-center tw-items-center tw-py-12 gamebg tw-w-full">
				<div className="category-bg container tw-py-10 tw-rounded-2xl">
					<div className="lg:tw-m-16 tw-m-8">
						<div className="tw-flex tw-justify-between tw-items-center">
							<div className="tw-h-14 tw-min-w-40 tw-rounded-3xl tw-ring tw-ring-[#ccc] tw-flex tw-justify-between tw-items-center tw-px-4">
								<img src={Avatar} alt="" className="" />
								<div>
									<small className="tw-text-base tw-pt-1 tw-font-normal satoshi tw-text-[#06094F]">
										{auth?.user?.lastName} {auth?.user?.firstName}
									</small>
								</div>
							</div>
							<div></div>
						</div>
						<div className="tw-mt-12 tw-grid lg:tw-grid-cols-2 tw-gap-8">
							<div>
								<h2 className="tw-text-2xl tw-text-plain tw-font-bold nexa">
									Quiz Details
								</h2>
								<div className="tw-mt-6 tw-space-y-4">
									{quizDetails?.map((it, i) => (
										<div key={i} className="tw-flex tw-gap-2 tw-items-center">
											<div className="tw-h-12 tw-cursor-pointer tw-w-full tw-capitalize tw-bg-[#EAFFFE] tw-rounded-xl tw-pl-6 tw-text-sm nexa tw-text-[#1b1b1b] tw-font-light tw-flex tw-justify-center tw-items-center">
												<strong>{it?.title}</strong>
											</div>
											<div className="tw-h-12 tw-rounded-lg tw-w-12 tw-flex tw-justify-center tw-bg-main tw-text-xs tw-font-bold nexa tw-text-white tw-items-center">
												<small>{it?.value}</small>
											</div>
										</div>
									))}
								</div>
							</div>
							<div>
								<h2 className="tw-text-2xl tw-text-plain tw-font-bold nexa">
									Question Details
								</h2>
								<div className="tw-mt-6 tw-space-y-4">
									{questDetails?.map((it, i) => (
										<div key={i} className="tw-flex tw-gap-2 tw-items-center">
											<div className="tw-h-12 tw-cursor-pointer tw-w-full tw-capitalize tw-bg-[#EAFFFE] tw-rounded-xl tw-pl-6 tw-text-sm nexa tw-text-[#1b1b1b] tw-font-light tw-flex tw-justify-center tw-items-center">
												<strong>{it?.title}</strong>
											</div>
											<div className="tw-h-12 tw-rounded-lg tw-w-12 tw-flex tw-justify-center tw-bg-main tw-text-xs tw-font-bold nexa tw-text-white tw-items-center">
												<small>{it?.value}</small>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Bookmarks;
