import Footer from "../../components/Footer";
import Header from "../../components/Header";

import MainQuiz from "../../components/Quiz/main-quiz";

export const tempData = [
	{
		question:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
		options: ["answer1", "answer2", "answer3", "answer4"],
		answer: "answer1",
	},
	{
		question:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
		options: ["answer1", "answer2", "answer3", "answer4"],
		answer: "answer1",
	},
	{
		question:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
		options: ["answer1", "answer2", "answer3", "answer4"],
		answer: "answer1",
	},
];
export const tempInfo = [
	{
		title: "Score accuracy",
		value: "67%",
	},
	{
		title: "Total Question",
		value: "5",
	},
	{
		title: "Correct",
		value: "2",
	},
	{
		title: "Wrong",
		value: "1",
	},
];

const DailyQuiz = () => {
	return (
		<div>
			<Header />
			<MainQuiz data={tempData} info={tempInfo} />
			<Footer />
		</div>
	);
};

export default DailyQuiz;
