import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TrueFalseQuiz from "../../components/Quiz/true-false";

const TrueFalse = () => {
  return (
    <section>
      <Header />
      <TrueFalseQuiz />
      <Footer />
    </section>
  );
};

export default TrueFalse;
