import { useEffect, useState } from "react";
// import { ModalContainer } from "../quiz-category-modal"
import ModalContainer from "../modalcontainer";
// import { useNavigate } from "react-router-dom";
import { AiFillCopy } from "react-icons/ai";
import ScienceImg from "../../../assets/science.svg";
import HistoryImg from "../../../assets/history.svg";
import GeographyImg from "../../../assets/geography.svg";
import Go from "../../../assets/go.svg";
import Button from "../../buttons/button";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../../data/Reducers/ErrorReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSocketQuiz } from "../../../data/Reducers/SocketReducer";
import { BiCheckCircle } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { IconContext } from "react-icons";
import { FaSquareShareNodes } from "react-icons/fa6";

const DuetModal = ({
  handleClose,
  category,
  type,
  state,
  setState,
  onClick,
}) => {
  // const navigate = useNavigate();
  // const init = {
  // 	difficulty: "",
  // 	response_time: "",
  // 	category: "",
  // 	questions_amount: "",
  // };
  // const [values, setValues] = useState(init);
  // const handleSelected = (value, selected) => {
  // 	setValues({ ...values, [value]: selected });
  // 	console.log(values);
  // };
  // const Arr = [
  // 	{
  // 		title: "Difficulty",
  // 		name: "difficulty",
  // 		levels: ["Easy", "Medium", "Hard"],
  // 	},
  // 	{
  // 		title: "Response Time",
  // 		name: "response_time",
  // 		levels: ["60s", "40s", "20s"],
  // 	},
  // 	{
  // 		title: "Pick a category",
  // 		name: "category",
  // 		levels: ["Arts", "Music", "Health"],
  // 	},
  // 	{
  // 		title: "Questions amount",
  // 		name: "questions_amount",
  // 		levels: ["10", "15", "20"],
  // 	},
  // ];

  let [pickType, setPickType] = useState(false),
		[loading, setLoading] = useState(false),
		[code, setCode] = useState(""),
		navigate = useNavigate(),
		[selection, setSelection] = useState(null),
		dispatch = useDispatch(),
		[joinNav, setJoinNav] = useState(null),
		{ mainSocket } = useSelector(s => s),
		[joinedRoom, setJoinedRoom] = useState([]),
		handleCreateJoinRoom = usecase => async () => {
			if (["start"]?.includes(usecase)) if (!mainSocket?.player) return;

			if (["join", "start"]?.includes(usecase))
				if (!code) return toast.info("Room Code required");

			if (["create"]?.includes(usecase))
				if (!state?.category)
					return toast.info("Please select a category to proceed");
			setLoading(true);
			try {
				if (["create"]?.includes(usecase)) {
					let res;
					// console.log({ query });
					res = await axios.post(`/api/v1/realTimeQuizBattle/${pickType}Room`, {
						...state,
						mode: type,
					});

					toast.success(res?.data?.message);
					console.log({ result: res?.data });
					setCode(res?.data?.data?.code);
					setSelection(res?.data?.data);
				}
				if (["join", "start"]?.includes(usecase)) {
					let res2 = await axios.post(
						`/api/v1/realTimeQuizBattle/${
							usecase === "join" ? "joinRoom" : "startGame"
						}`,
						{
							code: `${code}`,
						}
					);
					toast.success(res2?.data?.message);
					console.log({ result2: res2?.data });
					if (usecase === "start") {
						if (!res2?.data?.data?.question || !res2?.data?.data?._id) {
							setLoading(false);
							setPickType(null);
							setCode("");
							handleClose();
							return toast.info("No question(s) found");
						}
					}

					if (res2?.data?.data?.question && res2?.data?.data?._id)
						dispatch(getSocketQuiz(res2?.data?.data));

					if (usecase === "start")
						navigate(type === "groupBattle" ? `/group-quiz` : "/duet-quiz", {
							state: {
								code,
								type,
								selection: selection || res2?.data?.data,
							},
						});
					else {
						setJoinNav(res2?.data);
						setSelection(res2?.data?.data);
					}
				}
				setLoading(false);
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
					setLoading(false);
				} else {
					toast.error(err?.response?.data?.message);
					setLoading(false);
				}
			}
			setLoading(false);
		};

	useEffect(() => {
		dispatch(getSocketQuiz(null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (joinNav && mainSocket?.quiz) {
			navigate(type === "groupBattle" ? `/group-quiz` : "/duet-quiz", {
				state: {
					code,
					type,
					selection,
				},
			});
			setJoinNav(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [joinNav, mainSocket?.quiz]);

	useEffect(() => {
		if (mainSocket?.player) setJoinedRoom([...joinedRoom, mainSocket?.player]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainSocket?.player]);

	const OptionArr = [
		{
			img: ScienceImg,
			bg: "linear-gradient(90deg, #FF512F 0%, #F09819 100%)",
			title: "sciences",
		},
		{
			img: HistoryImg,
			bg: "linear-gradient(90deg, #E55D87 0%, #5FC3E4 100%)",
			title: "History",
		},
		{
			img: GeographyImg,
			bg: "linear-gradient(90deg, #24C6DC 0%, #514A9D 100%)",
			title: "Geography",
		},
		{
			img: HistoryImg,
			bg: "linear-gradient(90deg, #E55D87 0%, #5FC3E4 100%)",
			title: "History",
		},
		{
			img: GeographyImg,
			bg: "linear-gradient(90deg, #24C6DC 0%, #514A9D 100%)",
			title: "Geography",
		},
	];

	return (
		<ModalContainer handleClose={handleClose}>
			<div
				style={{
					overflowY: "scroll",
				}}
				className="lg:tw-w-full  tw-max-h-[550px] tw-rounded-xl twpb-6 category-bg">
				<div
					onClick={() => {
						setPickType(null);
						setCode("");
						handleClose();
					}}
					className="tw-float-right tw-size-5 tw-rounded-lg tw-bg-black tw-flex tw-justify-center tw-items-center tw-cursor-pointer">
					<IconContext.Provider value={{ color: "white" }}>
						<IoMdClose size={15} />
					</IconContext.Provider>
				</div>
				{!pickType && (
					<div className="tw-flex tw-justify-center tw-mt-8 tw-gap-6">
						<div
							style={{
								boxShadow:
									"0px 28.341px 56.682px 0px rgba(0, 17, 18, 0.24), 0px 8.817px 8.817px 0px #006368 inset, 0px -8.817px 8.817px 0px #004448 inset",
								background: "linear-gradient(180deg, #00474C 0%, #005F65 100%)",
							}}
							onClick={() => {
								setPickType("create");
								setCode("");
							}}
							className="tw-h-16 tw-cursor-pointer tw-rounded-3xl tw-flex tw-justify-between tw-items-center tw-px-4 tw-relative">
							<small className="tw-text-xl tw-font-bold nexa tw-text-white">
								Create Room
							</small>
						</div>
						<div
							style={{
								background: "linear-gradient(180deg, #2A668B 0%, #3889BA 100%)",
								boxShadow:
									"0px 28.341px 56.682px 0px rgba(10, 24, 33, 0.24), 0px 8.817px 8.817px 0px #3A8EC1 inset, 0px -8.817px 8.817px 0px #286285 inset",
							}}
							onClick={() => {
								setPickType("join");
								setCode("");
							}}
							className="tw-h-16 tw-cursor-pointer tw-rounded-3xl tw-flex tw-justify-between tw-items-center tw-px-4 tw-relative">
							<small className="tw-text-xl tw-font-bold nexa tw-text-white">
								Join Room
							</small>
							<p className="tw-text-sm tw-font-medium krub tw-text-center tw-text-[#1b1b1b]">
								{/* 200 Coins */}
							</p>
						</div>
					</div>
				)}
				{pickType && (
					<>
						{pickType === "create" && !code ? (
							<>
								<div className="tw-flex tw-mt-8 tw-justify-center tw-gap-6 tw-items-center">
									<h2 className="tw-font-bold  tw-text-[#1b1b1b] lg:tw-text-3xl tw-text-2xl poppins tw-uppercase tw-text-center">
										Categories
									</h2>
									<img src={Go} alt="" className="tw-h-10 lg:tw-h-full" />
								</div>

								<h3 className="tw-text-base tw-uppercase tw-text-[#1b1b1b] tw-text-opacity-80 k2d tw-font-medium">
									Select Category
								</h3>
								<div className="tw-grid tw-grid-cols-2 tw-px-8 tw-gap-6 tw-mt-5">
									{category?.data?.docs
										?.filter(it => !it?.parentCategory)
										?.map((item, i) => (
											<div
												onClick={i => {
													setState({ ...state, category: item?._id });
												}}
												key={i}
												style={{
													background: OptionArr?.[i % OptionArr?.length]?.bg,
												}}
												className="lg:tw-h-36 tw-h-20 tw-rounded-xl myShadow2 tw-cursor-pointer tw-relative">
												<img
													src={
														item?.image?.url ||
														OptionArr?.[i % OptionArr?.length]?.img
													}
													alt=""
													className="tw-mx-auto lg:tw-h-20 tw-h-16 tw-mt-2"
												/>
												<div
													style={{
														background: "rgba(255, 255, 255, 0.50)",
													}}
													className="tw-absolute tw-bottom-3 tw-flex tw-justify-center tw-items-center lg:tw-h-10 tw-h-6 tw-w-full">
													<h6
														style={{
															textShadow: "0px 4px 4px rgba(0, 0, 0, 0.20)",
														}}
														className="tw-text-sm k2d tw-font-bold tw-text-white tw-capitalize">
														{item?.name}
													</h6>
												</div>
												{state?.category === item?._id && (
													<div className="tw-absolute tw-bottom-1 tw-right-1">
														<BiCheckCircle
															className="tw-text-[#fff]"
															size={30}
														/>
													</div>
												)}
											</div>
										))}
								</div>
							</>
						) : (
							<>
								<div>
									<span className="lg:tw-text-base tw-text-sm tw-font-medium tw-text-[ #3E4347] mulish tw-uppercase">
										Code{" "}
										{pickType === "create" && (
											<>
												<span className="tw-block tw-pt-3 tw-text-xs">
													Copy the code below and share with your playmate
												</span>
											</>
										)}
									</span>
									<div className="tw-relative tw-mt-3 tw-h-14 tw-w-full tw-bg-[#fcfdfe] tw-rounded-lg tw-border tw-border-[#CDCDCD]">
										<input
											type="text"
											placeholder="Enter Code to play"
											name="code"
											value={code}
											readOnly={
												pickType === "create" ||
												(pickType === "join" && joinNav)
											}
											onChange={e => setCode(e?.target.value)}
											className="tw-h-full tw-pl-6 tw-w-full tw-text-sm mulish tw-font-medium tw-text-black placeholder:tw-text-opacity-40"
										/>
										<div className="tw-absolute tw-right-3 tw-top-5">
											<div className="tw-flex tw-gap-2 tw-items-center">
												<AiFillCopy
													onClick={() =>
														navigator.clipboard
															.writeText(code)
															.then(() => {
																toast.success("Code copied successfully");
															})
															.catch(() => {
																toast.error("Error copying code");
															})
													}
													className="tw-cursor-pointer"
													size={20}
												/>
												<FaSquareShareNodes
													className="tw-cursor-pointer"
													size={20}
												/>
											</div>
										</div>
									</div>

									{pickType === "create" && (
										// ||
										// (pickType === "join" && joinNav)
										<>
											{mainSocket?.player && (
												<>
													{joinedRoom?.map((it, c) => (
														<span
															key={c}
															className="lg:tw-text-base tw-text-sm tw-font-medium tw-text-[#3E4347] mulish tw-uppercase tw-block">
															Player {it?.lastName} {it?.firstName} has joined
															the game
														</span>
													))}
												</>
											)}
										</>
									)}
									{pickType === "join" && joinNav && (
										<span className="lg:tw-text-base tw-text-sm tw-font-medium tw-text-[#3E4347] mulish tw-uppercase tw-block">
											Waiting for the other player to start the game
										</span>
									)}
								</div>
							</>
						)}
						<div className="tw-mt-6 tw-flex tw-justify-center tw-gap-4">
							{/* <button
								onClick={onClick}
								className="tw-h-10 tw-w-32 btngradient tw-rounded-lg tw-text-white tw-text-base tw-font-semibold k2d">
								Play Now
							</button> */}
							<Button
								onClick={handleCreateJoinRoom(
									pickType === "join"
										? "join"
										: pickType === "create" && code
										? "start"
										: "create"
								)}
								cssStyle={{
									background:
										pickType === "join"
											? ""
											: pickType === "create" && code && !mainSocket?.player
											? "linear-gradient(180deg, #B6152B 0%, #F51C3A 100%)"
											: "linear-gradient(180deg, #2A668B 0%, #3889BA 100%)",
									boxShadow:
										pickType === "join"
											? ""
											: pickType === "create" && code && !mainSocket?.player
											? "0px 28.341px 56.682px 0px rgba(44, 5, 10, 0.25), 0px 9.006px 9.006px 0px #FE1D3D inset, 0px -9.006px 9.006px 0px #AD1429 inset"
											: "0px 28.341px 56.682px 0px rgba(10, 24, 33, 0.24), 0px 8.817px 8.817px 0px #3A8EC1 inset, 0px -8.817px 8.817px 0px #286285 inset",
								}}
								loading={loading}
								title={
									pickType === "join"
										? "Play with a Friend"
										: pickType === "create" && code && !mainSocket?.player
										? "Waiting for the other player..."
										: pickType === "create" && code
										? "Play now"
										: "Create Room"
								}
								width={`tw-h-16 tw-cursor-pointer tw-rounded-3xl tw-flex tw-justify-between tw-items-center tw-px-4 tw-relative ${
									pickType === "join"
										? `${
												pickType === "join" && joinNav ? "tw-hidden" : ""
										  } tw-text-[#2F80ED] tw-font-semibold`
										: "tw-text-white"
								}`}
								loadCss={pickType === "join" ? "tw-text-[#2F80ED]" : "white"}
							/>
							{/* <button className="tw-h-10 tw-w-40 tw-bg-transparent tw-border tw-border-[#0966AB] tw-rounded-lg tw-text-[#2F80ED] tw-text-base tw-font-semibold k2d">
								Play with a Friend
							</button> */}
						</div>
					</>
				)}
			</div>
		</ModalContainer>
		// <ModalContainer>
		// 	<div
		// 		style={{
		// 			overflowY: "scroll",
		// 		}}
		// 		className="lg:tw-w-1/3 tw-mx-auto tw-max-h-[550px] tw-rounded-xl tw-p-6 tw-bg-[#f3f7fa]">
		// 		<div onClick={handleClose} className="tw-float-right tw-cursor-pointer">
		// 			<AiFillCloseSquare size={20} />
		// 		</div>
		// 		<div className="tw-flex tw-justify-center tw-mt-8 tw-gap-6">
		// 			<div
		// 				style={{
		// 					boxShadow: "0px 0px 1px -2px rgba(0, 0, 0, 0.25)",
		// 				}}
		// 				className="tw-p-6 tw-bg-white">
		// 				<h6 className="krub tw-text-sm tw-font-semibold tw-text-center tw-text-[#1b1b1b]">
		// 					Entry Fee
		// 				</h6>
		// 				<p className="tw-text-sm tw-font-medium krub tw-text-center tw-text-[#1b1b1b]">
		// 					10 Coins
		// 				</p>
		// 			</div>
		// 			<div
		// 				style={{
		// 					boxShadow: "0px 0px 1px -2px rgba(0, 0, 0, 0.25)",
		// 				}}
		// 				className="tw-p-6 tw-bg-white">
		// 				<h6 className="krub tw-text-sm tw-font-semibold tw-text-center tw-text-[#1b1b1b]">
		// 					Current Coins
		// 				</h6>
		// 				<p className="tw-text-sm tw-font-medium krub tw-text-center tw-text-[#1b1b1b]">
		// 					200 Coins
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="tw-mt-4 tw-space-y-4">
		// 			{Arr.map(item => (
		// 				<div key={item} className="">
		// 					<h6 className="tw-text-base segoe tw-font-normal tw-text-[#172A3A]">
		// 						{item.title}
		// 					</h6>
		// 					<div className=" tw-grid tw-grid-cols-3 tw-gap-5">
		// 						{item.levels.map(level => (
		// 							<div
		// 								onClick={() => handleSelected(item.name, level)}
		// 								key={level}
		// 								className={
		// 									values[item.name] === level
		// 										? "tw-py-1 tw-bg-[#60B551] tw-cursor-pointer tw-rounded-lg"
		// 										: "tw-py-1 tw-bg-white tw-cursor-pointer tw-rounded-lg"
		// 								}>
		// 								<h6
		// 									className={
		// 										values[item.name] === level
		// 											? "tw-text-center k2d tw-pt-1 tw-font-semibold tw-text-sm tw-text-white"
		// 											: "tw-text-center k2d tw-pt-1 tw-font-semibold tw-text-sm tw-text-[#BEC5C6]"
		// 									}>
		// 									{level}
		// 								</h6>
		// 							</div>
		// 						))}
		// 					</div>
		// 				</div>
		// 			))}
		// 		</div>
		// 		<div className="tw-mt-6 tw-flex tw-justify-center tw-gap-4">
		// 			<button
		// 				onClick={onClick}
		// 				className="tw-h-10 tw-w-32 btngradient tw-rounded-lg tw-text-white tw-text-base tw-font-semibold k2d">
		// 				Play Now
		// 			</button>
		// 			<button className="tw-h-10 tw-w-40 tw-bg-transparent tw-border tw-border-[#0966AB] tw-rounded-lg tw-text-[#2F80ED] tw-text-base tw-font-semibold k2d">
		// 				Play with a Friend
		// 			</button>
		// 		</div>
		// 	</div>
		// </ModalContainer>
	);
};

export default DuetModal;


export const ComingSoon = ({ handleClose }) => {
	return (
		<ModalContainer handleClose={handleClose}>
			<div
				style={{
					overflowY: "scroll",
				}}
				className="lg:tw-w-full  tw-max-h-[550px] tw-rounded-xl twpb-6 category-bg">
				<div className="tw-pb-10">
					<div
						onClick={() => {
							handleClose();
						}}
						className="tw-float-right tw-size-5 tw-rounded-lg tw-bg-black tw-flex tw-justify-center tw-items-center tw-cursor-pointer">
						<IconContext.Provider value={{ color: "white" }}>
							<IoMdClose size={15} />
						</IconContext.Provider>
					</div>
				</div>
				<h1
					data-aos="fade-up"
					data-aos-duration="1500"
					className="tw-text-4xl  sansation tw-font-extrabold text-center tw-leading-10 tw-text-[#002224]">
					Coming Soon!!!
				</h1>
			</div>
		</ModalContainer>
	);
};