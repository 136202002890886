import ScoreBoard from "../../Scoreboard";
import useFetch from "../../../data/useFetch";
import Button, { LoadingLoader } from "../../buttons/button";
import Letsplay from "../../../assets/letsplay.svg";
import Ico from "../../../assets/mathico.svg";

const MathQuiz = () => {
  let {
    examData,
    handleNext,
    isQuestion,
    setIsSelected,
    isSelected,
    examLoad,
    loading,
    handlePrev,
    result,
  } = useFetch();

  if (examLoad && !examData) return <LoadingLoader />;

  // const QuestionsArr = [
  // 	{
  // 		question: "102 + 3 =",
  // 		options: ["6", "5", "105", "200"],
  // 		isCorrect: "105",
  // 	},
  // 	{
  // 		question: "102 + 3 =",
  // 		options: ["6", "5", "105", "200"],
  // 		isCorrect: "105",
  // 	},
  // 	{
  // 		question: "102 + 3 =",
  // 		options: ["6", "5", "105", "200"],
  // 		isCorrect: "105",
  // 	},
  // ];

  const currentQuestion = examData[isQuestion];

  return (
		<section className="tw-bg-[#F5F5F5] tw-py-20 tw-w-full">
			<div className="container tw-bg-[#FAFAFA] tw-p-8">
				{isQuestion < examData?.length ? (
					<img src={Letsplay} alt="" className="tw-mx-auto" />
				) : (
					""
				)}
				{/* <h1 className="tw-text-xl segoe tw-font-bold tw-text-[#1b1b1b]">
					{isQuestion < examData?.length ? "Questions" : ""}
				</h1> */}
				{currentQuestion ? (
					<div className="lg:tw-w-3/4 tw-mx-auto tw-mt-6 myShadow tw-py-8 tw-px-4 tw-rounded-lg tw-bg-[#fdfdfd]">
						<div className="tw-relative tw-flex tw-justify-center">
							<h2 className="tw-text-6xl tw-font-normal segoe tw-uppercase tw-text-[#1b1b1b]">
								Question {isQuestion + 1}
							</h2>
							<img
								src={Ico}
								alt=""
								className="tw-absolute tw-right-0 tw-hidden lg:tw-block"
							/>
						</div>
						<div className="lg:tw-w-96 tw-mx-auto">
							<div className="tw-h-24 tw-w-full tw-flex tw-items-center tw-justify-center tw-mt-6 tw-mx-auto tw-bg-[#F3F7FA]">
								<h3 className="tw-text-xl segoe tw-font-semibold tw-text-[#162023] tw-rounded-xl  tw-pt-3">
									{currentQuestion?.image?.url && (
										<img
											src={currentQuestion?.image?.url}
											alt="Question"
											className="tw-mx-auto tw-h-52"
										/>
									)}
								</h3>
							</div>
							<h4 className="tw-text-sm tw-py-4 tw-font-semibold krub tw-text-center tw-text-[#1b1b1b]">
								{currentQuestion?.question}
							</h4>
							<div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-6">
								{currentQuestion?.options?.map((item, i) => (
									<div
										key={i}
										onClick={() =>
											setIsSelected({
												answer: i + 1,
												option: item?.value || item,
											})
										}
										className={`tw-py-6 tw-rounded-xl myShadow ${
											isSelected?.option === (item?.value || item) &&
											isSelected?.answer - 1 === i
												? "tw-bg-[#E9FFE7]"
												: "tw-bg-white"
										}`}>
										<h5 className="tw-text-base krub tw-font-semibold tw-text-center tw-text-[#1b1b1b]">
											{item?.value || item}
										</h5>
									</div>
								))}
							</div>
						</div>
						<div className="tw-flex tw-mt-10 tw-justify-center tw-gap-8">
							<div>
								{isQuestion >= 1 && (
									<button
										onClick={handlePrev}
										className={`tw-h-14 tw-w-44 tw-bg-[#F0F7F9] myShadow tw-rounded-2xl tw-text-[#373535] poppins tw-font-semibold tw-text-base ${
											isQuestion === 0 ? "tw-hidden" : "tw-block"
										}`}>
										Previous
									</button>
								)}
							</div>
							{/* <button
								onClick={handleNext}
								className={`tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB] 
           
                    `}>
								{isQuestion === examData.length - 1 ? "Submit" : "Next"}
							</button> */}
							<Button
								onClick={handleNext}
								loading={loading}
								title={isQuestion === examData?.length - 1 ? "Submit" : "Next"}
								width={
									"tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB]"
								}
							/>
						</div>
					</div>
				) : (
					<ScoreBoard info={result} examData={examData} />
				)}
			</div>
		</section>
	);
};

export default MathQuiz;
