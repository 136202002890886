import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Go from "../assets/go.svg";
import Cat1 from "../assets/cat1.svg";
import Cat2 from "../assets/cat2.svg";
import Cat3 from "../assets/cat3.svg";
import Cat4 from "../assets/cat4.svg";
import Cat5 from "../assets/cat5.png";
import Cat6 from "../assets/cat6.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BiCheckCircle } from "react-icons/bi";

const Categories = () => {
  return (
    <div>
      <Header />
      <MainSection />
      <Footer />
    </div>
  );
};

export const MainSection = ({ type = "category", title = "Categories" }) => {
  const Arr = [
    {
      name: "Engineering",
      action: () => {},
      img: Cat1,
    },
    {
      name: "Engineering",
      action: () => {},
      img: Cat2,
    },
    {
      name: "Engineering",
      action: () => {},
      img: Cat3,
    },
    {
      name: "Engineering",
      action: () => {},
      img: Cat4,
    },
    {
      name: "Engineering",
      action: () => {},
      img: Cat5,
    },
    {
      name: "Engineering",
      action: () => {},
      img: Cat6,
    },
  ];

  let { state } = useLocation(),
    navigate = useNavigate(),
    { auth, category } = useSelector((s) => s),
    [list, setList] = useState(null),
    [newState, setNewState] = useState({});

  useEffect(() => {
    let newD = category?.data?.docs?.filter((it) => {
      return !it?.parentCategory && it?.type === state?.type;

      // let typeID = state?.typeID || state?.examID || state?.contestID;
      // return !typeID
      // 	? !it?.parentCategory && it?.type === state?.type
      // 	: !it?.parentCategory &&
      // 			it?.type === state?.type &&
      // 			typeID === it?.typeID;
    });

    if (type === "subCategory") {
      newD = category?.data?.docs?.filter((it) => {
        return state?.typeID
          ? it?.parentCategory &&
              it?.type === state?.type &&
              it?.typeID === state?.typeID &&
              it?.parentCategory === state?.category
          : it?.parentCategory &&
              it?.type === state?.type &&
              it?.parentCategory === state?.category;
      });
    }
    setList(newD);
  }, [state, category?.data, type]);

  useEffect(() => {
    if (!state || !auth?.isAuth || !state?.type) navigate(-1);
  }, [state, navigate, auth?.isAuth]);

  // console.log({ list });

  return (
    <div>
      <div className="tw-min-h-screen tw-flex tw-justify-center tw-items-center tw-py-12 gamebg tw-w-full">
        <div className="tw-p-10 tw-rounded-xl category-bg container">
          <button
            className="tw-h-9 tw-bg-[#00849F] tw-animate-pulse hover:tw-animate-none lg:tw-w-28 tw-w-20 tw-capitalize tw-rounded poppins tw-text-xs tw-font-semibold tw-text-white"
            onClick={() => {
              navigate(type === "category" ? "/" : "/categories", {
                state,
              });
            }}
          >
            Back
          </button>
          <div className="tw-flex tw-justify-center tw-gap-8 tw-items-center">
            <h1 className="lg:tw-text-6xl tw-text-3xl tw-text-main tw-font-bold nexa">
              {title}
            </h1>
            <img src={Go} alt="" className="" />
          </div>
          <div className="tw-w-3/4 tw-grid lg:tw-grid-cols-3 tw-gap-8 tw-mx-auto tw-mt-8">
            {list?.map((it, i) => (
              <div
                key={i}
                onClick={() => {
                  setNewState({ [type]: it?._id });
                  // console.log({
                  // 	cat: category?.data?.docs
                  // 		?.filter(li => li?.parentCategory)
                  // 		?.filter(li => {
                  // 			return (
                  // 				li?.type === state?.type &&
                  // 				li?.parentCategory === it?._id
                  // 			);
                  // 		}),
                  // 	state,
                  // 	curr: it?._id,
                  // });
                  navigate(
                    type === "category" &&
                      category?.data?.docs
                        ?.filter((li) => li?.parentCategory)
                        ?.filter((li) => {
                          return (
                            li?.type === state?.type &&
                            li?.parentCategory === it?._id
                          );
                          // let typeID =
                          // 	state?.typeID || state?.examID || state?.contestID;
                          // return typeID
                          // 	? it?.parentCategory &&
                          // 			it?.type === state?.type &&
                          // 			it?.typeID === typeID &&
                          // 			it?.parentCategory === it?._id
                          // 	: it?.parentCategory &&
                          // 			it?.type === state?.type &&
                          // 			it?.parentCategory === it?._id;
                        })?.length > 0
                      ? "/sub-categories"
                      : "/levels",
                    {
                      state: { ...state, [type]: it?._id },
                    }
                  );
                }}
                className="tw-rounded-xl lg:tw-block tw-flex tw-items-center lg:tw-items-start lg:tw-gap-0 tw-bg-[#F9F9F9] lg:tw-bg-transparent tw-gap-2 tw-cursor-pointer tw-relative"
              >
                <img
                  src={it?.image?.url || Arr?.[i % Arr?.length]?.img}
                  alt=""
                  className="tw-rounded-t-xl tw-hidden lg:tw-block tw-w-full"
                />
                <img
                  src={it?.image?.url || Arr?.[i % Arr?.length]?.img}
                  alt=""
                  className="tw-rounded-t-xl tw-block lg:tw-hidden tw-w-full tw-h-20"
                />
                <div className="tw-bg-[#F9F9F9] tw-rounded-b-xl tw-h-16 tw-flex tw-justify-center tw-items-center tw-w-full">
                  <h5 className="lg:tw-text-xl tw-text-base tw-font-semibold nexa tw-text-plain tw-text-center tw-capitalize">
                    {it?.name}
                  </h5>
                </div>
                {newState?.[type] === it?._id && (
                  <div className="tw-absolute tw-bottom-1 tw-right-1">
                    <BiCheckCircle className="tw-text-main" size={30} />
                  </div>
                )}
              </div>
            ))}
          </div>
          {newState?.[type] && (
            <>
              <div className="tw-mt-4 tw-justify-center tw-hidden">
                <button
                  onClick={() =>
                    navigate(
                      type === "category" &&
                        category?.data?.docs?.filter((it) => {
                          return (
                            it?.parentCategory &&
                            it?.type === state?.type &&
                            it?.parentCategory === newState?.[type]
                          );
                          // let typeID =
                          // 	state?.typeID || state?.examID || state?.contestID;
                          // return typeID
                          // 	? it?.parentCategory &&
                          // 			it?.type === state?.type &&
                          // 			it?.typeID === typeID &&
                          // 			it?.parentCategory === newState?.[type]
                          // 	: it?.parentCategory &&
                          // 			it?.type === state?.type &&
                          // 			it?.parentCategory === newState?.[type];
                        })?.length > 0
                        ? "/sub-categories"
                        : "/levels",
                      {
                        state: { ...state, [type]: newState?.[type] },
                      }
                    )
                  }
                  className="tw-h-9 tw-bg-[#00849F] tw-animate-pulse hover:tw-animate-none lg:tw-w-28 tw-w-20 tw-capitalize tw-rounded poppins tw-text-xs tw-font-semibold tw-text-white"
                >
                  Continue
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Categories;
