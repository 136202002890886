import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { ExamQuizDuetMode } from "../../components/Quiz/exam-quiz";

const GroupQuiz = () => {
	return (
		<div>
			<Header />
			<ExamQuizDuetMode />
			<Footer />
		</div>
	);
};

export default GroupQuiz
