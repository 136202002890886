import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import WordGuesser from "../../components/Quiz/guessTheWord";

const GuessTheWord = () => {
	return (
		<div>
			<Header />
			<WordGuesser />
			<Footer />
		</div>
	);
};

export default GuessTheWord;
