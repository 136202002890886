import React, { useRef } from "react";
import ScoreBoard from "../../Scoreboard";
import Play from "../../../assets/audio.svg";
import Button, { LoadingLoader } from "../../buttons/button";
import useFetch from "../../../data/useFetch";
import Aud from "../../../assets/aud.svg";

const Audioquiz = () => {
	let {
		examData,
		handleNext,
		isQuestion,
		setIsSelected,
		isSelected,
		examLoad,
		loading,
		handlePrev,
		result,
	} = useFetch();

	let audioRef = useRef();

	if (examLoad && !examData) return <LoadingLoader />;

	// const QuestionsArr = [
	// 	{
	// 		question: "102 + 3 =",
	// 		options: [
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 		],
	// 		isCorrect: "105",
	// 	},
	// 	{
	// 		question: "",
	// 		options: [
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 		],
	// 		isCorrect: "105",
	// 	},
	// 	{
	// 		question: "102 + 3 =",
	// 		options: [
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 			"Lorem ipsum dolor sit amet consectetur.",
	// 		],
	// 		isCorrect: "105",
	// 	},
	// ];
	const currentQuestion = examData?.[isQuestion];

	return (
		<div className="tw-bg-[#F5F5F5] tw-py-20 tw-w-full">
			<div className="tw-bg-[#FAFAFA] tw-p-8 container">
				{/* <h1 className="tw-text-xl segoe tw-font-bold tw-text-[#1b1b1b]">
					{isQuestion < examData?.length ? "Questions" : ""}
				</h1> */}
				{currentQuestion ? (
					<div className="lg:tw-w-3/4 tw-mx-auto tw-mt-6 myShadow tw-py-8 tw-px-4 tw-rounded-lg tw-bg-[#fdfdfd]">
						<div className="tw-w-full tw-h-28 tw-relative tw-bg-white  tw-items-center tw-flex tw-justify-center bgAudio">
							<div className="tw-flex tw-gap-6 tw-items-center">
								<h2 className="lg:tw-text-6xl tw-text-3xl tw-font-bold tw-text-center segoe tw-text-[#407BFF]">
									Question {isQuestion + 1}
								</h2>
								<img src={Aud} alt="" className="" />
							</div>
						</div>
						<div className="tw-w-3/4 tw-mx-auto tw-mt-10">
							<h3 className="tw-text-2xl tw-text-black tw-text-center lg:tw-w-96 tw-mx-auto tw-font-bold k2d tw-block">
								{currentQuestion?.question}
							</h3>
							<div className="tw-h-32 tw-flex tw-justify-center tw-items-center tw-relative tw-w-5/6 tw-mx-auto tw-border tw-border-[#0966AB]">
								<div onClick={() => audioRef?.current?.play()}>
									<img src={Play} alt="" className=" tw-mx-auto" />
									<h6 className="tw-text-base tw-pt-3 krub tw-font-medium tw-text-[#1b1b1b] tw-text-opacity-90">
										Tap to Play Audio
									</h6>
									<audio
										controls
										ref={audioRef}
										className="notificationSound tw-hidden">
										<source
											src={
												currentQuestion?.audioUrl ||
												currentQuestion?.audioFile?.url
											}
											type="audio/mp3"
										/>
									</audio>
								</div>
							</div>
							<div className="tw-grid lg:tw-w-96 tw-mx-auto md:tw-grid-cols-2 tw-gap-4 tw-mt-6">
								{currentQuestion?.options?.map((item, i) => (
									<div
										key={item}
										onClick={() => {
											if (audioRef?.current) audioRef?.current?.pause();
											setIsSelected({
												answer: i + 1,
												option: item?.value || item,
											});
										}}
										className={`tw-py-6 tw-px-8 tw-rounded-xl myShadow ${
											isSelected?.option === (item?.value || item) &&
											isSelected?.answer - 1 === i
												? "tw-bg-[#E9FFE7]"
												: "tw-bg-white"
										}`}>
										<h5 className="tw-text-sm tw-text-opacity-90 krub tw-font-semibold tw-text-center tw-text-[#1b1b1b]">
											{item?.value || item}
										</h5>
									</div>
								))}
							</div>
							<div className="tw-flex tw-mt-10 tw-justify-center tw-gap-8">
								<div>
									{isQuestion >= 1 && (
										<button
											onClick={handlePrev}
											className={`tw-h-14 lg:tw-w-44 tw-w-24 tw-bg-[#F0F7F9] myShadow tw-rounded-2xl tw-text-[#373535] poppins tw-font-semibold lg:tw-text-base tw-text-sm ${
												isQuestion === 0 ? "tw-hidden" : "tw-block"
											}`}>
											Previous
										</button>
									)}
								</div>
								{/* <button
									onClick={handleNext}
									className={`tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB] 
           
                    `}>
									{isQuestion === examData?.length - 1 ? "Submit" : "Next"}
								</button> */}
								<Button
									onClick={handleNext}
									loading={loading}
									title={
										isQuestion === examData?.length - 1 ? "Submit" : "Next"
									}
									width={
										"tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB] "
									}
								/>
							</div>
						</div>
					</div>
				) : (
					<ScoreBoard info={result} examData={examData} />
				)}
			</div>
		</div>
	);
};

export default Audioquiz;
