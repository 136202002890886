import React from "react";
import Header from "../../components/Header";
import HeroImg from "../../assets/heroimg.png";

import Behance from "../../assets/behance.svg";
import Dribbble from "../../assets/driddle.svg";
import Youtube from "../../assets/youtube.svg";
import Webflow from "../../assets/webflow.svg";

import Apple from "../../assets/apple.svg";
import Play from "../../assets/play.svg";
import Categories1 from "../../assets/category1.png";
import Categories2 from "../../assets/category2.png";

import Category3 from "../../assets/category3.png";
import Category4 from "../../assets/category4.png";
import Category5 from "../../assets/category5.png";
import Footer from "../../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Explore1 from "../../assets/explore1.png";
import ExAvatar from "../../assets/exploreavatar.png";
import SwiperNav from "../../components/SwiperNav";
import HomeModal from "../../components/Modals/homeModal";
import { useState } from "react";
import ExploreMain from "../../assets/exploremain.png";
import { QuizCategoryModal } from "../../components/Modals/quiz-category-modal";

const Home = () => {
  const [modal, setModal] = useState(false);
  return (
    <div className="home">
      <Header />
      <Hero onClick={() => setModal(true)} />
      <Trending bg={"tw-bg-[#020228]"} handleModal={(i) => setModal(true)} />
      <Explore />
      <Categories />
      <Footer />
      {modal && <HomeModal handleClose={() => setModal(false)} />}
    </div>
  );
};
const Hero = ({ onClick }) => {
  const Partners = [Dribbble, Youtube, Behance, Webflow];
  return (
    <div className="">
      <div
        style={{
          background: "linear-gradient(90deg, #0D3E6F 0%, #363795 100%)",
        }}
        className="tw-w-full tw-py-16"
      >
        <div className="container tw-grid tw-items-center tw-h-full lg:tw-grid-cols-2 lg:tw-gap-10 tw-gap-16 tw-pb-8 lg:tw-pb-0">
          <div className="">
            <div>
              <h1
                data-aos="fade-up"
                data-aos-duration="1500"
                className="lg:tw-text-5xl lg:tw-block tw-hidden akira tw-max-w-2xl tw-text-3xl tw-text-white poppins tw-font-extrabold tw-leading-[52px]"
              >
                Lorem ipsum{" "}
                <span className="lg:tw-text-6xl akira tw-text-3xl tw-text-[#f7631b] tw-font-extrabold tw-leading-[52px]">
                  dolor
                </span>{" "}
                sit <br /> amet
              </h1>
              <h1
                data-aos="fade-up"
                data-aos-duration="1500"
                className="tw-text-2xl akira lg:tw-hidden tw-block tw-font-extrabold tw-text-white tw-leading-[40px]"
              >
                Lorem ipsum{" "}
                <span className="tw-text-2xl akira lg:tw-hidden tw-block tw-font-extrabold tw-text-[#f7631b]">
                  dolor
                </span>{" "}
                sit amet
              </h1>
              <p
                data-aos="fade-down"
                data-aos-duration="1500"
                className="lg:tw-text-xl tw-pt-10 tw-text-base tw-text-white poppins tw-font-normal tw-leading-9 lg:tw-max-w-xl lg:tw-w-full tw-w-96 tw-capitalize"
              >
                Lorem ipsum dolor sit amet consectetur. Donec tempus consectetur
                mi ullamcorper. Viverra tristique varius elementum eget mi
                massa.
              </p>
              <div
                data-aos="zoom-in-up"
                data-aos-duration="1500"
                className="tw-mt-10 tw-flex tw-flex-col lg:tw-flex-row tw-gap-8"
              >
                <button
                  onClick={onClick}
                  className="tw-bg-white lg:tw-h-20 tw-h-14 tw-w-56 lg:tw-w-80 tw-rounded-full tw-text-[#020228] poppins tw-font-semibold lg:tw-text-2xl"
                >
                  Explore Trivia
                </button>
                <button className="tw-bg-transparent tw-border-2 tw-border-white lg:tw-h-20 lg:tw-w-80 tw-h-14 tw-w-56 tw-rounded-full tw-text-white poppins tw-font-semibold lg:tw-text-2xl">
                  Download App
                </button>
              </div>
              <div
                data-aos="fade-down"
                data-aos-duration="1500"
                className="tw-mt-16 tw-flex tw-flex-col md:tw-flex-row tw-gap-8"
              >
                <div className="tw-mx-auto lg:tw-mx-0">
                  <h2 className="tw-text-4xl tw-font-extrabold akira txt">
                    432K+
                  </h2>
                  <p className="tw-text-center poppins tw-text-white tw-font-medium">
                    Users
                  </p>
                </div>
                <div className="tw-mx-auto lg:tw-mx-0">
                  <h2 className="tw-text-4xl tw-font-extrabold akira txt">
                    200K+
                  </h2>
                  <p className="tw-text-center poppins tw-text-white tw-font-medium">
                    Quizzes
                  </p>
                </div>
                <div className="tw-mx-auto lg:tw-mx-0">
                  <h2 className="tw-text-4xl tw-font-extrabold akira txt">
                    10K+
                  </h2>
                  <p className="tw-text-center poppins tw-text-white tw-font-medium">
                    Community
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img
              data-aos="zoom-in"
              data-aos-duration="1500"
              src={HeroImg}
              alt="Hero_img"
              className=""
            />
          </div>
        </div>
        <div
          data-aos="zoom-in-up"
          data-aos-duration="1500"
          className="container tw-py-12 partner tw-mt-16"
        >
          <h2 className="lg:tw-text-5xl tw-text-3xl akira tw-font-extrabold tw-text-center tw-uppercase partnerTxt">
            our partners
          </h2>
          <div className="tw-flex tw-mt-8 tw-flex-col lg:tw-flex-row tw-mx-10 tw-gap-6 tw-gap-y-8 tw-justify-between tw-items-center">
            {Partners.map((item, i) => (
              <img key={i} src={item} alt="" className="" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export const Trending = ({ bg, handleModal }) => {
  const Arr = [
    {
      img: Explore1,
      title: "Group Battles",
      coins: "10",
      created: false,
      avatar: ExAvatar,
    },
    {
      img: Explore1,
      title: "GK contest",
      coins: "10",
      created: true,
      avatar: ExAvatar,
    },
    {
      img: Explore1,
      title: "Group Battles",
      coins: "10",
      created: false,
      avatar: ExAvatar,
    },
    {
      img: Explore1,
      title: "Group Battles",
      coins: "10",
      created: true,
      avatar: ExAvatar,
    },
    {
      img: Explore1,
      title: "Group Battles",
      coins: "10",
      created: false,
      avatar: ExAvatar,
    },
  ];
  return (
    <div>
      <div className={`tw-w-full  tw-py-20 ${bg}`}>
        <div className="container">
          <h2 className="lg:tw-text-5xl tw-text-3xl tw-font-extrabold akira partnerTxt tw-uppercase">
            trending now
          </h2>
          <p className="tw-text-white tw-font-medium poppins tw-text-2xl">
            Explore the Trending Categories
          </p>
          <div data-aos="fade-up" data-aos-duration="1500" className="tw-mt-12">
            <Swiper
              className="tw-mx-auto tw-relative"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  centeredSlides: true,
                },
                480: {
                  slidesPerView: 1,
                  centeredSlides: true,
                },
                768: {
                  slidesPerView: 1,
                  centeredSlides: true,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
            >
              {Arr.map((item, i) => (
                <SwiperSlide key={i}>
                  <div className="tw-p-4 tw-mb-24 tw-w-64 lg:tw-m-4 ExploreBox">
                    <img src={item.img} alt="" className="tw-rounded-2xl" />
                    <div className="tw-mt-8 tw-flex tw-justify-between tw-items-center">
                      <div className="tw-flex tw-gap-2">
                        <img
                          src={require("../../assets/exploreavatar.png")}
                          alt=""
                          className="tw-rounded-xl tw-h-10"
                        />
                        <div>
                          <h6 className="tw-text-sm tw-text-[#0075FF] poppins tw-font-semibold">
                            {item.title}
                          </h6>
                          <h6 className="poppins tw-text-[#E4E4E4] tw-font-semibold tw-text-[10px]">
                            {item.coins} to play
                          </h6>
                        </div>
                      </div>
                      <div className="">
                        <h6 className="tw-text-sm tw-text-[#FF6B00] tw-font-semibold poppins">
                          {item.created ? "Play now" : "Create/Join"}
                        </h6>
                      </div>
                    </div>
                    <div className="tw-flex tw-justify-center tw-mt-8">
                      <button
                        onClick={() => handleModal(i)}
                        className="tw-bg-white tw-h-10 tw-w-48 tw-rounded-full tw-text-[#020228] poppins tw-font-medium tw-text-base"
                      >
                        Explore Trivia
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              <SwiperNav />
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Explore = () => {
  return (
    <div className="">
      <div className="tw-w-full tw-bg-[#f9f9f7] tw-py-16">
        <div className="container">
          <div className="tw-grid lg:tw-grid-cols-2 tw-h-full tw-items-center tw-gap-6">
            <div className="tw-block lg:tw-hidden">
              <img src={ExploreMain} alt="" className="tw-w-full" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              className="tw-flex tw-items-center tw-h-full"
            >
              <div>
                {" "}
                <h2 className="lg:tw-text-5xl tw-text-3xl tw-font-medium nexa tw-text-[#002224] tw-uppercase">
                  Explore{" "}
                  <span className="tw-text-main tw-font-extrabold">more</span>
                </h2>
                <div className="tw-mt-10">
                  <h3 className="lg:tw-text-xl tw-text-sm tw-font-bold poppins tw-text-[#002224]">
                    Download the Mobile App
                  </h3>
                  <div className="tw-flex tw-gap-6 tw-mt-5">
                    <img src={Apple} alt="" className="lg:tw-h-full tw-h-12" />
                    <img src={Play} alt="" className="lg:tw-h-full tw-h-12" />
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-hidden lg:tw-block">
              <img src={ExploreMain} alt="" className="tw-w-full" />
            </div>
          </div>
          {/* <section className="tw-mt-20">
            <h2 className="lg:tw-text-5xl tw-text-3xl tw-text-center tw-font-extrabold akira partnerTxt tw-uppercase">
              testimonies
            </h2>
            <p className="tw-text-white tw-text-center tw-font-medium poppins lg:tw-text-2xl">
              Hear What Our Users Have to Say
            </p>
            <div
              style={{
                borderEadius: "20px",
                background: "rgba(255, 255, 255, 0.17)",
              }}
              data-aos="zoom-out"
              data-aos-duration="1500"
              className="tw-w-full tw-flex tw-flex-col lg:tw-flex-row tw-gap-10 tw-items-center tw-py-10 tw-mt-10"
            >
              {" "}
              <div className="lg:tw-w-[20%]">
                <h4
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  className="tw-pl-8 tw-3xl lg:tw-w-40 poppins tw-font-semibold tw-text-white"
                >
                  Trusted by millions of users
                </h4>
              </div>
              <div className="lg:tw-pr-10 tw-flex lg:tw-w-[80%] tw-flex-col lg:tw-flex-row tw-justify-center lg:tw-justify-start tw-gap-6 tw-items-center">
                {TestimonialArr.map((item, i) => (
                  <div
                    key={i}
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    className="lg:tw-h-80 tw-w-96 tw-p-8 tw-bg-white tw-rounded-xl tw-shadow-xl"
                  >
                    <p className="lato tw-text-sm tw-text-[#14213D] tw-font-semibold">
                      {item.text}
                    </p>
                    <div className="tw-mt-10 tw-flex tw-items-center tw-gap-6">
                      <img src={item.img} alt="" className="" />
                      <div>
                        <h6 className="tw-text-sm inter tw-text-[#14213d] tw-font-semibold">
                          {item.name}
                        </h6>
                        <h6 className="tw-text-xs inter tw-text-[14213d] tw-font-normal">
                          {item.role}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="tw-flex tw-mt-16 tw-justify-center tw-items-center">
              <button className="tw-bg-transparent tw-border-2 tw-border-white tw-h-20 tw-w-80 tw-rounded-full tw-text-white poppins tw-font-semibold tw-text-2xl tw-capitalize">
                see more
              </button>
            </div>
          </section> */}
        </div>
      </div>
    </div>
  );
};

const Categories = () => {
  return (
    <section
      style={{
        background: "linear-gradient(90deg, #0D3E6F 0%, #363795 100%)",
      }}
      className="tw-py-10 "
    >
      <div className="container">
        <h2 className="lg:tw-text-5xl tw-text-3xl tw-font-extrabold akira partnerTxt tw-uppercase">
          top Categories
        </h2>
        <p className="tw-text-white tw-font-medium poppins lg:tw-text-2xl tw-text-base">
          Browse collections from the top categories
        </p>
        <div className="tw-grid md:tw-grid-cols-2 tw-gap-6 tw-mt-10">
          <div className="partner tw-p-10">
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <img src={Categories1} alt="" className="tw-rounded-xl" />
              <img src={Categories2} alt="" className="tw-rounded-xl" />
            </div>
            <h6 className="tw-pt-4 poppins tw-text-2xl tw-text-center tw-font-medium tw-text-white">
              Contests
            </h6>
          </div>
          <div className="tw-space-y-4">
            <div className="partner tw-w-full tw-p-6">
              <div className="tw-grid tw-grid-cols-2 ">
                <img src={Category3} alt="" className="tw-rounded-xl" />
                <img src={Category4} alt="" className="tw-rounded-xl" />
              </div>
              <h6 className="tw-pt-4 poppins tw-text-2xl tw-text-center tw-font-medium tw-text-white">
                Group Battle
              </h6>
            </div>
            <div className="partner tw-p-6 tw-w-full">
              <img src={Category5} alt="" className="tw-rounded-xl" />
              <h6 className="tw-pt-4 poppins tw-text-2xl tw-text-center tw-font-medium tw-text-white">
                Group Battle
              </h6>
            </div>
          </div>
        </div>
      </div>
      <section className="tw-my-20">
        <div className="container tw-grid md:tw-grid-cols-2 tw-gap-10">
          <div>
            <h3 className="lg:tw-text-5xl tw-text-3xl tw-text-white clash tw-font-bold tw-uppercase">
              stay updated
            </h3>
            <p className="poppins lg:tw-text-xl tw-text-base tw-font-medium tw-pt-8 tw-text-white tw-leading-9">
              Lorem ipsum dolor sit amet consectetur. Quam tortor ultricies
              aliquet tempor ullamcorper dignissim. Elit egestas diam commodo
              ultricies. Elit.
            </p>
          </div>
          <div>
            <input
              type="text"
              placeholder="Email address"
              className="inputBg tw-w-full tw-h-20 tw-pl-10 poppins tw-font-medium tw-text-white tw-text-2xl"
            />
            <button
              type="submit"
              className="btnBg  tw-mt-8 tw-bg-white tw-w-full tw-h-20 poppins tw-text-2xl tw-font-medium btnTxt"
            >
              Send Me Updates
            </button>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Home;
