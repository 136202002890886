import React, { useState } from "react";
import Header from "../../components/Header";
import { Explore } from "../Home";
import Footer from "../../components/Footer";
import Img from "../../assets/contact.png";
import Logo from "../../assets/logolight.svg";
import { PurifiedText } from "../about-us";

const ContactUs = () => {
	return (
		<div>
			<Header />
			<Mainsection />
			<Explore />
			<Footer />
		</div>
	);
};

const Mainsection = () => {
	let [type, setType] = useState("");
	return (
		<section className="tw-w-full tw-bg-[#F4FDFF] tw-h-screen">
			<div className="container tw-grid lg:tw-grid-cols-2 tw-h-full lg:tw-items-center tw-gap-6">
				<div>
					<img src={Img} alt="" className="tw-block lg:tw-hidden" />
					<h1 className="tw-text-6xl tw-pt-12 lg:tw-pt-0 tw-font-extrabold tw-text-main nexa">
						Contact Us
					</h1>
					<img src={Img} alt="" className="tw-hidden lg:tw-block" />
				</div>
				<div>
					<img src={Logo} alt="" className="tw-h-32" />
					<PurifiedText type={type} setType={setType} section={"contact"} />
					{!type && (
						<p className="tw-text-base tw-pt-10 tw-text-plain tw-font-normal nexa">
							etur adipiscing elit, sed do eiusmod tempor incididunt ut labore
							et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
							exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore eu fugiat nulla pariatur
						</p>
					)}
				</div>
			</div>
		</section>
	);
};

export default ContactUs;
