import { ClipLoader } from "react-spinners";
import "./loading.css";

const BUTTON_TYPES = {
	primary: "tw-bg-black tw-text-white",
	primaryOutline: "tw-border-2 tw-border-[#2A72A8] tw-text-main",
	secondary: "tw-bg-[#F72585] tw-text-white",
	tetiary: "tw-border-2 tw-border-main tw-text-main",
};

const Button = ({
	children,
	loading,
	buttonType,
	width,
	style,
	title,
	loadCss,
	disabled,
	icon,
	type,
	cssStyle,
	...restProps
}) => {
	return (
		<div>
			<button
				type={type || "button"}
				disabled={loading || disabled}
				className={`tw-text-sm tw-p-2 tw-px-8 tw-rounded-lg tw-flex tw-items-center tw-gap-2 tw-justify-center ${
					width || "tw-w-52"
				} ${style || ""} ${BUTTON_TYPES[buttonType]}`}
				style={cssStyle || {}}
				{...restProps}>
				{icon && <span>{icon}</span>}
				{children}
				<span className={loading ? "tw-me-2" : ""}>
					{title ? title : "log in"}
				</span>
				{loading && (
					<ClipLoader color={loadCss ? loadCss : "white"} size={16} />
				)}
			</button>
		</div>
	);
};

export default Button;

export const LoadingLoader = () => {
	return (
		<div className="loader">
			<div className="hexagon" aria-label="Animated hexagonal ripples">
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
			</div>
			<div className="tw-flex tw-items-center tw-justify-center">
				<p aria-label="Loading" className="text-center">
					Loading
				</p>
			</div>
		</div>
	);
};
