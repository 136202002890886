import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Audioquiz from "../../components/Quiz/audio-quiz";

const AudioQuiz = () => {
  return (
    <div>
      <Header />
      <Audioquiz />
      <Footer />
    </div>
  );
};

export default AudioQuiz;
