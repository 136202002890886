import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MathQuiz from "../../components/Quiz/math-quiz";

const MathMania = () => {
  return (
    <section className="">
      <Header />
      <MathQuiz />
      <Footer />
    </section>
  );
};

export default MathMania;
