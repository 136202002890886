import React from "react";
import { ModalContainer } from "../quiz-category-modal";
import ScienceImg from "../../../assets/science.svg";
import HistoryImg from "../../../assets/history.svg";
import GeographyImg from "../../../assets/geography.svg";
import { useNavigate } from "react-router-dom";
import { AiFillCloseSquare } from "react-icons/ai";

const AudioModal = ({ handleClose, category, state, setState, type }) => {
	const navigate = useNavigate();
	const OptionArr = [
		{
			img: ScienceImg,
			bg: "linear-gradient(90deg, #FF512F 0%, #F09819 100%)",
			title: "sciences",
		},
		{
			img: HistoryImg,
			bg: "linear-gradient(90deg, #E55D87 0%, #5FC3E4 100%)",
			title: "History",
		},
		{
			img: GeographyImg,
			bg: "linear-gradient(90deg, #24C6DC 0%, #514A9D 100%)",
			title: "Geography",
		},
		{
			img: HistoryImg,
			bg: "linear-gradient(90deg, #E55D87 0%, #5FC3E4 100%)",
			title: "History",
		},
		{
			img: GeographyImg,
			bg: "linear-gradient(90deg, #24C6DC 0%, #514A9D 100%)",
			title: "Geography",
		},
	];
	return (
		<ModalContainer>
			<div
				style={{
					boxShadow: "0px 2px 10px -6px rgba(0, 0, 0, 0.25)",
				}}
				className="container tw-py-8 tw-bg-white">
				<div onClick={handleClose} className="tw-float-right tw-cursor-pointer">
					<AiFillCloseSquare size={20} />
				</div>
				<h2 className="tw-font-bold tw-pt-8 tw-text-[#1b1b1b] tw-text-xl krud tw-uppercase tw-text-center">
					Categories
				</h2>
				<h3 className="tw-text-base tw-uppercase tw-text-[#1b1b1b] tw-text-opacity-80 k2d tw-font-medium">
					Category
				</h3>
				<div className="tw-grid tw-grid-cols-2 tw-px-8 tw-gap-10  lg:tw-grid-cols-5 tw-mt-5">
					{category?.data?.docs
						?.filter(it => !it?.parentCategory && it?.type === type)
						?.map((item, i) => (
							<div
								onClick={i => {
									setState({ ...state, category: item?._id });
									navigate("/audio-quiz", {
										state: { ...state, category: item?._id, type },
									});
								}}
								key={i}
								style={{
									background: OptionArr?.[i % OptionArr?.length]?.bg,
								}}
								className="tw-h-36 tw-rounded-xl tw-cursor-pointer tw-relative">
								<img
									src={OptionArr?.[i % OptionArr?.length]?.img}
									alt=""
									className="tw-mx-auto"
								/>
								<div
									style={{
										background: "rgba(255, 255, 255, 0.50)",
									}}
									className="tw-absolute tw-bottom-3 tw-flex tw-justify-center tw-items-center tw-h-10 tw-w-full">
									<h6
										style={{
											textShadow: "0px 4px 4px rgba(0, 0, 0, 0.20)",
										}}
										className="tw-text-sm k2d tw-font-bold tw-text-white tw-capitalize">
										{item?.name}
									</h6>
								</div>
							</div>
						))}
				</div>
			</div>
		</ModalContainer>
	);
};

export default AudioModal;
