import { createSlice } from "@reduxjs/toolkit";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	status: "",
	contest: null,
	funAndLearn: null,
	config: null,
	exam: null,
	quizSettings: null,
	settings: null,
};

export const categorySlice = createSlice({
	name: "category",
	initialState,
	reducers: {
		getCategory: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getExam: (state, { payload }) => {
			state.exam = payload?.data || payload;
		},
		getCoinHistory: (state, { payload }) => {
			state.coinHistory = payload?.data || payload;
		},
		getBadges: (state, { payload }) => {
			state.badges = payload?.data || payload;
		},
		getContest: (state, { payload }) => {
			state.contest = payload?.data || payload;
		},
		getConfig: (state, { payload }) => {
			state.config = payload?.data || payload;
		},
		getLevel: (state, { payload }) => {
			state.level = payload?.data || payload;
		},
		getQuizSettings: (state, { payload }) => {
			state.quizSettings = payload?.data || payload;
		},
		getSettings: (state, { payload }) => {
			state.settings = payload?.data || payload;
		},
		getFaqs: (state, { payload }) => {
			state.faq = payload?.data || payload;
		},
		getFunAndLearn: (state, { payload }) => {
			state.funAndLearn = payload?.data || payload;
		},
		categoryFail: state => {
			state.status = "";
		},
		logoutCategory: state => {
			state.data = null;
			state.search = "";
		},
	},
});
export const {
	categoryFail,
	getCategory,
	logoutCategory,
	getContest,
	getFunAndLearn,
	getConfig,
	getExam,
	getQuizSettings,
	getBadges,
	getCoinHistory,
	getSettings,
	getLevel,
	getFaqs,
} = categorySlice.actions;

export const manageCategory = (type, sect) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			if (sect === "category") {
				res = await axios.get(`/api/v1/category?_populate=image&_limit=0`);
				dispatch(getCategory(res?.data));
			}
			if (sect === "funAndLearn") {
				let res2 = await axios.get(
					`/api/v1/funAndLearn?_populate=category&_populate=subCategory&_limit=0`
				);
				dispatch(getFunAndLearn(res2?.data));
			}
			if (sect === "contest") {
				let res3 = await axios.get(`/api/v1/contest?_limit=0`);
				dispatch(getContest(res3?.data));
			}
			if (sect === "exam") {
				let res5 = await axios.get(`/api/v1/exam?_limit=0`);
				dispatch(getExam(res5?.data));
			}
			if (sect === "systemConfig") {
				let res4 = await axios.get(`/api/v1/systemConfig`);
				dispatch(getConfig(res4?.data));
			}
			if (sect === "quizSettings") {
				let res6 = await axios.get(`/api/v1/quizSettings`);
				dispatch(getQuizSettings(res6?.data));
			}
			if (sect === "coinHistory") {
				let res7 = await axios.get(`/api/v1/coinHistory`);
				dispatch(getCoinHistory(res7?.data));
			}
			if (sect === "badges") {
				let res8 = await axios.get(`/api/v1/badges`);
				dispatch(getBadges(res8?.data));
			}
			if (sect === "settings") {
				let res = await axios.get(`/api/v1/staticSettings`);
				dispatch(getSettings(res?.data));
			}
			if (sect === "level") {
				let res = await axios.get(`/api/v1/level`);
				dispatch(getLevel(res?.data));
			}
			if (sect === "faq") {
				let res = await axios.get(`/api/v1/faq`);
				dispatch(getFaqs(res?.data));
			}
		}

		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(categoryFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err, sect });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(categoryFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	}
};
