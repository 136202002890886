import { useNavigate } from "react-router-dom";
import { ModalContainer } from "../quiz-category-modal";
import { AiFillCloseSquare } from "react-icons/ai";

const ExamRulesModal = ({ handleClose }) => {
  const navigate = useNavigate();
  const RulesArr = [
    "I will not copy and give this exam with honesty",
    "If you lock your phone then exam will complete automatically",
    "If you minimize application or open other application and don't come back to application with in 5 seconds then exam will complete automatically",
    "Screen recording is prohibited",
    "In Android screenshot capturing is prohibited",
    "In ios, if you take screenshot then rules will violate and it will inform to examinator",
  ];
  return (
    <ModalContainer>
      <div className="tw-w-1/3 tw-mx-auto tw-bg-white tw-py-8 tw-px-5 tw-rounded-lg">
        <div onClick={handleClose} className="tw-float-right tw-cursor-pointer">
          <AiFillCloseSquare size={20} />
        </div>
        <h2 className="tw-text-xl krub tw-font-semibold textColor tw-text-center tw-uppercase">
          exam rules
        </h2>
        <ul className="tw-list-disc">
          {RulesArr.map((rule) => (
            <li
              key={rule}
              className="tw-text-sm tw-text-[#1b1b1b] krub tw-font-medium"
            >
              {rule}
            </li>
          ))}
        </ul>
        <div className="tw-flex tw-items-center tw-justify-center tw-mt-4 tw-gap-2">
          <input
            type="checkbox"
            name="accept_rules"
            className="tw-h-4 tw-w-4"
            id=""
          />
          <h6 className="tw-text-sm tw-font-normal tw-pt-1 k2d tw-text-[#1b1b1b] tw-text-opacity-90">
            I agree to the terms and conditions
          </h6>
        </div>
        <div className="tw-flex tw-mt-3 tw-justify-center">
          {" "}
          <button
            onClick={() => navigate("/exam")}
            className="tw-h-12 tw-w-44 tw-text-base k2d tw-font-semibold tw-text-white btngradient myShadow2 tw-rounded-lg tw-uppercase"
          >
            submit
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ExamRulesModal;
