import React from "react";
import Header from "../../components/Header";
import Img from "../../assets/policiesimg.png";
import Footer from "../../components/Footer";
import Faq1 from "../../assets/faq1.png";

const Policies = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <MainSection />
      <Footer />
    </div>
  );
};

const HeroSection = () => {
  return (
    <section className="policiesHero tw-w-full lg:tw-h-screen">
      <div className="container tw-py-16 lg:tw-py-0 tw-flex tw-flex-col lg:tw-flex-row tw-justify-start tw-gap-10 lg:tw-justify-between tw-h-full lg:tw-items-center">
        <div>
          <h1 className="tw-text-4xl poppins tw-uppercase tw-font-bold tw-tracking-[-1px] tw-leading-10 tw-text-white">
            terms &{" "}
            <span className="tw-text-4xl tw-uppercase poppins tw-font-bold tw-tracking-[-1px] tw-leading-10 ">
              policies
            </span>{" "}
          </h1>
          <p className="tw-pt-6 tw-text-2xl sansation tw-font-normal tw-w-96 tw-text-white">
            Lorem ipsum dolor sit amet consectetur. At purus bibendum lectus
            integer gravida fames amet ridiculus. Blandit rhoncus iaculis
            consequat neque dictum aenean nisl. proin aliquam. Elementum.
          </p>
        </div>
        <div>
          <img src={Img} alt="" className="" />
        </div>
      </div>
    </section>
  );
};

const MainSection = () => {
  const Arr = [
    {
      title: "Information Collection and Use",
      desc: "We collect certain information from users when they sign up for an account on our quiz application or participate in quizzes. This may include personal information such as name, email address, and demographic information. We use this information to personalize and improve the user experience and to communicate with users.",
    },
    {
      title: "Data Storage and Security",
      desc: "We take the security of user data very seriously and take appropriate measures to protect it from unauthorized access, disclosure, or modification. User data is stored on secure servers and is only accessible to authorized personnel.",
    },
    {
      title: "Third-Party Services",
      desc: "We may use third-party services to help us provide the quiz application and to analyze user behavior. These services may collect user data on our behalf, but we will never share personal information with third parties without user consent.",
    },
    {
      title: "Children's Privacy",
      desc: "Our quiz application is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13, and if we become aware of such information, we will take steps to delete it immediately.",
    },
    {
      title: "Changes to Privacy Policy",
      desc: "We reserve the right to modify this Privacy Policy at any time. Users will be notified of any changes through the quiz application or via email. By continuing to use the quiz application after any changes are made, users agree to the updated Privacy Policy. By including these details in your Privacy Policy section, you can help ensure that users are aware of how their data is collected, stored, and used when using your quiz application.",
    },
    {
      title: "Intellectual Property",
      desc: "All content on this quiz application, including text, images, and logos, is owned by Elite Quiz and is protected by copyright law. You may not use any content from the application without our express permission.",
    },
  ];
  const NewsArr = [
    {
      image: Faq1,
      date: "10th July, 2023",
      title: "Lorem ipsum dolor",
    },
    {
      image: Faq1,
      date: "10th July, 2023",
      title: "Lorem ipsum dolor",
    },
    {
      image: Faq1,
      date: "10th July, 2023",
      title: "Lorem ipsum dolor",
    },
  ];
  return (
    <section className="tw-w-full tw-py-20 tw-bg-[#f5f5f5]">
      <div className="container tw-flex tw-flex-col md:tw-flex-row tw-gap-10">
        <div className="lg:tw-w-[75%] tw-bg-white tw-py-16">
          <div className="tw-space-y-6 md:tw-px-20 tw-px-8">
            {Arr.map((item, i) => (
              <div key={i} className="">
                <h3 className="tw-text-lg tw-font-semibold segoe tw-text-main">
                  {item.title}
                </h3>
                <p className="tw-text-base tw-text-[#1b1b1b] tw-text-opacity-90 tw-font-normal segoe">
                  {item.desc}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="lg:tw-w-[25%] tw-space-y-4">
          {NewsArr.map((item, i) => (
            <div
              data-aos-duration="1500"
              data-aos="zoom-in-up"
              style={{
                boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
              }}
              className="tw-bg-white tw-w-full tw-h-42"
            >
              <img src={item.image} alt="" className="tw-h-36 tw-w-full" />
              <div className=" tw-p-2">
                <h6 className="tw-text-[15px] tw-text-[#1b1b1b] segoe tw-font-normal">
                  {item.date}
                </h6>
                <h6 className="tw-text-base  tw-text-[#1b1b1b] segoe tw-font-bold">
                  {item.title}
                </h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Policies;
