import { useState, useEffect } from "react";
import Img from "../../assets/faq.svg";
import { CiSquarePlus, CiSquareMinus } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { manageCategory } from "../../data/Reducers/CategoryReducer";

const FaqSection = () => {
	const [active, setActive] = useState(null);
	const Arr = [
			{
				question: "How are courses presented to the learners?",
				answer:
					"Once users log into their account, on the dashboard, they can click on Categories to access all the courses assigned to them. Course status reports are available on the dashboard.",
			},
			{
				question: "Can Users view which courses and tests they have taken?",
				answer:
					"Yes, learners will see their category completion status and check course progress and their current results on tests.",
			},
			{
				question: "Do we need to install anything?",
				answer:
					"Not really. Just make sure you have the latest version of your Web browser.",
			},
			{
				question: "What test questions are supported by Get-2-Know LMS?",
				answer:
					"Get-2-Know questions formats can be Multiple Choice Questions (MCQs) with single or multiple correct answers, true or false questions, short answers, fill-in-the-blank questions, freeform essay questions, matching questions and sequence questions.",
			},
			{
				question: "What language options are available? ",
				answer:
					"Get-2-Know is currently available in English. However, we are planning to add more language options.",
			},
		],
		dispatch = useDispatch(),
		{ category } = useSelector(s => s),
		[faq, setFaq] = useState(Arr);

	useEffect(() => {
		dispatch(manageCategory("get", "faq"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (category?.faq) {
			if (category?.faq?.docs?.length > 0) setFaq(category?.faq?.docs);
		}
	}, [category]);

	const handleFaq = i => {
		setActive(active === i ? null : i);
	};
	return (
		<section className="tw-bg-[#F4FDFF] tw-w-full tw-py-16">
			<div className="container">
				<div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between lg:tw-items-center">
					<img src={Img} alt="" className="tw-block lg:tw-hidden" />
					<h3 className="lg:tw-text-5xl tw-pt-12 lg:tw-pt-0 tw-text-3xl tw-text-main tw-font-extrabold nexa">
						Frequently Asked <br /> Question
					</h3>
					<img src={Img} alt="" className="tw-hidden lg:tw-block" />
				</div>
				<div className="tw-mt-10 tw-space-y-4">
					{faq?.map((it, i) => (
						<div
							style={{
								border: "0.5px solid rgba(11, 34, 57, 0.50)",
							}}
							key={i}
							className="tw-py-4 tw-px-6 tw-bg-white lg:tw-w-3/4 tw-rounded-2xl">
							<div
								onClick={() => handleFaq(i)}
								className="tw-flex tw-cursor-pointer tw-items-center tw-justify-between">
								<h5 className="tw-text-base tw-text-plain tw-font-normal nexa">
									{it?.question}
								</h5>
								{active === i ? <CiSquareMinus /> : <CiSquarePlus />}
							</div>
							{active === i && (
								<p className="tw-text-base tw-text-main segoe">{it?.answer}</p>
							)}
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default FaqSection;
