import React, { useContext, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Avatar from "../../assets/avatar.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { manageCategory } from "../../data/Reducers/CategoryReducer";
import moment from "moment";
import { GlobalState } from "../../data/Context";

const Bookmarks = () => {
	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	let { auth, category } = useSelector(s => s),
		navigate = useNavigate(),
		dispatch = useDispatch(),
		{ numberWithCommas, convertCamelCase } = useContext(GlobalState);

	const Headers = ["SN", "Type", "Coin Type", "Coins", "Description", "Date"];

	useEffect(() => {
		dispatch(manageCategory("get", "coinHistory"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!auth?.isAuth) navigate(-1);
	}, [auth?.isAuth, navigate]);

	return (
		<section>
			<div className="tw-min-h-screen tw-flex tw-justify-center tw-items-center tw-py-12 gamebg tw-w-full">
				<div className="category-bg container tw-py-10 tw-rounded-2xl">
					<div className="lg:tw-m-16 tw-m-8">
						<div className="tw-flex tw-justify-between tw-items-center">
							<div className="tw-h-14 tw-min-w-40 tw-rounded-3xl tw-ring tw-ring-[#ccc] tw-flex tw-justify-between tw-items-center tw-px-4">
								<img src={Avatar} alt="" className="" />
								<div>
									<small className="tw-text-base tw-pt-1 tw-font-normal satoshi tw-text-[#06094F]">
										{auth?.user?.lastName} {auth?.user?.firstName}
									</small>
								</div>
							</div>
							<div></div>
						</div>
						<div
							style={{
								boxShadow: "2px 2px 5px rgba(0, 0, 0, 1)",
							}}
							className="tw-rounded-xl tw-hidden lg:tw-block tw-min-h-96 tw-p-10 tw-w-full tw-bg-transparent tw-mt-12 ">
							<table className="tw-table tw-w-full">
								<thead>
									{Headers?.map((it, i) => (
										<th
											className={`satoshi tw-text-sm tw-font-medium tw-text-[#06094F] ${
												i === 0 ? "tw-w-8" : "tw-w-20" // Adjust the width as needed
											}`}>
											{it}
										</th>
									))}
								</thead>
								<tbody>
									{category?.coinHistory?.docs?.map((it, i) => (
										<tr className={`tw-border-b tw-border-b-[#CDDCE0] tw-h-16`}>
											<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
												{i + 1}
											</td>
											<td>
												<div className="tw-flex tw-gap-2 tw-items-center">
													<div>
														<small className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa tw-capitalize">
															{convertCamelCase(it?.type)}
														</small>
													</div>
												</div>
											</td>
											<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa tw-capitalize">
												{it?.coinType}
											</td>
											<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
												{numberWithCommas(it?.amount)}
											</td>
											<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
												{it?.description}
											</td>
											<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
												{moment(it?.createdAt).format("DD/MM/YYYY")}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Bookmarks;
