import React from "react";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/mainlogo.png";

const Footer = () => {
  const navigate = useNavigate();
  const Links = [
      {
        name: "About us",
        url: "/about-us",
      },
      {
        name: "Contact Us",
        url: "/contact-us",
      },
      {
        name: "FAQs",
        url: "/faq",
      },
    ],
    Links2 = [
      {
        name: "Instructions",
        url: "/instruction",
      },

      {
        name: "Policy",
        url: "/policy",
      },

      {
        name: "Terms | Conditions",
        url: "/terms",
      },
    ],
    Links3 = [
      {
        name: "Contact Details",
        url: "/contact-us",
      },
      {
        name: "+234 704 330 3000",
      },
      {
        name: "+234 809 293 3330",
      },
      {
        name: "Info@get-2-know.com",
      },
    ];

  return (
    <section className="tw-w-full tw-bg-main">
      <div className="container tw-border-t-2 tw-grid lg:tw-grid-cols-4 tw-grid-cols-2 tw-gap-6 tw-border-white tw-py-10">
        <div>
          <img src={Logo} alt="" className="tw-h-10" />
          <div className="tw-mt-6 lg:tw-mt-2">
            <p className="tw-text-white poppins tw-text-sm tw-font-normal">
              A Product of Martad Education & Skills Development Ltd.
            </p>
            <p className="tw-text-white lg:tw-pt-2 poppins tw-text-sm tw-font-normal">
              3b Alegbe Close, Mende, Maryland Lagos, Nigeria.
            </p>
          </div>
        </div>
        <div className="tw-list-none lg:tw-mt-4 tw-mt-16 lg:tw-space-y-3 tw-space-y-1 lg:tw-list-outside">
          {Links2?.map((it) => (
            <li
              onClick={() => navigate(it?.url)}
              className="lg:tw-text-sm tw-text-base tw-cursor-pointer poppins tw-text-white tw-font-normal"
            >
              {it?.name}
            </li>
          ))}
        </div>
        <div className="tw-list-none  lg:tw-space-y-3 tw-space-y-1 lg:tw-list-outside">
          {Links3?.map((it) => (
            <li
              onClick={() => navigate(it?.url)}
              className="lg:tw-text-sm tw-text-base tw-cursor-pointer poppins tw-text-white tw-font-normal"
            >
              {it?.name}
            </li>
          ))}
          <div className="tw-flex tw-gap-4 tw-mt-6">
            <div>
              <a
                rel="noreferrer"
                href="https://www.facebook.com/profile.php?id=61559171757221&mibextid=ZbWKwL"
                target="_blank"
              >
                <IconContext.Provider value={{ color: "#fff" }}>
                  <AiOutlineFacebook className="tw-cursor-pointer" size={15} />
                </IconContext.Provider>
              </a>
            </div>
            <div className="tw-cursor-pointer">
              <a
                href="https://www.instagram.com/__get2know?igsh=MW5yNnF3ZWJqd3M3OA=="
                target="_blank"
                rel="noreferrer"
              >
                <IconContext.Provider value={{ color: "#fff" }}>
                  <AiOutlineInstagram size={15} />
                </IconContext.Provider>
              </a>
            </div>
            <div>
              <a
                rel="noreferrer"
                href="https://twitter.com/playGet2know"
                target="_blank"
              >
                <IconContext.Provider value={{ color: "#fff" }}>
                  <AiOutlineTwitter className="tw-cursor-pointer" size={15} />
                </IconContext.Provider>
              </a>
            </div>
          </div>
        </div>

        <div className="tw-list-none  lg:tw-space-y-3 tw-space-y-1 lg:tw-list-outside">
          {Links?.map((it) => (
            <li
              onClick={() => navigate(it?.url)}
              className="lg:tw-text-sm tw-text-base tw-cursor-pointer poppins tw-text-white tw-font-normal"
            >
              {it?.name}
            </li>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Footer;
