import { ModalContainer } from "../quiz-category-modal";
import { AiFillCloseSquare } from "react-icons/ai";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ContestModal = ({ handleClose, state, setState, category, type }) => {
	let [active, setActive] = useState(0),
		{ auth } = useSelector(s => s),
		[coins, setCoins] = useState(0),
		navigate = useNavigate();

	return (
		<ModalContainer>
			<div className="tw-bg-[#f3f7fa] tw-p-8">
				<div onClick={handleClose} className="tw-float-right tw-cursor-pointer">
					<AiFillCloseSquare size={20} />
				</div>
				{active === 1 ? (
					<div className="tw-flex tw-justify-center tw-mt-8 tw-gap-6">
						<div
							style={{
								boxShadow: "0px 0px 1px -2px rgba(0, 0, 0, 0.25)",
							}}
							className="tw-p-6 tw-bg-white">
							<h6 className="krub tw-text-sm tw-font-semibold tw-text-center tw-text-[#1b1b1b]">
								Entry Fee
							</h6>
							<p className="tw-text-sm tw-font-medium krub tw-text-center tw-text-[#1b1b1b]">
								{coins} Coins
							</p>
						</div>
						<div
							style={{
								boxShadow: "0px 0px 1px -2px rgba(0, 0, 0, 0.25)",
							}}
							className="tw-p-6 tw-bg-white">
							<h6 className="krub tw-text-sm tw-font-semibold tw-text-center tw-text-[#1b1b1b]">
								Current Coins
							</h6>
							<p className="tw-text-sm tw-font-medium krub tw-text-center tw-text-[#1b1b1b]">
								{auth?.user?.points || 0} Coins
							</p>
						</div>
					</div>
				) : (
					<>
						<div className="tw-grid md:tw-grid-cols-2 tw-grid-cols-2 tw-px-8 tw-gap-10  lg:tw-grid-cols-5 tw-mt-5">
							{category?.[type]?.docs?.map((item, ind) => (
								<div
									key={ind}
									style={{
										boxShadow: "0px 0px 1px -2px rgba(0, 0, 0, 0.25)",
										background: state?.typeID === item?._id ? "#000" : "",
									}}
									onClick={() => {
										setState({ ...state, typeID: item?._id });
										setCoins(item?.entryFee);
									}}
									className="tw-p-6 tw-bg-white tw-cursor-pointer">
									<h6
										style={{
											color: state?.typeID === item?._id ? "#fff" : "",
										}}
										className="krub tw-text-sm tw-font-semibold tw-text-center tw-text-[#1b1b1b]">
										{item?.name || item?.title}
									</h6>
									{/* <p className="tw-text-sm tw-font-medium krub tw-text-center tw-text-[#1b1b1b]">
										{auth?.user?.coins || 0} Coins
									</p> */}
								</div>
							))}
						</div>
					</>
				)}
				<div className="tw-mt-5 tw-flex tw-justify-center">
					<button
						onClick={() => {
							if (active === 0 && type === "exam") {
								if (!state?.typeID) return toast.info("Please Select a type");
								return navigate("/new-quiz", {
									state: {
										type,
										key: category?.exam?.docs?.find(
											it => it?._id === state?.typeID
										)?.key,
									},
								});
							}
							if (active === 0 && type === "funAndLearn") {
								if (!state?.typeID) return toast.info("Please Select a type");
								return navigate("/categories", {
									state: {
										type,
										typeID: state?.typeID,
									},
								});
							}
							if (active === 0 && type === "contest") {
								if (!state?.typeID) return toast.info("Please Select a type");
								return setActive(1);
							}
							if (active === 1 && type === "contest")
								if (auth?.user?.points < coins)
									return toast.info(
										"You do not have sufficient coins to proceed"
									);
							return navigate("/categories", {
								state: {
									type,
									typeID: state?.typeID,
								},
							});
						}}
						className="tw-h-10 tw-w-32 btngradient tw-rounded-lg tw-text-white tw-text-base tw-font-semibold k2d">
						Play Now
					</button>
				</div>
			</div>
		</ModalContainer>
	);
};

export default ContestModal;
