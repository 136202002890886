import React, { useEffect, useRef, useMemo, useState } from "react";
import { io } from "socket.io-client";
import { useURL } from "./data/Config";
import { useDispatch, useSelector } from "react-redux";
import {
	getSocket,
	getSocketQuiz,
	getSocketComplete,
	getSocketPlayerJoin,
} from "./data/Reducers/SocketReducer";
import { toast } from "react-toastify";

const SocketClient = () => {
	const { auth, mainSocket } = useSelector(s => s);

	let [socket, setSocket] = useState(null),
		dispatch = useDispatch();
	const setUpSocket = async () => {
		let newSocket = io(useURL, {
			query: {
				userId: auth?.user?._id,
			},
			transports: ["websocket"],
		});
		newSocket.on("disconnect", () => {
			setSocket(null);
			setTimeout(setUpSocket, 3000);
		});
		setSocket(newSocket);
	};
	useEffect(() => {
		if (socket) {
			dispatch(getSocket(socket));
			// console.log({ socket, mainSocket: mainSocket?.socket });
			// setReconn(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket]);

	let audioRef = useRef(),
		audioRef2 = useRef();

	useMemo(
		() => {
			setUpSocket();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		mainSocket?.socket?.on("room-created", value => {
			console.log({ value }, "room-created");
		});
		// console.log({ socket });
		return () => {
			mainSocket?.socket?.off("room-created");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainSocket]);

	useEffect(() => {
		mainSocket?.socket?.on("room-joined", value => {
			console.log({ value }, "room-joined");
			if (value?.user) dispatch(getSocketPlayerJoin(value?.user));
		});
		// console.log({ socket });
		return () => {
			mainSocket?.socket?.off("room-joined");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainSocket]);

	useEffect(() => {
		mainSocket?.socket?.on("battle-complete", value => {
			console.log({ value }, "battle-complete");
			dispatch(getSocketComplete(value));
		});
		// console.log({ socket });
		return () => {
			mainSocket?.socket?.off("battle-complete");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainSocket]);

	useEffect(() => {
		mainSocket?.socket?.on("waiting", value => {
			console.log({ value }, "waiting");
			toast.info(value);
		});
		// console.log({ socket });
		return () => {
			mainSocket?.socket?.off("waiting");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainSocket]);

	useEffect(() => {
		mainSocket?.socket?.on("quiz", value => {
			console.log({ value }, "quiz");
			dispatch(getSocketQuiz(value));
		});
		// console.log({ socket });
		return () => {
			mainSocket?.socket?.off("quiz");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainSocket]);

	// useEffect(() => {
	// 	mainSocket?.socket?.on("user-status", value => {
	// 		if (!value.isOnline) {
	// 			console.log({ value }, "user-status");
	// 			setUpSocket();
	// 		}
	// 	});
	// 	// console.log({ socket });
	// 	return () => {
	// 		mainSocket?.socket?.off("user-status");
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [mainSocket]);

	return (
		<>
			<audio
				style={{ display: "none" }}
				controls
				ref={audioRef}
				className="notificationSound">
				{/* <source src={audio} type="audio/mp3" /> */}
			</audio>
			<audio
				controls
				ref={audioRef2}
				className="notificationSound"
				style={{ display: "none" }}>
				{/* <source src={audio2} type="audio/mp3" /> */}
			</audio>
		</>
	);
};

export default SocketClient;
