// import Img from "../../../assets/quizimg.svg";
import ScoreBoard from "../../Scoreboard";
import Button, { LoadingLoader } from "../../buttons/button";
import { tempData, tempInfo } from "../../../screens/daily-quiz";
import useFetch from "../../../data/useFetch";
import { LifeLineOption, MainTimer } from "../exam-quiz";
import { useState } from "react";
import { useEffect } from "react";

export const QuizArr = [
  "quizZone",
  "dailyQuiz",
  "mathQuiz",
  "guessTheWord",
  "funAndLearn",
  "contest",
  "audioQuiz",
  "trueOrFalse",
  "selfChallenge",
  "exam",
];

const MainQuiz = ({ data = tempData, info = tempInfo }) => {
  let {
    examData,
    handleNext,
    isQuestion,
    setIsSelected,
    isSelected,
    examLoad,
    loading,
    handlePrev,
    result,
    type,
    options,
    setOptions,
    handleTimeUp,
    duration,
    k,
  } = useFetch();

  if (examLoad && !examData) return <LoadingLoader />;

  return (
    <section className="tw-w-full tw-py-24 tw-bg-[#F5F5F5]">
      <div
        style={{
          boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
        }}
        className="container tw-relative tw-bg-[#FAFAFA] tw-p-10"
      >
        <h1 className="tw-text-xl segoe tw-font-bold tw-text-[#1b1b1b]">
          {isQuestion < examData?.length ? "Questions" : ""}
        </h1>
        {isQuestion < examData?.length && (
          <MainTimer
            duration={duration || 10}
            handleNext={handleTimeUp}
            k={k}
          />
        )}
        <div className="tw-mt-6">
          {isQuestion < examData?.length ? (
            <div>
              <div
                className={`lg:tw-w-3/4 tw-mx-auto tw-p-8 categorybg tw-bg-[#FF7A00]`}
              >
                <h2 className="itw-text-5xl tw-text-center tw-font-bold segoe tw-text-white">
                  Question {isQuestion + 1}/{examData?.length}
                </h2>

                <div className="tw-h-min-32 tw-w-3/4 clip1 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-bg-[#FFC700] tw-py-5">
                  <h3 className="tw-text-2xl tw-text-black tw-text-center lg:tw-w-96 tw-mx-auto tw-font-bold k2d">
                    {examData?.[isQuestion]?.question}
                  </h3>
                </div>
                <OptionsList
                  list={examData?.[isQuestion]?.options}
                  isQuestion={isQuestion}
                  isSelected={isSelected}
                  setIsSelected={setIsSelected}
                  options={options}
                  correctIndex={examData?.[isQuestion]?.correctOptionIndex}
                />
                <div className="tw-mt-12 tw-flex tw-justify-between">
                  <div className="tw-flex tw-gap-5">
                    <button className="btngradient tw-hidden md:tw-block tw-rounded-xl tw-base tw-font-semibold tw-text-white poppins tw-border tw-border-[#0966AB] tw-h-12 tw-w-32">
                      {isQuestion + 1} / {examData?.length}
                    </button>
                    {isQuestion >= 1 && (
                      <button
                        onClick={handlePrev}
                        className="btngradient tw-rounded-xl tw-base tw-font-semibold tw-text-white poppins tw-border tw-border-[#0966AB] tw-h-12 tw-w-32"
                      >
                        Previous
                      </button>
                    )}
                  </div>
                  <div>
                    {/* <button
											onClick={handleNext}
											className="btngradient tw-rounded-xl tw-base tw-font-semibold tw-text-white poppins tw-border tw-border-[#0966AB] tw-h-12 tw-w-32">
											{isQuestion === examData?.length - 1 ? "Submit" : "Next"}
										</button> */}
                    <Button
                      onClick={handleNext}
                      loading={loading}
                      title={
                        isQuestion === examData?.length - 1 ? "Submit" : "Next"
                      }
                      width={
                        "btngradient tw-rounded-xl tw-base tw-font-semibold tw-text-white poppins tw-border tw-border-[#0966AB] tw-h-12 tw-w-32"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ScoreBoard info={result} examData={examData} />
          )}
        </div>
        {isQuestion < examData?.length &&
          ["quizZone", "dailyQuiz"]?.includes(type) && (
            <LifeLineOption setOptions={setOptions} />
          )}
      </div>
    </section>
  );
};

export default MainQuiz;

export const AudienceCount = ({ options, option }) => {
  let [count, setCount] = useState(0);

  useEffect(() => {
    let reder = options?.reduce((ac, i) => (ac += i?.audienceCount), 0) || 0;
    if (reder) {
      let newer = (option?.audienceCount / reder) * 100;
      setCount(newer);
    }
  }, [option, options]);
  return (
    <>
      <span className="tw-ml-auto">{Number(count).toFixed(0)}%</span>
    </>
  );
};

export const OptionsList = ({
  options,
  list,
  setIsSelected,
  isSelected,
  isQuestion,
  correctIndex,
}) => {
  let [optionList, setOptionList] = useState(null);

  useEffect(() => {
    let rand;
    do {
      rand = Math.abs(Math.floor(Math.random() * list?.length - 1));
    } while (rand === correctIndex - 1);
    // console.log({ rand, list, correctIndex });
    if (options?.fiftyFifty && options?.fiftyFiftyNo === isQuestion) {
      let newList = list?.map((it, x) => {
        return { ...it, show: rand !== x && correctIndex - 1 !== x };
      });
      setOptionList(newList);
    } else setOptionList(list);
  }, [options, list, isQuestion, correctIndex]);

  return (
    <div className="tw-grid tw-grid-cols-2 tw-gap-6 tw-mt-6 tw-w-11/12 tw-mx-auto">
      {optionList
        ?.filter((it) => !it?.show)
        ?.map((option, i) => (
          <div key={i} className="tw-flex tw-gap-8 tw-w-full">
            {/* {isSelected?.option === option &&
                        isSelected?.answer - 1 === i && (
                          <div
                            style={{
                              background:
                                "linear-gradient(90deg, #0966AB 0%, #2F80ED 50%, #2D9EE0 100%)",
                            }}
                            className="tw-w-4 tw-h-4"
                          ></div>
                        )} */}

            <div
              onClick={() =>
                setIsSelected({
                  answer: i + 1,
                  option: option?.value || option,
                })
              }
              className={`tw-h-min-24 clip2   tw-cursor-pointer tw-p-[10%] tw-items-center tw-flex tw-justify-center tw-rounded-xl tw-w-full ${
                isSelected?.option === (option?.value || option) &&
                isSelected?.answer - 1 === i
                  ? "tw-bg-[#03d8d1]"
                  : "tw-bg-white"
              }`}
            >
              <span
                className={`tw-text-2xl k2d tw-font-normal tw-capitalize tw-text-[#172A3A] ${
                  options?.audiencePoll &&
                  options?.audiencePollNo === isQuestion
                    ? "tw-w-full tw-flex tw-items-center"
                    : ""
                }`}
              >
                {option?.value || option}
                {options?.audiencePoll &&
                  options?.audiencePollNo === isQuestion && (
                    <AudienceCount option={option} options={list} />
                  )}
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};
