import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
// import LeaderImg from "../assets/leader.png";
import Avatar from "../assets/avata.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { manageLeaderboard } from "../data/Reducers/LeaderboardReducer";
import { LoadingLoader } from "../components/buttons/button";
import { RankManager } from "../components/leaderboard/tablesection";

const Leader = () => {
  return (
    <div>
      <Header />
      <MainSection />
      <Footer />
    </div>
  );
};

const MainSection = () => {
  const [tab, setTab] = useState(0);
  const tabHeaders = ["All Time", "Day", "Week", "Month"];
  const Headers = ["SN", "Name", "Coins", "Points"];
  // tableData = [
  // 	{
  // 		name: "Kolawonle Henry",
  // 		position: "1st",
  // 		points: "600",
  // 	},
  // 	{
  // 		name: "Kolawonle Henry",
  // 		position: "1st",
  // 		points: "600",
  // 	},
  // 	{
  // 		name: "Kolawonle Henry",
  // 		position: "1st",
  // 		points: "600",
  // 	},
  // 	{
  // 		name: "Kolawonle Henry",
  // 		position: "1st",
  // 		points: "600",
  // 	},
  // 	{
  // 		name: "Kolawonle Henry",
  // 		position: "1st",
  // 		points: "600",
  // 	},
  // ];

  let [type, setType] = useState("all");
  const handleTab = (i) => {
    setType(
      i === 1 ? "daily" : i === 2 ? "weekly" : i === 3 ? "monthly" : "all"
    );
    setTab(i);
  };

  let { leaderboard, auth } = useSelector((s) => s),
    [state, setState] = useState(null),
    dispatch = useDispatch(),
    navigate = useNavigate();

  useEffect(() => {
    setState(
      type === "monthly"
        ? leaderboard?.monthly
        : type === "daily"
        ? leaderboard?.daily
        : type === "weekly"
        ? leaderboard?.weekly
        : leaderboard?.data
    );
  }, [
    leaderboard?.data,
    type,
    leaderboard?.daily,
    leaderboard?.monthly,
    leaderboard?.weekly,
  ]);

  useEffect(() => {
    dispatch(manageLeaderboard("get", { type }));
  }, [dispatch, type]);

  useEffect(() => {
    if (!auth?.isAuth) navigate(-1);
  }, [auth?.isAuth, navigate]);

  if (!state && !leaderboard?.data) return <LoadingLoader />;

  return (
    <div>
      <div className="tw-w-full tw-h-80 leader-header"></div>
      <div className="category-bg tw-w-full tw-py-16">
        <div className="container">
          <h1 className="tw-text-xl tw-font-normal tw-text-plain nexa">
            Result Log
          </h1>
          <div className="tw-mt-6 tw-flex tw-gap-6">
            {tabHeaders.map((item, i) => (
              <h2
                key={i}
                onClick={() => handleTab(i)}
                className={`tw-text-xl krub tw-cursor-pointer ${
                  tab === i
                    ? "tw-text-plain tw-font-bold"
                    : "tw-font-medium tw-text-[#1b1b1b] tw-text-opacity-90"
                } `}
              >
                {item}
              </h2>
            ))}
          </div>
          <div
            style={{
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 1)",
            }}
            className="tw-rounded-xl tw-hidden lg:tw-block tw-min-h-96 tw-p-10 tw-w-full tw-bg-transparent"
          >
            <table className="tw-table tw-w-full">
              <thead>
                {Headers?.map((it, i) => (
                  <th
                    className={`satoshi tw-text-sm tw-font-medium tw-text-[#06094F] ${
                      i === 0 ? "tw-w-8" : "tw-w-20" // Adjust the width as needed
                    }`}
                  >
                    {it}
                  </th>
                ))}
              </thead>
              <tbody>
                {state?.map((it, i) => (
                  <tr
                    className={`tw-border-b tw-border-b-[#CDDCE0] tw-h-16 ${
                      auth?.user?._id === it?.user?.[0]?._id && "tw-font-bold"
                    }`}
                  >
                    <td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
                      {i + 1}
                    </td>
                    <td>
                      <div className="tw-flex tw-gap-2 tw-items-center">
                        <img src={Avatar} alt="" className="" />
                        <div>
                          <small className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
                            {it?.user?.[0]?.lastName} {it?.user?.[0]?.firstName}{" "}
                            {auth?.user?._id === it?.user?.[0]?._id && "(You)"}
                          </small>
                        </div>
                      </div>
                    </td>
                    <td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
                      {it?.totalPoints}
                    </td>
                    <td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
                      <RankManager score={i} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="tw-block lg:tw-hidden">
            <div className="tw-mt-6 tw-space-y-3">
              {state?.map((it, i) => (
                <div
                  key={i}
                  className={`bordered tw-h-20 tw-px-4 tw-w-full tw-flex tw-justify-between tw-items-center ${
                    auth?.user?._id === it?.user?.[0]?._id && "tw-font-bold"
                  }`}
                >
                  <div>
                    <img src={Avatar} alt="" className="" />
                    <div className="tw-mt-2">
                      <small className="tw-text-sm tw-font-normal tw-text-plain nexa">
                        {it?.user?.[0]?.lastName} {it?.user?.[0]?.firstName}{" "}
                        {auth?.user?._id === it?.user?.[0]?._id && "(You)"}
                      </small>
                    </div>
                  </div>
                  <div>
                    <div>
                      {" "}
                      <small className="tw-text-sm tw-text-right tw-font-normal tw-text-[#017B06] nexa">
                        <RankManager score={i} />
                      </small>
                    </div>
                    <div className="tw-mt-22">
                      {" "}
                      <small className="tw-text-sm tw-font-normal tw-text-plain nexa">
                        {it?.totalPoints} Points
                      </small>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leader;
