import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Avatar from "../../assets/avatar.svg";
import { QuizBtn } from "../new-quiz";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GlobalState } from "../../data/Context";
import { ComingSoon } from "../../components/Modals/duet-category-modal";

const Pricing = () => {
	let { country } = useContext(GlobalState),
		navigate = useNavigate();

	useEffect(() => {
		if (country) {
			if (country?.toLowerCase() === "nigeria") navigate("/plans");
		} else {
			navigate("/plans");
		}
	}, [country, navigate]);

	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	const Pricings = [
		{
			duration: "Monthly",
			bg: "#86DFD9",
			features: [
				"One Day Free Trial",
				"9.99",
				"30 Days Access",
				"Full Access To All Features",
				"480 Bonus Coins",
				"Eligibility To Win Cash Prizes",
			],
			currency: "$",
			amount: 9.99,
		},
		{
			duration: "Yearly",
			bg: "#A0C7E4",
			features: [
				"One Day Free Trial",
				"99.99",
				"365 Days Access",
				"Full Access To All Features",
				"5000 Bonus Coins",
				"Eligibility To Win Cash Prizes",
			],
			currency: "$",
			amount: 99.99,
		},
	];

	let { auth } = useSelector(s => s);

	return (
		<section>
			<div className="tw-w-full tw-min-h-screen tw-py-16 gamebg">
				<div className="tw-p-12 tw-rounded-xl category-bg container">
					<div className="lg:tw-w-5/6 tw-mx-auto">
						<div className="tw-flex tw-justify-between tw-items-center">
							<div className="tw-h-14 tw-min-w-40 tw-rounded-3xl tw-ring tw-ring-[#ccc] tw-flex tw-gap-4 tw-justify-between tw-items-center tw-px-4">
								<img src={Avatar} alt="" className="" />
								<div>
									<small className="tw-text-base tw-pt-1 tw-font-normal satoshi tw-text-[#06094F]">
										{auth?.user?.lastName} {auth?.user?.firstName}
									</small>
								</div>
							</div>
							<QuizBtn text={"Get in touch"} />
						</div>
						<div className="tw-w-full tw-rounded-2xl tw-mt-10 tw-bg-main tw-p-10">
							<h2 className="tw-text-3xl tw-font-bold nexa tw-text-white tw-text-center">
								Pricing
							</h2>
							<div className="tw-mt-10 tw-flex tw-flex-col lg:tw-flex-row tw-justify-center tw-items-center tw-gap-10">
								{Pricings?.map((it, i) => (
									<PricingCard
										key={i}
										features={it?.features}
										duration={it?.duration}
										bg={it?.bg}
										currency={it?.currency}
										amount={it?.amount}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export const PricingCard = ({
	bg,
	duration,
	features,
	plan,
	amount,
	currency,
}) => {
	// const navigate = useNavigate();
	let { numberWithCommas } = useContext(GlobalState),
		[modal, setModal] = useState(null);

	return (
		<div>
			<div
				style={{
					background: bg,
				}}
				className="lg:tw-h-[480px] tw-h-[420px]  tw-relative tw-py-8 tw-px-4 tw-rounded-3xl tw-w-80 ">
				<h3 className="tw-text-2xl tw-font-bold nexa tw-text-plain">
					{duration} {plan ? "Plan" : "Payment"}
				</h3>
				<ul className="tw-mt-6 tw-list-disc tw-space-y-4 tw-pl-4">
					{features.map((it, i) => (
						<li
							key={i}
							className="tw-text-base tw-text-plain tw-font-bold nexa">
							{!isNaN(it) ? `${currency} ` : ""}
							{!isNaN(it) ? numberWithCommas(Number(it).toFixed(2)) : it}
						</li>
					))}
				</ul>
				<div className="tw-absolute tw-w-full tw-right-0 tw-bottom-8 tw-flex tw-justify-center">
					<QuizBtn
						onClick={() => setModal("true")}
						// onClick={() =>
						// 	navigate("/payment", { state: { amount, currency: "dollars", item } })
						// }
						text={"Pay Now"}
					/>
				</div>
			</div>
			{modal && <ComingSoon handleClose={() => setModal(null)} />}
		</div>
	);
};
export default Pricing;
