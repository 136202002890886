import React, { useContext, useEffect, useState } from "react";
import Logo from "../../components/Logo";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { IconContext } from "react-icons";
import HomeModal from "../Modals/homeModal";

import { useSelector } from "react-redux";
import { FaCaretDown, FaUserCircle } from "react-icons/fa";
// Header.js
import { Link as ScrollLink } from "react-scroll";
import { GlobalState } from "../../data/Context";

const Header = () => {
  const [modal, setModal] = useState(false);
  const [sticky, setSticky] = useState(false);
  const location = useLocation(),
    [moreModal, setMoreModal] = useState(false),
    navigate = useNavigate(),
    { auth } = useSelector((s) => s),
    currentPath = location.pathname,
    [nav, setNav] = useState(),
    { country } = useContext(GlobalState);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  const navList = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Play Games",
      url: "#main",
    },
    {
      name: "Invite Friends",
      url: "/invite",
    },
    {
      name: "Leaderboard",
      // url: auth?.isAuth ? "/leaderboard" : "/login",
      // url: "/leader",
      url: auth?.isAuth ? "/leader" : "/login",
    },
    {
      name: "Pricing",
      url: auth?.isAuth
        ? country
          ? country?.toLowerCase() === "nigeria"
            ? "/plans"
            : "/pricing"
          : "/plans"
        : "/login",
    },
    {
      name: "Buy Coin",
      url: auth?.isAuth
        ? country
          ? country?.toLowerCase() === "nigeria"
            ? "/buy-coin"
            : "/pricing"
          : "/buy-coin"
        : "/login",
    },
  ];
  const moreArr = [
    {
      name: "About us",
      url: "/about-us",
    },
    {
      name: "Instructions",
      url: "/instruction",
    },
    {
      name: "Contact Us",
      url: "/contact-us",
    },
    {
      name: "Policy",
      url: "/policy",
    },
    {
      name: "FAQs",
      url: "/faq",
    },
    {
      name: "Terms | Conditions",
      url: "/terms",
    },
  ];
  const MobileArr = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Play Games",
      url: "#main",
    },
    {
      name: "Invite Friends",
      url: "/invite",
    },
    {
      name: "Leaderboard",
      url: auth?.isAuth ? "/leader" : "/login",
    },
    {
      name: "Pricing",
      url: auth?.isAuth
        ? country
          ? country?.toLowerCase() === "nigeria"
            ? "/plans"
            : "/pricing"
          : "/plans"
        : "/login",
    },
    {
      name: "About us",
      url: "/about-us",
    },
    {
      name: "Instructions",
      url: "/instruction",
    },
    {
      name: "Contact Us",
      url: "/contact-us",
    },
    {
      name: "Policy",
      url: "/policy",
    },
    {
      name: "FAQs",
      url: "/faq",
    },
    {
      name: "Terms | Conditions",
      url: "/terms",
    },
  ];
  return (
    <div>
      <div
        className={
          sticky
            ? "tw-bg-white tw-w-full tw-z-50 tw-h-20 tw-fixed tw-top-0 tw-transition tw-duration-1000 tw-shadow-xl tw-ease-in-out"
            : "tw-h-20 tw-w-full tw-bg-white"
        }
      >
        <div className="container tw-h-full tw-flex tw-items-center tw-justify-between">
          <div className="">
            <Logo />
          </div>

          <div className="lg:tw-flex tw-hidden tw-mt-4 tw-gap-7">
            {navList.map((item, i) => (
              <React.Fragment key={i}>
                {item.url === "#main" ? (
                  <ScrollLink
                    style={{
                      textDecoration: "none !important",
                    }}
                    to={item.url.substring(1)}
                    smooth={true}
                    duration={500}
                    className={`segoe no-underline tw-cursor-pointer tw-text-base tw-p-2 tw-font-semibold tw-rounded-sm tw-text-[#00555A]`}
                  >
                    {item.name}
                  </ScrollLink>
                ) : (
                  <h6
                    onClick={() => navigate(`${item.url}`)}
                    key={i}
                    className={`segoe tw-cursor-pointer tw-text-base tw-p-2 tw-font-semibold tw-rounded-sm tw-text-[#00555A] ${
                      currentPath === item.url ? "tw-bg-[#ffffff66]" : ""
                    }`}
                  >
                    {item.name}
                  </h6>
                )}
              </React.Fragment>
            ))}
            <div
              onClick={() => setMoreModal(!moreModal)}
              className={`tw-flex tw-cursor-pointer tw-gap-2 tw-relative tw-items-center`}
            >
              <h6 className="segoe tw-cursor-pointer tw-text-base tw-font-semibold tw-rounded-sm tw-text-[#00555A]">
                More
              </h6>
              <IconContext.Provider value={{ color: "#00555A" }}>
                <FaCaretDown />
              </IconContext.Provider>
              {moreModal && (
                <div
                  style={{
                    boxShadow: "0px 4px 19px 0px rgba(0, 0, 0, 0.25)",
                  }}
                  className="tw-absolute tw-rounded-lg tw-w-80 tw-p-6 tw-right-16 tw-left-0 tw-bg-white tw-shadow-2xl tw-z-[1000] tw-top-14 tw-grid tw-grid-cols-2 tw-justify-between "
                >
                  {moreArr?.map((link, i) => (
                    <h6
                      key={i}
                      onClick={() => navigate(link?.url)}
                      className="segoe tw-cursor-pointer tw-text-base tw-font-semibold tw-rounded-sm tw-text-[#00555A]"
                    >
                      {link?.name}
                    </h6>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="tw-flex tw-gap-4 tw-items-center">
            <div
              onClick={() => navigate("/my-account")}
              className="tw-block lg:tw-hidden"
            >
              <IconContext.Provider value={{ color: "#00555A" }}>
                <FaUserCircle size={20} />
              </IconContext.Provider>
            </div>

            <div onClick={() => setNav(!nav)} className="tw-block lg:tw-hidden">
              <IconContext.Provider value={{ color: "black" }}>
                <AiOutlineMenu size={20} />
              </IconContext.Provider>
            </div>
          </div>

          <div className="lg:tw-block tw-hidden">
            <button
              onClick={() =>
                auth?.isAuth ? navigate("/my-account") : setModal(true)
              }
              className="tw-bg-[#00555A] tw-rounded tw-h-10 tw-w-32 tw-text-base tw-text-white segoe tw-font-semibold"
            >
              {`My Account`}
            </button>
          </div>
        </div>
        {nav && (
          <div className="tw-fixed tw-inset-0 tw-w-full tw-transition-transform tw-bg-white tw-border-b-2  tw-p-6 tw-z-50">
            <div className="tw-flex tw-justify-between">
              <Logo />
              <div onClick={() => setNav(false)} className="tw-cursor-pointer">
                {" "}
                <IconContext.Provider value={{ color: "#00555A" }}>
                  <AiOutlineClose size={20} />
                </IconContext.Provider>
              </div>
            </div>
            <div className="tw-list-none tw-mt-6 tw-space-y-4">
              {MobileArr?.map((item) => (
                <h6
                  onClick={() => navigate(`${item.url}`)}
                  className="tw-text-main poppins tw-text-xl tw-font-medium"
                >
                  {item.name}
                </h6>
              ))}
            </div>
          </div>
        )}
      </div>
      {modal && <HomeModal handleClose={() => setModal(false)} />}
    </div>
  );
};

export default Header;
