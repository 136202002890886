import React from "react";
import LogoLight from "../../assets/logolight.svg";
import Logodark from "../../assets/logo2.svg";
import { useNavigate } from "react-router-dom";

const Logo = () => {
  const navigate = useNavigate();
  return (
    <div>
      <img
        src={LogoLight}
        alt="logo"
        className="lg:tw-h-14 tw-cursor-pointer tw-h-10"
        onClick={() => navigate("/")}
      />
    </div>
  );
};

export const Logo2 = () => {
  const navigate = useNavigate();
  return (
    <div>
      <img
        onClick={() => navigate("/")}
        src={Logodark}
        alt=""
        className="tw-cursor-pointer lg:tw-h-14 tw-h-8"
      />
    </div>
  );
};
export default Logo;
