import React from "react";
import User1 from "../../../assets/users1.png";
import User2 from "../../../assets/users2.png";
import User3 from "../../../assets/users3.png";
import User4 from "../../../assets/users4.png";

const TopUserSection = () => {
  const Arr = [
    {
      img: User1,
      name: "John Doe",
      coins: 200,
    },
    {
      img: User2,
      name: "John Doe",
      coins: 200,
    },
    {
      img: User3,
      name: "John Doe",
      coins: 200,
    },
    {
      img: User4,
      name: "John Doe",
      coins: 200,
    },
    {
      img: User3,
      name: "John Doe",
      coins: 200,
    },
    {
      img: User1,
      name: "John Doe",
      coins: 200,
    },
  ];
  return (
    <div>
      <div className=" tw-mt-10 tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-16">
        {Arr.map((item, i) => (
          <Card key={i} img={item.img} name={item.name} coins={item.coins} />
        ))}
      </div>
    </div>
  );
};

export default TopUserSection;
const Card = ({ img, name, coins }) => {
  return (
    <div>
      <img src={img} alt="" className="" />
      <h6 className="tw-text-xl tw-font-bold lato tw-text-[#003559] tw-pt-3">
        {name}
      </h6>
      <p className="tw-text-2xl tw-font-bold lato tw-text-[#003559]">
        {coins}{" "}
        <span className="tw-text-2xl lato textColor tw-font-normal">coins</span>
      </p>
    </div>
  );
};
