import React from "react";
import ModalContainer from "./modalcontainer";
import Ex from "../../assets/ex.svg";
import { QuizBtn } from "../../screens/new-quiz";

const DeleteModal = ({ handleClose, loading, handleDelete }) => {
	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-mt-8 tw-flex tw-justify-center">
					<div>
						<img src={Ex} alt="" className="tw-mx-auto" />
						<div className="tw-mt-6">
							<h4 className="tw-text-lg tw-font-bold tw-text-center nexa tw-capitalize tw-text-plain">
								Are you sure ?
							</h4>
							<p className="tw-pt-2 tw-text-sm tw-font-normal tw-text-center tw-text-plain">
								You wont be able to revert this!
							</p>
							<div className="tw-flex tw-justify-center tw-gap-4">
								<QuizBtn
									text={"Yes, Delete"}
									loading={loading}
									onClick={handleDelete}
								/>
								<button
									onClick={handleClose}
									style={{
										background:
											"linear-gradient(180deg, #B6152B 0%, #F51C3A 100%)",
										boxShadow:
											"1.7353793971153371e-15px 28.34089469909668px 56.68178939819336px 0px #2C050A40, 5.514649825350893e-16px 9.00610637664795px 9.00610637664795px 0px #FE1D3D inset, -5.514649825350893e-16px -9.00610637664795px 9.00610637664795px 0px #AD1429 inset",
									}}
									className="tw-rounded-3xl tw-h-12 lg:tw-w-40 tw-w-28 tw-text-white tw-font-bold nexa tw-text-sm lg:tw-text-base">
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			</ModalContainer>
		</div>
	);
};

export default DeleteModal;
