import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ExamQuiz from "../../components/Quiz/exam-quiz";
// import MainQuiz from "../../components/Quiz/main-quiz";

const GkQuiz = () => {
  return (
    <section>
      <Header />
      <ExamQuiz />
      <Footer />
    </section>
  );
};

export default GkQuiz;
