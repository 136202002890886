import React from "react";

const MyInput = ({ type, value, placeholder, onChange, label }) => {
  return (
    <div>
      <p className="tw-text-sm tw-capitalize sansation tw-font-normal tw-text-black">
        {label}
      </p>
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className="tw-mt-2 tw-h-16 tw-w-full tw-bg-transparent tw-border tw-border-[#ccc] tw-rounded-[10px] tw-pl-8 tw-text-[#1b1b1bcc] tw-text-sm sansation tw-font-normal"
      />
    </div>
  );
};

export default MyInput;
