import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageRender from "./PageRender";

// import Home from "./screens/Home";
import AuthHome from "./screens/authhome";
import { useSelector } from "react-redux";
import SocketClient from "./SocketClient";

const Routers = () => {
  let { auth } = useSelector((s) => s);
  return (
		<>
			{auth?.isAuth && <SocketClient />}
			<ToastContainer position="top-right" />
			<Routes>
				<Route path="/" element={<AuthHome />} />
				<Route path="/:page" element={<PageRender />} />
				<Route path="/:page/:id" element={<PageRender />} />
				<Route path="/:page/:id/:step" element={<PageRender />} />
			</Routes>
		</>
	);
};

export default Routers;
