import React from "react";
import Lock from "../../../assets/lock.svg";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { AiOutlineClose } from "react-icons/ai";

const HomeModal = ({ handleClose }) => {
  const navigate = useNavigate();
  return (
    <div className="tw-fixed tw-z-[1000] tw-bg-opacity-30 tw-flex tw-justify-center tw-items-center  tw-backdrop-blur-sm tw-inset-0 tw-bg-black ">
      <div className="tw-h-96 tw-relative tw-w-96 category-bg tw-rounded-xl tw-flex tw-justify-center tw-items-center">
        <div
          onClick={handleClose}
          className="tw-absolute tw-cursor-pointer tw-top-8 tw-right-8"
        >
          <IconContext.Provider value={{ color: "#000" }}>
            <AiOutlineClose size={20} />
          </IconContext.Provider>
        </div>
        <div>
          <img src={Lock} alt="lock_icon" className="tw-mx-auto" />
          <h2 className="k2d tw-text-base tw-text-center tw-pt-3 tw-text-[#1b1b1b] tw-font-medium">
            Log In to have access to play
          </h2>
          <div className="tw-mx-auto tw-w-32 tw-mt-8">
            <button
              onClick={() => navigate("/login")}
              className="tw-bg-[#F7631B] tw-h-10 tw-w-full tw-font-semibold segoe tw-text-base tw-text-white tw-rounded"
            >
              Log In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeModal;
