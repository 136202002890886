import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { QuizBtn } from "../new-quiz";
import Avatar from "../../assets/avatar.svg";
import Card from "../../assets/card.svg";
import Momo from "../../assets/momo.png";
import Stripe from "../../assets/stripe.svg";
import Mtn from "../../assets/mtn.png";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../data/Context";
import { ComingSoon } from "../../components/Modals/duet-category-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import moment from "moment";
import ModalContainer from "../../components/Modals/modalcontainer";
import { IconContext } from "react-icons";
import { IoMdClose } from "react-icons/io";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import {
	useStripe,
	useElements,
	PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { returnErrors } from "../../data/Reducers/ErrorReducer";

const Payment = () => {
	let { state } = useLocation(),
		{ auth } = useSelector(s => s),
		navigate = useNavigate();

	useEffect(() => {
		if (!state?.amount) {
			navigate(-1);
		}
	}, [state, navigate]);

	useEffect(() => {
		if (!state || !auth?.isAuth) navigate(-1);
	}, [state, navigate, auth?.isAuth]);

	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	const { country } = useContext(GlobalState);
	let { state } = useLocation();

	let mainArr = [
		state?.paymentType !== "not-airtime" && {
			title: "Pay With Airtime (MTN user Only)",
			icon: Mtn,
			platform: "mtn",
		},
		{
			title: "Pay With MOMO (MTN user only)",
			icon: Momo,
			platform: "momo",
		},
		{
			title: "Pay With Credit/Debit Card",
			icon: Card,
			platform: "paystack",
		},
	];

	
	const MainArr = country
		? country?.toLowerCase() === "nigeria"
			? mainArr
			: [
					{
						title: "Pay With Stripe",
						icon: Stripe,
						platform: "stripe",
					},
			  ]
		: mainArr;
	let { auth } = useSelector(s => s),
		[platform, setPlatform] = useState("");

	useEffect(() => {
		if (platform) {
			if (platform === "mtn") {
				let link =
					"http://ng-app.com/MARTAD/GET2KNOW-24-Yes-23410220000028099-web?trxId=xxx";
				if (state?.item?.plan_id === "weekly")
					link =
						"http://ng-app.com/MARTAD/GET2KNOW-168-Yes-23410220000028100-web?trxId=xxx";
				if (state?.item?.plan_id === "monthly")
					link =
						"http://ng-app.com/MARTAD/GET2KNOW-720-Yes-23410220000028101-web?trxId=xxx";

				window.open(link, "_blank");
				setPlatform("");
			}
		}
	}, [platform, state]);

	return (
		<div>
			<div className="tw-w-full tw-min-h-screen tw-py-16 gamebg">
				<div className="tw-p-12 tw-rounded-xl category-bg container">
					<div className="lg:tw-w-5/6 tw-mx-auto">
						<div className="tw-flex tw-justify-between tw-items-center">
							<div className="tw-h-14 tw-min-w-40 tw-rounded-3xl tw-ring tw-ring-[#ccc] tw-flex tw-justify-between tw-items-center tw-px-4">
								<img src={Avatar} alt="" className="" />
								<div>
									<small className="tw-text-base tw-pt-1 tw-font-normal satoshi tw-text-[#06094F]">
										{auth?.user?.lastName} {auth?.user?.firstName}
									</small>
								</div>
							</div>
							<QuizBtn text={"Get in touch"} />
						</div>
						<div className="tw-w-full tw-rounded-2xl tw-mt-10 tw-bg-main tw-p-10">
							<h2 className="tw-text-3xl tw-font-bold nexa tw-text-white tw-text-center">
								Payment Methods
							</h2>
							<div className="tw-w-5/6 tw-mt-10 tw-mx-auto tw-space-y-4">
								{MainArr?.filter(e => e)?.map(it => (
									<div className="tw-w-full tw-bg-[#D9C880] lg:tw-h-24 tw-py-4 lg:tw-py-0 tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-justify-between tw-px-5 tw-rounded-xl">
										<div className="tw-flex tw-gap-3 tw-items-center">
											<img src={it?.icon} alt="" className="tw-h-10" />
											<strong className="lg:tw-text-xl tw-text-sm tw-text-center lg:tw-text-left tw-font-bold nexa tw-text-plain">
												{it?.title}
											</strong>
										</div>
										<div className="tw-mt-4 tw-flex lg:tw-block lg:tw-justify-normal tw-justify-center lg:tw-mt-0">
											<QuizBtn
												text={"Pay Now"}
												onClick={() => setPlatform(it?.platform)}
											/>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			{["momo", "stripe"]?.includes(platform) && (
				<ComingSoon handleClose={() => setPlatform("")} />
			)}
			{platform === "paystack" && (
				<MakeCardsPaystack handleClose={() => setPlatform("")} />
			)}
			{/* {platform === "stripe" && (
				<ParentComponent handleClose={() => setPlatform("")} />
			)} */}
		</div>
	);
};

export default Payment;

export const StripePayment = ({ secret, secretErr, handleClose }) => {
	const stripe = useStripe(),
		elements = useElements(),
		[loading, setLoading] = useState(null);

	let handleSubmit = async e => {
		e.preventDefault();
		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		if (!secret) return;
		setLoading(true);
		try {
			var result = await stripe.confirmPayment({
				elements,
				confirmParams: {
					return_url: `${window.location.origin}/schedule/confirmBooking`,
				},
				redirect: "if_required",
			});
			console.log({ result });
			if (result?.error) {
				setLoading(false);
				toast.error(result?.error?.message, { autoClose: 5000 });
			} else if (
				result?.paymentIntent &&
				result?.paymentIntent?.status === "succeeded"
			) {
				try {
					var res2 = await axios.post("/api/v1/subscription/stripe", {
						pay_ref: result?.paymentIntent,
						channel: "stripe",
					});
					toast.success(res2.data.msg, { autoClose: 5000 });
					// setState(init);
					handleClose();
					setLoading(false);
				} catch (error) {
					setLoading(false);
					if (error?.response?.data?.data) {
						console.log({ err1: error?.response?.data?.data });
						error?.response?.data?.data.forEach(element => {
							toast.error(element.msg, { autoClose: 5000 });
						});
					}
				}
			} else {
				setLoading(false);
				toast.error("Unexpected error: Payment could not be completed", {
					autoClose: 5000,
				});
			}

			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log({ err2: error });
			if (error?.response?.data?.data) {
				console.log({ err: error?.response?.data?.data });
				error?.response?.data?.data.forEach(element => {
					toast.error(element.msg, { autoClose: 5000 });
				});
			}
		}
		setLoading(false);
	};
	return (
		<>
			<ModalContainer handleClose={() => {}}>
				<div
					style={{
						overflowY: "scroll",
					}}
					className="lg:tw-w-full  tw-max-h-[550px] tw-rounded-xl twpb-6 category-bg">
					<div className="tw-pb-10">
						<div
							onClick={() => {
								handleClose();
							}}
							className="tw-float-right tw-size-5 tw-rounded-lg tw-bg-black tw-flex tw-justify-center tw-items-center tw-cursor-pointer">
							<IconContext.Provider value={{ color: "white" }}>
								<IoMdClose size={15} />
							</IconContext.Provider>
						</div>
					</div>
					{secret ? (
						<PaymentElement />
					) : secretErr ? (
						<>
							<p className="text-center">
								{secretErr || `Connectivity error, please refresh`}
							</p>
						</>
					) : (
						<>
							<div className="flex align-center justify-center">
								<ClipLoader size={55} />
							</div>
						</>
					)}
					{secret && (
						<div className="tw-flex tw-align-center tw-justify-center">
							<QuizBtn
								text={"Make Payment"}
								loading={loading}
								onClick={handleSubmit}
							/>
						</div>
					)}
				</div>
			</ModalContainer>
		</>
	);
};

export const MakeCardsPaystack = ({ handleClose }) => {
	let { auth } = useSelector(s => s),
		{ state } = useLocation(),
		[reference, setReference] = useState(""),
		navigate = useNavigate(),
		// { msisdn } = useContext(GlobalState),
		dispatch = useDispatch(),
		config = {
			email: auth?.user?.email || auth?.user?.createdBy?.email,
			amount: Number(state?.amount * 100),
			publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
			metadata: {
				name: `${auth?.user?.firstName || auth?.user?.createdBy?.firstName} ${
					auth?.user?.lastName || auth?.user?.createdBy?.lastName
				}`,
				phone: auth?.user?.phone || auth?.user?.createdBy?.phone,
			},
			reference: reference ? reference?.toString()?.split("|")?.join("") : "",
		},
		initializePayment = usePaystackPayment(config);
	console.log({ config });
	useEffect(() => {
		if (state?.amount) {
			setReference(moment().format("YYYYMMDDHHmmssASSS"));
		}
	}, [state?.amount]);

	let handleSuccess = async item => {
		setLoading(true);
		console.log({ item }, "async");
		try {
			var res = await axios.post("/api/v1/subscription/paystack", {
				pay_ref: item?.reference,
				// channel: "paystack",
				plan_id: state?.item?.plan_id,
				// msisdn,
			});
			console.log({ res }, "paystack");
			toast.success(res?.data?.message);
			navigate("/");
			handleClose();
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
			// navigate(-1);
			setLoading(false);
		}
	};

	// you can call this function anything
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log("closed");
		handleClose();
	};

	const onSuccess = ref => {
		console.log({ ref }, "success");
		handleSuccess(ref);
	};

	useEffect(() => {
		if (reference) {
			initializePayment({ onSuccess, onClose });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reference]);

	let [loading, setLoading] = useState(false);

	return (
		<>
			<ModalContainer handleClose={() => {}}>
				<div
					style={{
						overflowY: "scroll",
					}}
					className="lg:tw-w-full  tw-max-h-[550px] tw-rounded-xl twpb-6 category-bg">
					<div className="tw-pb-10">
						<div
							onClick={() => {
								handleClose();
							}}
							className="tw-float-right tw-size-5 tw-rounded-lg tw-bg-black tw-flex tw-justify-center tw-items-center tw-cursor-pointer">
							<IconContext.Provider value={{ color: "white" }}>
								<IoMdClose size={15} />
							</IconContext.Provider>
						</div>
					</div>
					<div className="tw-flex tw-align-center tw-justify-center">
						<QuizBtn text={"Paystack"} loading={loading} onClick={() => {}} />
					</div>
				</div>
			</ModalContainer>
		</>
	);
};

export const ParentComponent = ({ handleClose }) => {
	let location = useLocation(),
		[secret, setSecret] = useState(""),
		[secretErr, setSecretErr] = useState("");

	const stripePromise = loadStripe(process.env.REACT_APP_STIPE_PUBLISH_KEY);

	useEffect(() => {
		if (location?.state?.amount) {
			let getSecret = async () => {
				let stat = {
					amount: location?.state?.amount,
				};
				try {
					var res = await axios.put("/api/v1/payment/booking", {
						...stat,
					});
					console.log({ res });
					setSecret(res?.data?.data, { autoClose: 5000 });
				} catch (err) {
					let thisErr = err?.message;

					if (err) console.log({ error: err.response?.data, err });
					if (err?.response?.status === 429) toast.error(err?.response?.data);
					let error = err.response?.data?.error;
					if (error) {
						thisErr = error?.toString();
					} else {
						thisErr = err?.response?.data?.message;
					}
					// navigate(-1);
					setSecretErr(thisErr);
				}
			};
			getSecret();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.state]);

	return (
		<>
			{secret && stripePromise ? (
				<Elements stripe={stripePromise} options={{ clientSecret: secret }}>
					<StripePayment
						secret={secret}
						secretErr={secretErr}
						handleClose={handleClose}
					/>
				</Elements>
			) : (
				<>
					<StripePayment
						secret={secret}
						secretErr={secretErr}
						handleClose={handleClose}
					/>
				</>
			)}
		</>
	);
};
