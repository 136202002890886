import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Star from "../assets/star.png";
import Back from "../assets/back.svg";
import Review from "../assets/review.svg";
import Share from "../assets/share.svg";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Scores = () => {
  return (
    <div>
      <Header />
      <MainSection />
      <Footer />
    </div>
  );
};

const MainSection = () => {
  const infos = [
      {
        title: "Temiloluwa K. Paul(YOu)",
        value: "87",
      },
      {
        title: "Paul Kolapo",
        value: "09",
      },
    ],
    { width, height } = useWindowSize();
  return (
    <div className="tw-min-h-screen tw-flex tw-justify-center tw-items-center tw-py-12 gamebg tw-w-full">
      <Confetti width={width} height={height} />
      <div className="tw-p-10 tw-rounded-xl category-bg container">
        <div className="tw-w-5/6 tw-mx-auto tw-grid tw-h-full tw-items-center lg:tw-grid-cols-2 tw-gap-8">
          <img src={Star} alt="" className="" />
          <div>
            <h1 className="tw-text-5xl tw-text-black tw-font-normal nexa">
              Results
            </h1>
            <h2 className="tw-text-3xl tw-text-[#004994] tw-pt-4 nexa tw-font-bold">
              HURRAY!!!
            </h2>
            <div className="tw-mt-4">
              <h6 className="tw-text-2xl tw-text-plain tw-font-normal nexa">
                Congratulations lola Aisha
              </h6>
              <h6 className="tw-text-2xl tw-text-plain tw-font-normal nexa">
                You won the group battle
              </h6>
            </div>
            <div className="tw-mt-6 tw-grid tw-grid-cols-2 tw-gap-4">
              {infos?.map((it, i) => (
                <div
                  key={i}
                  style={{
                    boxShadow: "0px 0px 6.7px 0px rgba(0, 0, 0, 0.15)",
                  }}
                  className="tw-rounded-md tw-p-5 tw-relative tw-bg-white"
                >
                  <div>
                    <strong
                      className={`tw-text-3xl tw-font-normal nexa ${
                        it?.title === "Score accuracy"
                          ? "tw-text-[#31BF51]"
                          : it?.title === "Total Question"
                          ? "tw-text-[#3E91E6]"
                          : it?.title === "Correct"
                          ? "tw-text-[#3E91E6]"
                          : "tw-text-[#F04F23]"
                      }`}
                    >
                      {it?.value}
                    </strong>
                  </div>
                  <div className="">
                    <small className="tw-text-sm nexa tw-font-medium tw-text-plain">
                      {it?.title}
                    </small>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.25",
              }}
              className="tw-w-full tw-py-3 tw-flex tw-justify-between tw-mt-10 tw-rounded-2xl tw-px-5"
            >
              <div className="tw-w-">
                <img src={Back} alt="" className="-tw-mb-2 tw-cursor-pointer" />
                <span className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                  Back
                </span>
              </div>
              <div>
                <img
                  src={Review}
                  alt=""
                  className="-tw-mb-2 tw-cursor-pointer"
                />
                <spam className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                  Review answer
                </spam>
              </div>
              <div>
                <img
                  src={Share}
                  alt=""
                  className="-tw-mb-2 tw-cursor-pointer"
                />
                <span className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                  Share score
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ScoreCompDuet = ({ info }) => {
  let { auth } = useSelector((s) => s),
    navigate = useNavigate(),
    [isWinner, setIsWinner] = useState("");

  useEffect(() => {
    if (auth?.user && info) {
      let user = info?.find((it) => auth?.user?._id === it?.user?.[0]?._id),
        nonUserFilter = info?.filter(
          (it) => auth?.user?._id !== it?.user?.[0]?._id
        );
      if (nonUserFilter?.every((it) => it?.total === user?.total))
        setIsWinner("draw");
      else if (nonUserFilter?.every((it) => user?.total > it?.total))
        setIsWinner("winner");
      else {
        setIsWinner("lost");
      }
    }
  }, [info, auth?.user]);
  const // infos = [
    //     {
    //       title: "Temiloluwa K. Paul(YOu)",
    //       value: "87",
    //     },
    //     {
    //       title: "Paul Kolapo",
    //       value: "09",
    //     },
    //   ],
    { width, height } = useWindowSize();

  return (
    <div className="tw-min-h-screen tw-flex tw-justify-center tw-items-center tw-py-12 gamebg tw-w-full">
      {isWinner === "winner" && <Confetti width={width} height={height} />}
      <div className="tw-p-10 tw-rounded-xl category-bg container">
        <div className="tw-w-5/6 tw-mx-auto tw-grid tw-h-full tw-items-center lg:tw-grid-cols-2 tw-gap-8">
          <img src={Star} alt="" className="" />
          <div>
            <h1 className="tw-text-5xl tw-text-black tw-font-normal nexa">
              Results
            </h1>
            {isWinner === "winner" && (
              <h2 className="tw-text-3xl tw-text-[#004994] tw-pt-4 nexa tw-font-bold">
                HURRAY!!!
              </h2>
            )}
            <div className="tw-mt-4">
              <h6 className="tw-text-2xl tw-text-plain tw-font-normal nexa">
                {isWinner === "winner"
                  ? "Congratulations!!!"
                  : isWinner === "draw"
                  ? "Hey!!!"
                  : "Oops!!!"}{" "}
                {auth?.user?.lastName}
              </h6>
              <h6 className="tw-text-2xl tw-text-plain tw-font-normal nexa">
                {isWinner === "winner"
                  ? "You won the game!!!"
                  : isWinner === "draw"
                  ? "The game ended in draw"
                  : "You lost the game"}
              </h6>
            </div>
            <div className="tw-mt-6 tw-grid tw-grid-cols-2 tw-gap-4">
              {info?.map((it, i) => (
                <div
                  key={i}
                  style={{
                    boxShadow: "0px 0px 6.7px 0px rgba(0, 0, 0, 0.15)",
                  }}
                  className="tw-rounded-md tw-p-5 tw-relative tw-bg-white"
                >
                  <div>
                    <strong
                      className={`tw-text-3xl tw-font-normal nexa ${
                        isWinner === "winner" &&
                        auth?.user?._id === it?.user?.[0]?._id
                          ? "tw-text-[#31BF51]"
                          : it?.title === "Total Question"
                          ? "tw-text-[#3E91E6]"
                          : it?.title === "Correct"
                          ? "tw-text-[#3E91E6]"
                          : "tw-text-[#F04F23]"
                      }`}
                    >
                      {it?.total}
                    </strong>
                  </div>
                  <div className="">
                    <small className="tw-text-sm nexa tw-font-medium tw-text-plain">
                      {it?.user?.[0]?.lastName} {it?.user?.[0]?.firstName}{" "}
                      {auth?.user?._id === it?.user?.[0]?._id && <>(You)</>}
                    </small>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.25",
              }}
              className="tw-w-full tw-py-3 tw-flex tw-justify-between tw-mt-10 tw-rounded-2xl tw-px-5"
            >
              <div className="tw-w-" onClick={() => navigate("/")}>
                <img src={Back} alt="" className="-tw-mb-2 tw-cursor-pointer" />
                <span className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                  Back
                </span>
              </div>
              <div className="tw-hidden">
                <img
                  src={Review}
                  alt=""
                  className="-tw-mb-2 tw-cursor-pointer"
                />
                <spam className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                  Review answer
                </spam>
              </div>
              <div>
                <img
                  src={Share}
                  alt=""
                  className="-tw-mb-2 tw-cursor-pointer"
                />
                <span className="tw-text-sm tw-font-semibold tw-mx-auto tw-text-center tw-text-plain nexa">
                  Share score
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scores;
