import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/buttons/button";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import { returnErrors } from "../data/Reducers/ErrorReducer";

const ForgotPassword = () => {
	const navigate = useNavigate(),
		[email, setEmail] = useState(""),
		dispatch = useDispatch(),
		[loading, setLoading] = useState("");

	let handleSubmit = async e => {
		e.preventDefault();
		if (!email) return;
		setLoading(true);
		try {
			var res = await axios.post(`/api/v1/auth/request-reset-password`, {
				email,
			});
			toast.success(res.data.message, { autoClose: false });
			navigate("/new-password", { state: { email } });
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
		setLoading(false);
	};

	return (
		<div>
			<div>
				<div className="tw-h-screen tw-w-full lg:tw-grid lg:tw-grid-cols-2">
					<div className="login-main tw-hidden lg:tw-block tw-h-full tw-w-full"></div>
					<div className="tw-h-full category-bg tw-w-full tw-flex tw-justify-center tw-items-center">
						<form action="" onSubmit={handleSubmit}>
							<h2 className="tw-text-3xl tw-font-bold tw-text-[#002224] satoshi">
								Forgot Password
							</h2>
							<div className="tw-space-y-4 tw-mt-8">
								<div className="">
									<small className="tw-text-base nexa tw-text-plain">
										Email
									</small>
									<div>
										{" "}
										<input
											type="email"
											name="username"
											onChange={e => setEmail(e?.target?.value)}
											value={email}
											style={{
												border: "0.6px solid rgba(0, 85, 90, 0.50)",
											}}
											className="tw-h-12 tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-96 tw-rounded-xl"
										/>
									</div>
								</div>
								<div className="tw-flex tw-justify-center tw-mt-4">
									<Button
										onClick={handleSubmit}
										loading={loading}
										title={"Submit"}
										width={
											"tw-h-10 tw-bg-main tw-w-80 tw-rounded-xl tw-font-semibold tw-text-white satoshi tw-text-bbase"
										}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
