import React, { useState } from "react";
import Header from "../../components/Header";
import { Explore } from "../Home";
import Footer from "../../components/Footer";
import Img from "../../assets/inst.svg";
import { PurifiedText } from "../about-us";

const Instruction = () => {
	return (
		<div>
			<Header />
			<Mainsection />
			<Explore />
			<Footer />
		</div>
	);
};

const Mainsection = () => {
	return (
		<section className="tw-w-full tw-bg-[#F4FDFF] tw-py-10 tw-min-h-screen tw-overflow-visible">
			<div className="container">
				<div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-justify-between">
					<img
						src={Img}
						alt=""
						className=" tw-rounded-lg tw-block  lg:tw-hidden"
					/>
					<h1 className="tw-text-6xl tw-pt-12 lg:tw-pt-0 tw-font-extrabold tw-text-main nexa">
						Instructions
					</h1>
					<img
						src={Img}
						alt=""
						className=" tw-rounded-lg tw-hidden lg:tw-block"
					/>
				</div>
				<MainSection />
			</div>
		</section>
	);
};

const MainSection = () => {
	const Arr = [
		{
			title: "Eligibility",
			desc: "All player/users can play contest.",
		},
		{
			title: "How to Enter",
			desc: "User can Play Contest by spending number of coins specified as an entry fees in contest details.",
		},
		{
			title: "choice of law",
			desc: "All the Contest and Operations are belongs to ScreenClassTeam. and Apple is not involved in any way with the contest.",
		},
		{
			title: "sponsor",
			desc: " Sponsors data will be shown there in contest as there are many sponsors for contest.",
		},
	];

	let [type, setType] = useState("");

	return (
		<div className="tw-mt-16">
			<PurifiedText type={type} setType={setType} section={"howToPlay"} />
			{!type && (
				<>
					<div className="tw-mt-10 segoe tw-text-[#1b1b1b]">
						<p className="tw-text-[#1b1b1b] md:tw-text-base tw-text-sm tw-font-medium">
							Online Quiz game has 4 or 5 options
						</p>
						<p className="tw-text-[#1b1b1b] md:tw-text-base tw-text-sm tw-font-medium">
							For each right answer 5 points will be given.
						</p>
						<p className="tw-text-[#1b1b1b] md:tw-text-base tw-text-sm tw-font-medium">
							Minus 2 points for each question.
						</p>
					</div>
					<div className="tw-mt-8 segoe">
						<p className="tw-text-[#1b1b1b] md:tw-text-base tw-text-sm tw-font-medium">
							Use of Lifeline : You can use only once per level
						</p>
						<p className="tw-text-[#1b1b1b] md:tw-text-base tw-text-sm tw-font-medium">
							50 - 50 : For remove two option out of four (deduct 4 coins).
						</p>
						<p className="tw-text-[#1b1b1b] md:tw-text-base tw-text-sm tw-font-medium">
							Skip question : You can pass question without minus points(deduct
							4 coins).
						</p>
						<p className="tw-text-[#1b1b1b] md:tw-text-base tw-text-sm tw-font-medium">
							Audience poll : Use audience poll to check other users choose
							option(deduct 4 coins).
						</p>
						<p className="tw-text-[#1b1b1b] md:tw-text-base tw-text-sm tw-font-medium">
							Reset timer : Reset timer again if you needed more time score
							(deduct 4 coins). Leaderboard
						</p>
						<p className="tw-text-[#1b1b1b] md:tw-text-base tw-text-sm tw-font-medium">
							You can compare your score with other users of app.
						</p>
					</div>
					<div className="tw-mt-8 segeo">
						<p className="tw-text-[#1b1b1b] md:tw-text-base tw-text-sm tw-font-semibold">
							Contest Rules
						</p>
						<p className="tw-text-[#1b1b1b] md:tw-text-base tw-text-sm tw-font-normal">
							To provide fair and equal chance of winning to all Online Quiz
							readers, the following are the official rules for all contests on
							Online Quiz.
						</p>
						<div className="tw-mt-6">
							{Arr.map(item => (
								<p className="md:tw-text-base tw-text-sm tw-text-[#1b1b1b] tw-font-medium">
									<span className="tw-text-main tw-uppercase md:tw-text-base tw-text-sm tw-font-medium">
										{item.title}
									</span>
									: {item.desc}
								</p>
							))}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Instruction;
