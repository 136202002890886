import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/buttons/button";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import { returnErrors } from "../data/Reducers/ErrorReducer";

const ForgotPassword = () => {
	const [show, setShow] = useState(false),
		[code, setCode] = useState(""),
		[loading, setLoading] = useState(""),
		navigate = useNavigate(),
		dispatch = useDispatch(),
		[state, setState] = useState({
			password: "",
			confirmPassword: "",
		}),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		};
	let handleSubmit = async e => {
		e.preventDefault();
		console.log({ state, code });
		if (!code) return;
		if (!state.password) return;
		if (state.password !== state.confirmPassword)
			return toast.error("Password do not match");

		setLoading(true);
		try {
			let body = {
				token: code,
				newPassword: state.password,
			};
			let res = await axios.post(`/api/v1/auth/reset-password`, body);
			toast.success(res.data.message);
			navigate("/login");
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
		setLoading(false);
	};

	return (
		<div>
			<div>
				<div className="tw-h-screen tw-w-full lg:tw-grid lg:tw-grid-cols-2">
					<div className="login-main tw-hidden lg:tw-block tw-h-full tw-w-full"></div>
					<div className="tw-h-full category-bg tw-w-full tw-flex tw-justify-center tw-items-center">
						<form action="" onSubmit={handleSubmit}>
							<h2 className="tw-text-3xl tw-font-bold tw-text-[#002224] satoshi">
								Forgot Password
							</h2>
							<div className="tw-space-y-4 tw-mt-8">
								<div className="">
									<small className="tw-text-base nexa tw-text-plain">
										Verify Code
									</small>
									<div>
										{" "}
										<input
											type="tel"
											name="code"
											onChange={e => setCode(e?.target?.value)}
											style={{
												border: "0.6px solid rgba(0, 85, 90, 0.50)",
											}}
											className="tw-h-12 tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-96 tw-rounded-xl"
										/>
									</div>
								</div>
								<div className="">
									<small className="tw-text-base nexa tw-text-plain">
										New Password
									</small>
									<div
										style={{
											border: "0.6px solid rgba(0, 85, 90, 0.50)",
										}}
										className="tw-w-96 tw-h-12 tw-relative tw-text-plain nexa tw-font-normal satoshi tw-rounded-xl">
										{" "}
										<input
											onChange={textChange}
											type={show ? "text" : "password"}
											name="password"
											className="tw-h-full tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-full tw-rounded-xl"
										/>
										<div
											onClick={() => setShow(!show)}
											className="tw-absolute tw-right-2 tw-cursor-pointer tw-top-4">
											{show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
										</div>
									</div>
								</div>
								<div className="">
									<small className="tw-text-base nexa tw-text-plain">
										Confirm Password
									</small>
									<div
										style={{
											border: "0.6px solid rgba(0, 85, 90, 0.50)",
										}}
										className="tw-w-96 tw-h-12 tw-relative tw-text-plain nexa tw-font-normal satoshi tw-rounded-xl">
										{" "}
										<input
											onChange={textChange}
											type={show ? "text" : "password"}
											name="confirmPassword"
											className="tw-h-full tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-full tw-rounded-xl"
										/>
										<div
											onClick={() => setShow(!show)}
											className="tw-absolute tw-right-2 tw-cursor-pointer tw-top-4">
											{show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
										</div>
									</div>
								</div>
								<div className="tw-flex tw-justify-center tw-mt-4">
									<Button
										onClick={handleSubmit}
										loading={loading}
										title={"Submit"}
										width={
											"tw-h-10 tw-bg-main tw-w-80 tw-rounded-xl tw-font-semibold tw-text-white satoshi tw-text-bbase"
										}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
