import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Explore } from "../Home";
import Footer from "../../components/Footer";
import Img from "../../assets/ABOUT US-02 1.png";
import Logo from "../../assets/logolight.svg";
import { useSelector, useDispatch } from "react-redux";
import DOMPurify from "dompurify";
import { manageCategory } from "../../data/Reducers/CategoryReducer";

export const createMarkup = html => {
	return {
		__html: DOMPurify.sanitize(html),
	};
};

const AboutUs = () => {
	return (
		<div>
			<Header />
			<Mainsection />
			<Explore />
			<Footer />
		</div>
	);
};

const Mainsection = () => {
	let [type, setType] = useState("");
	return (
		<section className="tw-w-full tw-bg-[#F4FDFF] tw-min-h-screen tw-py-10 tw-overflow-visible">
			<div className="container tw-grid lg:tw-grid-cols-2 tw-h-full tw-items-center tw-gap-10">
				<div className="tw-relative ">
					<h1 className="tw-text-main tw-font-extrabold nexa lg:tw-text-[89px] tw-text-4xl tw-absolute tw-top-4">
						About
					</h1>
					<img src={Img} alt="" className="tw-mt-10 lg:tw-mt-16" />
				</div>
				<div className="">
					<img src={Logo} alt="" className="lg:tw-h-32" />
					<div className="tw-space-y-4 tw-mt-6">
						<PurifiedText type={type} setType={setType} section={"about"} />
						{!type && (
							<>
								<p className="tw-text-base tw-text-plain tw-font-normal nexa">
									onsectetur adipiscing elit, sed do eiusmod tempor incididunt
									ut labore et dolore magna aliqua. Ut enim ad minim veniam,
									quis nostrud exercitation ullamco laboris nisi ut aliquip ex
									ea commodo consequat. Duis aute irure dolor in reprehenderit
									in voluptate velit esse cillum dolore eu fugiat nulla
									pariatur. Excepteur sint occaecat cupidatat non proident, sunt
									in culpa qui officia deserunt mollit anim id est laborum."
								</p>
								<p className="tw-text-base tw-text-plain tw-font-normal nexa">
									onsectetur adipiscing elit, sed do eiusmod tempor incididunt
									ut labore et dolore magna aliqua. Ut enim ad minim veniam,
									quis nostrud exercitation ullamco laboris nisi ut aliquip ex
									ea commodo consequat. Duis aute irure dolor in reprehenderit
									in voluptate velit esse cillum dolore eu fugiat nulla
									pariatur. Excepteur sint occaecat cupidatat non proident, sunt
									in culpa qui officia deserunt mollit anim id est laborum."
								</p>
							</>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;

export const PurifiedText = ({ type, setType, section }) => {
	let { category } = useSelector(s => s),
		dispatch = useDispatch();

	useEffect(() => {
		dispatch(manageCategory("get", "settings"));
	}, [dispatch]);

	useEffect(() => {
		if (category?.settings) {
			let data = category?.settings?.docs?.[0];
			if (data?.[section]) {
				setType(data?.[section]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category?.settings]);

	return (
		<>
			{type && (
				<>
					<p
						className="tw-text-base tw-text-plain tw-font-normal nexa"
						dangerouslySetInnerHTML={createMarkup(type)}
					/>
				</>
			)}
		</>
	);
};
