// import Confetti from "react-confetti";
// import Avatar1 from "../../../assets/avatar1.svg";
// import Avatar2 from "../../../assets/avatar2.svg";
// import Avatar3 from "../../../assets/avatar3.svg";
import { useEffect, useState } from "react";

// const TableSection = ({ data, user }) => {
// 	if (!data) return;
// 	const Arr = [
// 		{
// 			id: 4,
// 			avatar: Avatar1,
// 			username: "John Doe",
// 			phone: "09036228765",
// 			email: "user@example.com",
// 			coins: "500",
// 			isauth: false,
// 			points: "2000",
// 		},
// 		{
// 			id: 5,
// 			avatar: Avatar2,
// 			username: "John Doe",
// 			phone: "09036228765",
// 			email: "user@example.com",
// 			coins: "500",
// 			isauth: false,
// 			points: "2000",
// 		},
// 		{
// 			id: 6,
// 			avatar: Avatar1,
// 			username: "John Doe",
// 			phone: "09036228765",
// 			email: "user@example.com",
// 			coins: "500",
// 			isauth: true,
// 			points: "2000",
// 		},
// 		{
// 			id: 7,
// 			avatar: Avatar2,
// 			username: "John Doe",
// 			phone: "09036228765",
// 			email: "user@example.com",
// 			coins: "500",
// 			isauth: false,
// 			points: "2000",
// 		},
// 		{
// 			id: 8,
// 			avatar: Avatar3,
// 			username: "John Doe",
// 			phone: "09036228765",
// 			email: "user@example.com",
// 			coins: "500",
// 			isauth: false,
// 			points: "2000",
// 		},
// 	];
// 	return (
// 		<div className="">
// 			<div className="lg:tw-w-11/12 tw-mx-auto -tw-mb-8 tableBg lg:tw-px-24 tw-pt-6 tw-rounded-t-xl tw-h-72 tw-mt-8">
// 				<div
// 					// style={{
// 					//   background: `${Tablebg}`,
// 					//   backgroundPosition: "center",
// 					//   backgroundSize: "cover",
// 					//   backgroundRepeat: "no-repeat",
// 					// }}
// 					className="tw-w-full leadbgmain tw-relative tw-h-full">
// 					{/* <Confetti /> */}
// 					<div className="tw-flex tw-justify-between">
// 						<div className="tw-absolute tw-left-0 tw-bottom-8">
// 							<img
// 								src={data?.[1]?.user?.[0]?.avatar?.url || Avatar1}
// 								alt=""
// 								className=" tw-mx-auto"
// 							/>
// 							<button className="tw-bg-[#F3F7FA] tw-mt-4 tw-h-6 tw-w-20 tw-rounded-xl tw-text-xs segoe tw-font-semibold tw-text-[#082038cc]">
// 								{data?.[1]?.totalPoints}
// 							</button>
// 							<h6 className="tw-text-xs tw-text-white tw-text-center segoe tw-font-semibold">
// 								{data?.[1]?.user?.[0]?.lastName}
// 							</h6>
// 							<div
// 								style={{
// 									background:
// 										"linear-gradient(90deg, #C9D6FF 0%, #E2E2E2 100%)",
// 								}}
// 								className="tw-h-16 tw-flex tw-justify-center tw-items-center segoe tw-w-20">
// 								<span className="tw-text-4xl tw-font-bold tw-text-[#2F80ED]">
// 									2
// 								</span>
// 							</div>
// 						</div>
// 						<div className="tw-mx-auto">
// 							<img
// 								src={data?.[0]?.user?.[0]?.avatar?.url || Avatar1}
// 								alt=""
// 								className=" tw-mx-auto"
// 							/>
// 							<button className="tw-bg-[#F3F7FA] tw-mt-4 tw-h-6 tw-w-20 tw-rounded-xl tw-text-xs segoe tw-font-semibold tw-text-[#082038cc]">
// 								{data?.[0]?.totalPoints}
// 							</button>
// 							<h6 className="tw-text-xs tw-text-white tw-text-center segoe tw-font-semibold">
// 								{data?.[0]?.user?.[0]?.lastName}
// 							</h6>
// 							<div
// 								style={{
// 									background:
// 										"linear-gradient(90deg, #C9D6FF 0%, #E2E2E2 100%)",
// 								}}
// 								className="tw-h-24 tw-flex tw-justify-center tw-items-center segoe tw-w-20">
// 								<span className="tw-text-4xl tw-font-bold tw-text-[#2F80ED]">
// 									1
// 								</span>
// 							</div>
// 						</div>
// 						<div className="tw-absolute tw-right-0 tw-bottom-8">
// 							<img
// 								src={data?.[2]?.user?.[0]?.avatar?.url || Avatar1}
// 								alt=""
// 								className=" tw-mx-auto"
// 							/>
// 							<button className="tw-bg-[#F3F7FA] tw-mt-4 tw-h-6 tw-w-20 tw-rounded-xl tw-text-xs segoe tw-font-semibold tw-text-[#082038cc]">
// 								{data?.[2]?.totalPoints}
// 							</button>
// 							<h6 className="tw-text-xs tw-text-white tw-text-center segoe tw-font-semibold">
// 								{data?.[2]?.user?.[0]?.lastName}
// 							</h6>
// 							<div
// 								style={{
// 									background:
// 										"linear-gradient(90deg, #C9D6FF 0%, #E2E2E2 100%)",
// 								}}
// 								className="tw-h-12 tw-flex tw-justify-center tw-items-center segoe tw-w-20">
// 								<span className="tw-text-4xl tw-font-bold tw-text-[#2F80ED]">
// 									3
// 								</span>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 			<div className="tw-overflow-x-auto">
// 				<table className="tw-min-w-full noScroll tw-table-auto tw-mx-auto tw-bg-white tw-p-6 tw-h-96 lg:tw-rounded-t-2xl">
// 					<tbody className="">
// 						{data?.map((item, i) => (
// 							<tr
// 								key={i}
// 								className={`${
// 									user?._id === item?.user?.[0]?._id
// 										? "tw-bg-[#F2FBFF]"
// 										: "tw-font-semibold tw-h-20"
// 								}`}>
// 								<td className="tw-w-4 tw-p-4 md:tw-text-base tw-text-xs tw-text-[#1b1b1b] tw-text-opacity-75 tw-font-semibold">
// 									{item.id}
// 								</td>
// 								<td className="">
// 									<img
// 										src={
// 											item?.user?.[0]?.avatar?.url ||
// 											Arr?.[i % Arr?.length]?.avatar
// 										}
// 										alt={
// 											item?.user?.[0]?.lastName ||
// 											Arr?.[i % Arr?.length]?.username
// 										}
// 										className="tw-h-14"
// 									/>
// 								</td>
// 								<td
// 									className={`segoe md:tw-text-base tw-text-xs ${
// 										user?._id === item?.user?.[0]?._id && "tw-font-bold"
// 									} tw-text-[#1b1b1b] tw-font-semibold tw-text-opacity-80`}>
// 									{item?.user?.[0]?.lastName} {item?.user?.[0]?.firstName}{" "}
// 									{user?._id === item?.user?.[0]?._id && "(You)"}
// 								</td>
// 								<td className="segoe md:tw-text-base tw-text-xs tw-font-semibold tw-text-[#1b1b1b] tw-text-opacity-80">
// 									{item?.user?.[0]?.phone}
// 								</td>
// 								<td className="segoe md:tw-text-base tw-text-xs tw-font-semibold tw-text-[#1b1b1b] tw-text-opacity-80">
// 									{item?.user?.[0]?.email}
// 								</td>
// 								<td className="segoe md:tw-text-base tw-text-xs tw-font-semibold tw-text-[#1b1b1b] tw-text-opacity-80">
// 									{item?.totalPoints} coins
// 								</td>
// 								<td className="segoe md:tw-text-base tw-text-xs tw-font-semibold tableTxt">
// 									<RankManager score={i} />
// 								</td>
// 							</tr>
// 						))}
// 					</tbody>
// 				</table>
// 			</div>
// 		</div>
// 	);
// };

// export default TableSection;

export const RankManager = ({ score }) => {
  let [grader, setGrader] = useState("");

  let hostEndsWith = (host, ends) => {
    let value = false;
    value = ends?.some((element) => {
      return host?.toString()?.endsWith(element?.toString());
    });
    return value;
  };
  useEffect(() => {
    if (score) {
      let finder = score + 1,
        finder2 = finder?.toString();
      if (hostEndsWith(finder2, ["11", "12", "13"])) {
        setGrader("th");
      } else if (finder2?.endsWith("1")) setGrader("st");
      else if (finder2?.endsWith("2")) setGrader("nd");
      else if (finder2?.endsWith("3")) setGrader("rd");
      else setGrader("th");
    } else if (score === 0) setGrader("st");
  }, [score]);

  return (
    <>
      {score + 1}
      {grader}
    </>
  );
};
