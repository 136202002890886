import React, { useState, useEffect } from "react";
// import Logo, { Logo2 } from "../../components/Logo";
// import Bg2 from "../../assets/loginbg2.png";
// import { LiaEyeSolid, LiaEyeSlash } from "react-icons/lia";
// import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadUser, login, setUserFail } from "../../data/Reducers/UserReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/buttons/button";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
// import Img from "../../assets/loginmain.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = () => {
  // const [show, setShow] = useState(false);

  return (
    <div>
      <Header />
      <MainSection />
      <Footer />
    </div>
  );
};

const MainSection = () => {
  const [show, setShow] = useState(false),
    navigate = useNavigate(),
    init = {
      username: "",
      password: "",
    },
    [state, setState] = useState(init),
    textChange = (e) => {
      let { name, value } = e.target;
      setState({ ...state, [name]: value });
    },
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    { auth } = useSelector((state) => state),
    dispatch = useDispatch();

  useEffect(() => {
    if (auth?.isLoggedIn && submit) {
      navigate("/");
    }
  }, [auth, submit, navigate]);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (!state?.username || !state?.password) return;
    setLoading(true);
    try {
      let res = await axios.post(`/api/v1/auth/login`, { ...state });
      console.log({ resp: res?.data });
      toast.success(res?.data?.message);
      dispatch(login(res?.data?.data));
      dispatch(loadUser());
    } catch (err) {
      if (err?.response?.status === 429 || err?.response?.status === 405)
        toast.error(err?.response?.data ? err?.response?.data : err?.message);
      console.log({ err });
      let error = err.response?.data?.error;
      if (error) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      } else {
        toast.error(err?.response?.data?.message);
      }
      dispatch(setUserFail());
    }
    setLoading(false);
    setSubmit(true);
  };
  return (
    <div>
      <div className="tw-h-screen tw-w-full lg:tw-grid lg:tw-grid-cols-2">
        <div className="login-main tw-hidden lg:tw-block tw-h-full tw-w-full"></div>
        <div className="tw-h-full category-bg tw-w-full tw-flex tw-justify-center tw-items-center">
          <form action="" onSubmit={handleSubmit}>
            <h2 className="tw-text-3xl tw-font-bold tw-text-[#002224] satoshi">
              Login
            </h2>
            {/* <small className="tw-text-base tw-font-normal satoshi tw-text-[#002224]">
							nobis est eligendi optio cumque nihil impedit quo{" "}
						</small> */}
            <div className="tw-space-y-4 tw-mt-8">
              <div className="">
                <small className="tw-text-base nexa tw-text-plain">
                  Phone number
                </small>
                <div>
                  {" "}
                  <input
                    type="tel"
                    name="username"
                    onChange={textChange}
                    style={{
                      border: "0.6px solid rgba(0, 85, 90, 0.50)",
                    }}
                    className="tw-h-12 tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-96 tw-rounded-xl"
                  />
                </div>
              </div>
              <div className="">
                <small className="tw-text-base nexa tw-text-plain">
                  Password
                </small>
                <div
                  style={{
                    border: "0.6px solid rgba(0, 85, 90, 0.50)",
                  }}
                  className="tw-w-96 tw-h-12 tw-relative tw-text-plain nexa tw-font-normal satoshi tw-rounded-xl"
                >
                  {" "}
                  <input
                    onChange={textChange}
                    type={show ? "text" : "password"}
                    name="password"
                    className="tw-h-full tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-full tw-rounded-xl"
                  />
                  <div
                    onClick={() => setShow(!show)}
                    className="tw-absolute tw-right-2 tw-cursor-pointer tw-top-4"
                  >
                    {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                  </div>
                </div>
              </div>
            </div>
            <h6
              onClick={() => navigate("/forgot-password")}
              className="tw-font-normal tw-cursor-pointer tw-mt-4 mulish tw-text-base tw-text-[#1c1c1c] tw-text-center"
            >
              Forgot password?
            </h6>
            <div className="tw-flex tw-justify-center tw-mt-4">
              {/* <button
                className="tw-h-10 tw-bg-main tw-w-80 tw-rounded-xl tw-font-semibold tw-text-white satoshi tw-text-bbase"
                type="submit"
              >
                Login
              </button> */}
              <Button
                onClick={handleSubmit}
                loading={loading}
                title={"Login"}
                width={
                  "tw-h-10 tw-bg-main tw-w-80 tw-rounded-xl tw-font-semibold tw-text-white satoshi tw-text-bbase"
                }
                // style={{
                // 	boxShadow: "0px 4px 12px 0px rgba(55, 81, 255, 0.24)",
                // }}
              />
            </div>
            <p className="tw-text-center nexa tw-text-[#06094F] tw-pt-2">
              You don’t have an account yet?{" "}
              <span
                onClick={() => navigate("/register")}
                className="tw-font-bold tw-cursor-pointer"
              >
                Sign Up
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;
