import React, { useState } from "react";
import Header from "../../components/Header";
import { Explore } from "../Home";
import Footer from "../../components/Footer";
import Img from "../../assets/terms.png";
import { PurifiedText } from "../about-us";

const Terms = () => {
	return (
		<div>
			<Header />
			<Mainsection />
			<Explore />
			<Footer />
		</div>
	);
};

const Mainsection = () => {
	let [type, setType] = useState("");
	return (
		<section className="tw-w-full tw-bg-[#F4FDFF] tw-py-10 tw-min-h-screen tw-overflow-visible">
			<div className="container">
				<div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-justify-between">
					<img
						src={Img}
						alt=""
						className=" tw-rounded-lg tw-block lg:tw-hidden"
					/>
					<h1 className="tw-text-6xl tw-font-extrabold tw-text-main tw-pt-12 lg:tw-pt-0 nexa">
						Terms and <br /> Conditions
					</h1>
					<img
						src={Img}
						alt=""
						className=" tw-rounded-lg tw-hidden lg:tw-block"
					/>
				</div>
				<div className="tw-space-y-4 tw-mt-16">
					<PurifiedText type={type} setType={setType} section={"terms"} />

					{!type && (
						<>
							<p className="tw-text-base tw-text-plain tw-font-normal nexa">
								onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut
								labore et dolore magna aliqua. Ut enim ad minim veniam, quis
								nostrud exercitation ullamco laboris nisi ut aliquip ex ea
								commodo consequat. Duis aute irure dolor in reprehenderit in
								voluptate velit esse cillum dolore eu fugiat nulla pariatur.
								Excepteur sint occaecat cupidatat non proident, sunt in culpa
								qui officia deserunt mollit anim id est laborum."onsectetur
								adipiscing elit, sed do eiusmod tempor incididunt ut labore et
								dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								exercitation ullamco laboris nisi ut aliquip ex ea commodo
								consequat. Duis aute irure dolor in reprehenderit in voluptate
								velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
								sint occaecat cupidatat non proident, sunt in culpa qui officia
								deserunt mollit anim id est laborum."
							</p>
							<p className="tw-text-base tw-text-plain tw-font-normal nexa">
								onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut
								labore et dolore magna aliqua. Ut enim ad minim veniam, quis
								nostrud exercitation ullamco laboris nisi ut aliquip ex ea
								commodo consequat. Duis aute irure dolor in reprehenderit in
								voluptate velit esse cillum dolore eu fugiat nulla pariatur.
								Excepteur sint occaecat cupidatat non proident, sunt in culpa
								qui officia deserunt mollit anim id est laborum."onsectetur
								adipiscing elit, sed do eiusmod tempor incididunt ut labore et
								dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								exercitation ullamco laboris nisi ut aliquip ex ea commodo
								consequat. Duis aute irure dolor in reprehenderit in voluptate
								velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
								sint occaecat cupidatat non proident, sunt in culpa qui officia
								deserunt mollit anim id est laborum."
							</p>{" "}
							<p className="tw-text-base tw-text-plain tw-font-normal nexa">
								onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut
								labore et dolore magna aliqua. Ut enim ad minim veniam, quis
								nostrud exercitation ullamco laboris nisi ut aliquip ex ea
								commodo consequat. Duis aute irure dolor in reprehenderit in
								voluptate velit esse cillum dolore eu fugiat nulla pariatur.
								Excepteur sint occaecat cupidatat non proident, sunt in culpa
								qui officia deserunt mollit anim id est laborum."onsectetur
								adipiscing elit, sed do eiusmod tempor incididunt ut labore et
								dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								exercitation ullamco laboris nisi ut aliquip ex ea commodo
								consequat. Duis aute irure dolor in reprehenderit in voluptate
								velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
								sint occaecat cupidatat non proident, sunt in culpa qui officia
								deserunt mollit anim id est laborum."
							</p>
						</>
					)}
				</div>
			</div>
		</section>
	);
};

export default Terms;
