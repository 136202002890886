import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ScoreComp } from "./scoreboard";
import useFetch from "../data/useFetch";
import { LoadingLoader } from "../components/buttons/button";
import { ClipLoader } from "react-spinners";
import Play from "../assets/audio.svg";
import {
	LifeLineOption,
	MainCoinPoints,
	MainTimer,
} from "../components/Quiz/exam-quiz";
import { AudienceCount } from "../components/Quiz/main-quiz";
import { QuessComponent } from "../components/Quiz/guessTheWord";
import { ScoreCompDuet } from "./duet-score";

const NewQuiz = () => {
	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	let {
		examData,
		handleNext,
		isQuestion,
		setIsSelected,
		isSelected,
		examLoad,
		loading,
		handlePrev,
		result,
		duration,
		handleTimeUp,
		k,
		type,
		setOptions,
		options,
		newAnswer,
		setNewAnswer,
		handleNextQuess,
		quizId,
	} = useFetch();

	let audioRef = useRef();

	if (examLoad && !examData) return <LoadingLoader />;

	const currentQuestion = examData?.[isQuestion];

	return (
		<>
			<QuizMainComponent
				currentQuestion={currentQuestion}
				duration={duration}
				handleNext={
					currentQuestion?.type === "guessTheWord"
						? handleNextQuess
						: handleNext
				}
				handlePrev={handlePrev}
				handleTimeUp={handleTimeUp}
				isQuestion={isQuestion}
				examData={examData}
				k={k}
				audioRef={audioRef}
				isSelected={isSelected}
				setIsSelected={setIsSelected}
				newAnswer={newAnswer}
				setNewAnswer={setNewAnswer}
				options={options}
				loading={loading}
				type={type}
				setOptions={setOptions}
				result={result}
				quizId={quizId}
			/>
		</>
	);
};

export const QuizBtn = ({ text, onClick, loading, bg, css, newCss }) => {
	return (
		<div>
			<button
				onClick={onClick}
				disabled={loading}
				style={{
					background: bg || "linear-gradient(180deg, #2A668B 0%, #3889BA 100%)",
					boxShadow:
						css ||
						"0px 28.341px 56.682px 0px rgba(10, 24, 33, 0.24), 0px 8.817px 8.817px 0px #3A8EC1 inset, 0px -8.817px 8.817px 0px #286285 inset",
				}}
				className={`tw-rounded-3xl tw-h-12 lg:tw-w-40 tw-w-28 tw-text-white tw-font-bold nexa tw-text-sm lg:tw-text-base ${
					newCss || ""
				}`}>
				{text}
				{loading && <ClipLoader color={"white"} size={16} />}
			</button>
		</div>
	);
};

export default NewQuiz;

export const OptionsList = ({
	options,
	list,
	setIsSelected,
	isSelected,
	isQuestion,
	correctIndex,
	audioRef,
	audioData,
	disabled,
}) => {
	let [optionList, setOptionList] = useState(null);

	useEffect(() => {
		let rand;
		do {
			rand = Math.abs(Math.floor(Math.random() * list?.length - 1));
		} while (rand === correctIndex - 1);
		// console.log({ rand, list, correctIndex });
		if (options?.fiftyFifty && options?.fiftyFiftyNo === isQuestion) {
			let newList = list?.map((it, x) => {
				return { ...it, show: rand !== x && correctIndex - 1 !== x };
			});
			setOptionList(newList);
		} else setOptionList(list);
	}, [options, list, isQuestion, correctIndex]);

	return (
		<>
			<div className="tw-mt-8 tw-w-3/4 tw-mx-auto tw-grid tw-justify-center lg:tw-grid-cols-2 tw-gap-5 lg:tw-gap-12">
				{optionList
					?.filter(it => !it?.show)
					?.map((it, i) => (
						<div
							key={i}
							onClick={
								!disabled
									? () => {
											if (audioData) {
												let audio = audioRef.current;

												if (!audio.paused) {
													audio.pause();
												}
											}

											setIsSelected({
												answer: i + 1,
												option: it?.value || it,
											});
									  }
									: null
							}
							className="tw-w-full tw-cursor-pointer tw-min-h-16 tw-relative">
							<div
								style={{
									borderRadius: "70px 4px",
									background: "#00555A",
								}}
								className="tw-min-h-10 tw-z-10 -lg:tw-left-2 -tw-left-4 tw-absolute lg:tw-top-0 tw-w-16">
								<div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full">
									<strong className="lg:tw-text-xl tw-text-sm tw-text-white tw-font-bold tw-uppercase nexa">
										{i === 0
											? "A"
											: i === 1
											? "B"
											: i === 2
											? "C"
											: i === 3
											? "D"
											: ""}
									</strong>
								</div>
							</div>
							<div
								style={{
									background:
										isSelected?.option === (it?.value || it) &&
										isSelected?.answer - 1 === i
											? "#002224"
											: "#ffffff",
								}}
								className="tw-min-h-12 lg:tw-rounded-tl-[70px] tw-rounded-br-[40px] lg:tw-rounded-tr-[4px] lg:tw-top-4 tw-bg-white tw-w-full tw-h-full">
								<div className="tw-flex tw-justify-center tw-items-center tw-h-full lg:tw-w-full tw-w-60 lg:tw-px-8">
									<strong
										style={{
											color:
												isSelected?.option === (it?.value || it) &&
												isSelected?.answer - 1 === i
													? "#ffffff"
													: "#002224",
										}}
										className={`tw-text-sm tw-text-plain tw-capitalize nexa tw-font-semibold ${
											options?.audiencePoll &&
											options?.audiencePollNo === isQuestion
												? "tw-w-full tw-flex tw-items-center"
												: ""
										}`}>
										{it?.value || it}
										{options?.audiencePoll &&
											options?.audiencePollNo === isQuestion && (
												<AudienceCount option={it} options={list} />
											)}
									</strong>
								</div>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

export const QuizMainComponent = ({
	currentQuestion,
	duration,
	handleNext,
	handlePrev,
	handleTimeUp,
	isQuestion = 0,
	examData,
	k,
	audioRef,
	isSelected,
	setIsSelected,
	newAnswer,
	setNewAnswer,
	options,
	loading,
	type,
	setOptions,
	result,
	realtime,
	disabled,
	quizId,
}) => {
	return (
		<>
			<div className="tw-min-h-screen tw-flex tw-justify-center tw-items-center lg:tw-py-12 tw-py-8 gamebg tw-w-full">
				{currentQuestion ? (
					<div className="tw-p-10 tw-rounded-xl category-bg container">
						<div className="tw-w-5/6 tw-mx-auto tw-p-8 tw-relative quiz-bg">
							<MainCoinPoints />
							{!disabled && (
								<MainTimer
									duration={duration || 10}
									handleNext={handleTimeUp}
									k={k}
								/>
							)}
							{!realtime && (
								<div className="tw-flex tw-justify-center">
									<QuizBtn
										text={`Question ${isQuestion + 1} / ${examData?.length}`}
									/>
								</div>
							)}
							<div className="tw-py-10">
								<p className="nexa tw-text-center tw-text-xl tw-text-white tw-font-semibold">
									{currentQuestion?.question}
								</p>
								{currentQuestion?.audioUrl ||
								currentQuestion?.audioFile?.url ? (
									<div className="tw-h-32 tw-flex tw-justify-center tw-items-center tw-relative tw-w-5/6 tw-mx-auto tw-border tw-border-[#0966AB]">
										<div
											onClick={() => {
												const audio = audioRef.current;

												if (audio.paused) {
													audio.play().catch(error => {
														// Handle play error, if necessary
														console.error("Error playing audio:", error);
													});
												}
											}}>
											<img src={Play} alt="" className=" tw-mx-auto" />
											<h6 className="tw-text-base tw-pt-3 krub tw-font-medium tw-text-[#fff] tw-text-opacity-90">
												Tap to Play Audio
											</h6>
											<audio
												controls
												ref={audioRef}
												className="notificationSound tw-hidden">
												<source
													src={
														currentQuestion?.audioUrl ||
														currentQuestion?.audioFile?.url
													}
													type="audio/mp3"
												/>
											</audio>
										</div>
									</div>
								) : null}
								{currentQuestion?.image?.url && (
									<img
										src={currentQuestion?.image?.url}
										alt="Question"
										className="tw-mx-auto tw-h-52"
									/>
								)}
								{/* <div className="tw-mt-8 tw-w-3/4 tw-mx-auto tw-grid tw-justify-center lg:tw-grid-cols-2 tw-gap-5 lg:tw-gap-12">
								{currentQuestion?.options?.map((it, i) => (
									<div
										key={i}
										onClick={() => {
											if (
												currentQuestion?.audioUrl ||
												currentQuestion?.audioFile?.url
											) {
												let audio = audioRef.current;

												if (!audio.paused) {
													audio.pause();
												}
											}

											setIsSelected({
												answer: i + 1,
												option: it?.value || it,
											});
										}}
										className="tw-w-full tw-cursor-pointer tw-min-h-16 tw-relative">
										<div
											style={{
												borderRadius: "70px 4px",
												background: "#00555A",
											}}
											className="tw-min-h-12 tw-z-10 -lg:tw-left-2 -tw-left-4 tw-absolute lg:tw-top-0 -tw-top-3 tw-w-16">
											<div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full">
												<strong className="tw-text-2xl tw-text-white tw-font-bold tw-uppercase nexa">
													{i === 0
														? "A"
														: i === 1
														? "B"
														: i === 2
														? "C"
														: i === 3
														? "D"
														: ""}
												</strong>
											</div>
										</div>
										<div
											style={{
												background:
													isSelected?.option === (it?.value || it) &&
													isSelected?.answer - 1 === i
														? "#002224"
														: "#ffffff",
											}}
											className="tw-min-h-12 lg:tw-rounded-tl-[70px] tw-rounded-br-[40px] lg:tw-rounded-tr-[4px] lg:tw-top-4 tw-bg-white tw-w-full tw-h-full">
											<div className="tw-flex tw-justify-center tw-items-center tw-h-full lg:tw-w-full tw-w-60 lg:tw-p-2">
												<strong
													style={{
														color:
															isSelected?.option === (it?.value || it) &&
															isSelected?.answer - 1 === i
																? "#ffffff"
																: "#002224",
													}}
													className="tw-text-2xl tw-text-plain tw-capitalize nexa tw-font-bold">
													{it?.value || it}
												</strong>
											</div>
										</div>
									</div>
								))}
							</div> */}
								{currentQuestion?.type === "guessTheWord" ? (
									<QuessComponent
										newAnswer={newAnswer}
										setNewAnswer={setNewAnswer}
										currentQuestion={currentQuestion}
										isQuestion={0}
									/>
								) : (
									<OptionsList
										list={currentQuestion?.options}
										isQuestion={isQuestion}
										isSelected={isSelected}
										setIsSelected={setIsSelected}
										options={options}
										correctIndex={currentQuestion?.correctOptionIndex}
										disabled={disabled}
									/>
								)}
							</div>
						</div>
						<div className="tw-flex  tw-w-5/6 tw-mx-auto tw-mt-8 tw-justify-between tw-items-center">
							<div className="tw-flex tw-gap-5">
								{!realtime && (
									<>
										<div className="tw-hidden lg:tw-block">
											<QuizBtn
												text={
													String(isQuestion + 1) +
													" / " +
													String(examData?.length)
												}
											/>
										</div>
										<QuizBtn
											onClick={handlePrev}
											text={"Previous"}
											newCss={"tw-hidden"}
										/>
									</>
								)}
							</div>
							<div className="lg:tw-mt-5 tw-mt-0">
								{realtime ? (
									currentQuestion?.type === "guessTheWord" ? (
										<QuizBtn
											loading={loading}
											onClick={handleNext}
											text={
												realtime
													? "Submit"
													: isQuestion === examData?.length - 1
													? "Submit"
													: "Next"
											}
										/>
									) : null
								) : (
									<QuizBtn
										loading={loading}
										onClick={handleNext}
										text={
											realtime
												? "Submit"
												: isQuestion === examData?.length - 1
												? "Submit"
												: "Next"
										}
									/>
								)}
							</div>
						</div>
						{!realtime &&
							isQuestion < examData?.length &&
							["quizZone", "dailyQuiz"]?.includes(type) && (
								<LifeLineOption setOptions={setOptions} />
							)}
					</div>
				) : realtime ? (
					<ScoreCompDuet
						info={result}
						examData={examData}
						realtime={realtime}
						quizId={quizId}
					/>
				) : (
					<ScoreComp
						info={result}
						examData={examData}
						realtime={realtime}
						quizId={quizId}
					/>
				)}
			</div>
		</>
	);
};
