import React from "react";
import Header from "../../components/Header";
import FaqSection from "../../components/faq";
import { Explore } from "../Home";
import Footer from "../../components/Footer";

const Faq = () => {
  return (
    <div>
      <Header />
      <FaqSection />
      <Explore />
      <Footer />
    </div>
  );
};

export default Faq;
