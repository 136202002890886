import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const QuizReview = () => {
	return (
		<section className="">
			<Header />
			<MainSection />
			<Footer />
		</section>
	);
};
const MainSection = () => {
	let { state } = useLocation(),
		{ auth } = useSelector(s => s),
		navigate = useNavigate(),
		data = state?.examData;

	useEffect(() => {
		if (!state || !auth?.isAuth) navigate(-1);
	}, [state, auth?.isAuth, navigate]);

	// console.log({ state }, "reviews");

	return (
		<section className="tw-w-full tw-py-16 tw-bg-[#f5f5f5]">
			<div className="container tw-bg-[#fafafa] tw-p-12">
				<div className="tw-space-y-6">
					{data?.map((item, i) => (
						<div key={i} className="">
							<h2 className="tw-text-xl segoe tw-text-[#162023] tw-font-normal">
								Question {i + 1}
							</h2>
							{item?.image?.url && (
								<img
									src={item?.image?.url}
									alt="Question"
									className="tw-mx-auto tw-h-52"
								/>
							)}
							<h2 className="tw-text-lg segoe tw-font-semibold tw-text-[#162023]">
								{item?.question} ?
							</h2>
							<div className="tw-space-y-3">
								{item?.options?.map((option, i) => (
									<AnswerChecker
										option={option}
										info={state?.info}
										question={item}
										index={i + 1}
									/>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="tw-flex tw-justify-center tw-mt-8">
				<button
					onClick={() => navigate("/")}
					style={{
						background:
							" linear-gradient(90deg, #0966AB 0%, #2F80ED 50%, #2D9EE0 100%)",
					}}
					className="tw-border tw-border-[#0966AB] tw-h-9 tw-w-28 tw-rounded-xl poppins tw-text-xs tw-font-semibold tw-text-white tw-uppercase">
					Go home
				</button>
			</div>
		</section>
	);
};
export default QuizReview;

const AnswerChecker = ({ option, info, question, index }) => {
	let [isCorrect, setIsCorrect] = useState(null),
		[selectOption, setSelectOption] = useState(null);

	useEffect(() => {
		if (info && question && index) {
			let finder = info?.find(it => it?.questionID === question?._id);
			if (finder) {
				if (finder?.correct) setIsCorrect(true);
				setSelectOption(finder?.option);
			}
		}
	}, [info, question, index]);

	// console.log({ isCorrect, selectOption, index });

	return (
		<div
			className={`tw-w-full tw-py-4 tw-flex tw-items-center tw-rounded-2xl tw-px-6`}
			style={{
				background:
					isCorrect && selectOption === index
						? "#14AE5C"
						: question?.correctOptionIndex === index
						? "#14AE5C"
						: selectOption === index
						? "#dc3545"
						: "#f1f1f1",
			}}>
			<h6
				className="tw-text-medium poppins tw-text-sm"
				style={{
					color:
						isCorrect && selectOption === index
							? "#fff"
							: question?.correctOptionIndex === index
							? "#fff"
							: selectOption === index
							? "#fff"
							: "#162023",
				}}>
				{option?.value || option}
			</h6>
		</div>
	);
};
