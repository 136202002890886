import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Arrow1 from "../assets/arrow-down1.svg";
import Arrow2 from "../assets/arrow-down2.svg";
import Arrow3 from "../assets/arrow-down3.svg";
import Lock from "../assets/lock2.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BiCheckCircle } from "react-icons/bi";
import { manageCategory } from "../data/Reducers/CategoryReducer";

const Levels = () => {
	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	let { state } = useLocation(),
		navigate = useNavigate(),
		{ auth, category } = useSelector(s => s),
		dispatch = useDispatch(),
		[level, setLevel] = useState(null),
		[levelPick, setLevelPick] = useState(null),
		Arr = [
			{
				level: "1",
				bg: "linear-gradient(180deg, #00474C 0%, #005F65 100%)",
				arrow: Arrow1,
				shadow:
					"0px 28.341px 56.682px 0px rgba(0, 17, 18, 0.24), 0px 8.817px 8.817px 0px #006368 inset, 0px -8.817px 8.817px 0px #004448 inset",
				show: true,
			},
			{
				level: "2",
				bg: "linear-gradient(180deg, #2A668B 0%, #3889BA 100%)",
				arrow: levelPick?.level >= 2 ? Arrow2 : Lock,
				shadow:
					"0px 28.341px 56.682px 0px rgba(10, 24, 33, 0.24), 0px 8.817px 8.817px 0px #3A8EC1 inset, 0px -8.817px 8.817px 0px #286285 inset",
				show: levelPick?.level >= 2,
			},
			{
				level: "3",
				bg: "linear-gradient(180deg, #B6152B 0%, #F51C3A 100%)",
				arrow: levelPick?.level >= 3 ? Arrow3 : Lock,
				shadow:
					"0px 28.341px 56.682px 0px rgba(44, 5, 10, 0.25), 0px 9.006px 9.006px 0px #FE1D3D inset, 0px -9.006px 9.006px 0px #AD1429 inset",
				show: levelPick?.level >= 3,
			},
		],
		[mainArr, setMainArr] = useState(Arr);

	useEffect(() => {
		if (!state || !auth?.isAuth) navigate(-1);
	}, [state, navigate, auth?.isAuth]);

	useEffect(() => {
		if (state?.type) {
			let findLevel = category?.level?.docs?.find(it => {
				let dd = it?.name === state?.type;
				if (state?.type && state?.category && state?.subCategory)
					dd =
						it?.name === state?.type &&
						it?.category === state?.category &&
						it?.subCategory === state?.subCategory;
				else if (state?.type && state?.category)
					dd = it?.name === state?.type && it?.category === state?.category;

				return dd;
			});
			setLevelPick(findLevel);
		}
	}, [state, auth?.user, category?.level]);

	useEffect(() => {
		if (levelPick) {
			let newNum = 3,
				newAr = [];
			if (levelPick?.level > 2) {
				newNum = 3 + levelPick?.level;
			}

			for (let x = 1; x <= newNum; x++) {
				let newObj = {
					level: x,
					bg: Arr?.[(x - 1) % Arr?.length]?.bg,
					arrow:
						x === 1
							? Arrow1
							: levelPick?.level >= x
							? Arr?.[(x - 1) % Arr?.length]?.arrow
							: Lock,
					shadow: Arr?.[(x - 1) % Arr?.length]?.shadow,
					show: x === 1 ? true : levelPick?.level >= x,
				};
				newAr?.push(newObj);
			}
			setMainArr(newAr);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [levelPick]);

	useEffect(() => {
		dispatch(manageCategory("get", "level"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="tw-min-h-screen tw-flex tw-justify-center tw-items-center lg:tw-py-12 tw-py-6 tw-px-8 lg:tw-px-0 gamebg tw-w-full">
			<div className="tw-p-10 tw-rounded-xl category-bg container">
				<div className="tw-grid lg:tw-grid-cols-3 tw-gap-10 tw-w-3/4 tw-mx-auto">
					{mainArr?.map((it, i) => (
						<div
							key={i}
							style={{
								background: it?.bg,
								boxShadow: it?.shadow,
							}}
							onClick={() => {
								setLevel(it?.show ? Number(it?.level) : null);
								if (it?.show) {
									navigate(
										// state?.type === "guessTheWord"
										// 	? "/guess-the-word"
										// 	:
										// : state?.type === "contest"
										// ? "/gk-quiz"
										"/new-quiz",
										{
											state: { ...state, level: Number(it?.level) },
										}
									);
								}
							}}
							className="tw-h-16 tw-cursor-pointer tw-rounded-3xl tw-flex tw-justify-between tw-items-center tw-px-4 tw-relative">
							<div>
								<small className="tw-text-3xl tw-font-bold nexa tw-text-white">
									Level {it?.level}
								</small>
							</div>
							<div
								className={`tw-flex tw-justify-center tw-items-center tw-size-10 tw-rounded-full tw-bg-white ${
									it?.show ? "" : "tw-p-2"
								}`}>
								<img src={it?.arrow} alt="" className="" />
							</div>
							{level && level?.toString() === it?.level && (
								<div className="tw-absolute tw-bottom-1 tw-right-[45%]">
									<BiCheckCircle className="tw-text-[#fff]" size={30} />
								</div>
							)}
						</div>
					))}
				</div>
				{level && (
					<>
						<div className="tw-mt-4 tw-justify-center tw-hidden">
							<button
								onClick={() =>
									navigate(
										state?.type === "guessTheWord"
											? "/guess-the-word"
											: // : state?.type === "contest"
											  // ? "/gk-quiz"
											  "/new-quiz",
										{
											state: { ...state, level },
										}
									)
								}
								className="tw-h-9 tw-bg-[#00849F] tw-animate-pulse hover:tw-animate-none lg:tw-w-28 tw-w-20 tw-capitalize tw-rounded poppins tw-text-xs tw-font-semibold tw-text-white">
								Continue
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Levels;
