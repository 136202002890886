import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import { errorSlice } from "./ErrorReducer";
import { categorySlice } from "./CategoryReducer";
import { leaderboardSlice } from "./LeaderboardReducer";
import { socketSlice } from "./SocketReducer";

const rootReducer = combineReducers({
	auth: UserReducer,
	error: errorSlice.reducer,
	category: categorySlice.reducer,
	leaderboard: leaderboardSlice.reducer,
	mainSocket: socketSlice.reducer,
});

export default rootReducer;
