import React, { useState } from "react";
import { ModalContainer } from "../quiz-category-modal";
import ScienceImg from "../../../assets/science.svg";
import HistoryImg from "../../../assets/history.svg";
import GeographyImg from "../../../assets/geography.svg";
import MusicImg from "../../../assets/music.svg";
import SportImg from "../../../assets/sport.svg";
import TourismImg from "../../../assets/tourism.svg";
import { useNavigate } from "react-router-dom";
import { AiFillCloseSquare } from "react-icons/ai";

const ChallengeModal = ({ handleClose }) => {
  const navigate = useNavigate();
  const init = {
    difficulty: "",
    response_time: "",
    questions_amount: "",
  };
  const [values, setValues] = useState(init);
  const handleSelected = (value, selected) => {
    setValues({ ...values, [value]: selected });
    console.log(values);
  };
  const Arr = [
    {
      title: "Difficulty",
      name: "difficulty",
      levels: ["Easy", "Medium", "Hard"],
    },
    {
      title: "Response Time",
      name: "response_time",
      levels: ["60s", "40s", "20s"],
    },

    {
      title: "Number of Questions",
      name: "questions_amount",
      levels: ["10", "15", "20"],
    },
  ];
  const OptionArr = [
    {
      img: ScienceImg,
      bg: "linear-gradient(90deg, #FF512F 0%, #F09819 100%)",
      title: "sciences",
    },
    {
      img: HistoryImg,
      bg: "linear-gradient(90deg, #E55D87 0%, #5FC3E4 100%)",
      title: "History",
    },
    {
      img: GeographyImg,
      bg: "linear-gradient(90deg, #24C6DC 0%, #514A9D 100%)",
      title: "Geography",
    },
    {
      img: MusicImg,
      bg: "linear-gradient(90deg, #ED4264 0%, #FFEDBC 100%)",
      title: "Music",
    },
    {
      img: SportImg,
      bg: "linear-gradient(90deg, #8E9EAB 0%, #EEF2F3 100%)",
      title: "Sports",
    },
    {
      img: TourismImg,
      bg: "linear-gradient(90deg, #1D2B64 0%, #F8CDDA 100%)",
      title: "Tourism",
    },
  ];
  return (
    <ModalContainer>
      <div
        style={{
          overflowY: "scroll",
        }}
        className="lg:tw-w-1/3 noScroll tw-mx-auto tw-max-h-[550px] tw-rounded-xl tw-px-8 tw-py-5 tw-bg-[#f3f7fa]"
      >
        <div onClick={handleClose} className="tw-float-right tw-cursor-pointer">
          <AiFillCloseSquare size={20} />
        </div>
        <h2 className="tw-text-base tw-pt-8 tw-font-semibold tw-text-[#1b1b1b] tw-text-opacity-80 k2d">
          Select Category
        </h2>
        <div className="tw-grid lg:tw-grid-cols-3 tw-grid-cols-2 tw-w-11/12 tw-mx-auto tw-mt-4 tw-gap-8">
          {OptionArr.map((item, i) => (
            <div
              key={i}
              style={{
                background: item.bg,
              }}
              className="tw-h-32 tw-rounded-xl myShadow2 tw-cursor-pointer tw-relative"
            >
              <img src={item.img} alt="" className="tw-mx-auto" />
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.50)",
                }}
                className="tw-absolute tw-bottom-3 tw-flex tw-justify-center tw-items-center tw-h-10 tw-w-full"
              >
                <h6
                  style={{
                    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.20)",
                  }}
                  className="tw-text-sm k2d tw-font-bold tw-text-white tw-capitalize"
                >
                  {item.title}
                </h6>
              </div>
            </div>
          ))}
        </div>
        <div className="tw-mt-6">
          <h3 className="tw-text-base k2d tw-text-opacity-80 tw-text-[#1b1b1b] tw-font-semibold">
            Select Sub-Category
          </h3>
          <div className="tw-w-11/12 tw-mt-5 tw-mx-auto">
            <select
              name="sub-category"
              id=""
              className="tw-bg-[#f1f1f1e6] tw-rounded-xl tw-h-14 tw-px-4 tw-w-full tw-text-xs tw-font-normal tw-text-[#162023] segoe"
            >
              <option value="Applied Science">Applied Science</option>
            </select>
            <div className="tw-mt-4 tw-space-y-4">
              {Arr.map((item) => (
                <div key={item} className="">
                  <h6 className="tw-text-base segoe tw-font-normal tw-text-[#172A3A]">
                    {item.title}
                  </h6>
                  <div className=" tw-grid lg:tw-grid-cols-3 tw-gap-5">
                    {item.levels.map((level) => (
                      <div
                        onClick={() => handleSelected(item.name, level)}
                        key={level}
                        className={
                          values[item.name] === level
                            ? "tw-py-1 tw-bg-[#60B551] tw-cursor-pointer tw-rounded-lg"
                            : "tw-py-1 tw-bg-white tw-cursor-pointer tw-rounded-lg"
                        }
                      >
                        <h6
                          className={
                            values[item.name] === level
                              ? "tw-text-center k2d tw-pt-1 tw-font-semibold tw-text-sm tw-text-white"
                              : "tw-text-center k2d tw-pt-1 tw-font-semibold tw-text-sm tw-text-[#BEC5C6]"
                          }
                        >
                          {level}
                        </h6>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="tw-flex tw-justify-center tw-mt-6">
          <button
            onClick={() => navigate("/comprehension")}
            className="tw-h-12 tw-w-80 btngradient myShadow2 tw-rounded-xl tw-text-base k2d tw-text-white tw-font-semibold"
          >
            Start Challenge
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ChallengeModal;
