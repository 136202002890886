import React, { useState, useEffect } from "react";
import ScoreBoard from "../../Scoreboard";
// import ExamQuiz from "../exam-quiz";
// import TrueImg from "../../../assets/true1.png";
import useFetch from "../../../data/useFetch";
import Button, { LoadingLoader } from "../../buttons/button";

const WordGuesser = () => {
	let {
		examData,
		handleNextQuess,
		isQuestion,
		examLoad,
		loading,
		result,
		newAnswer,
		setNewAnswer,
	} = useFetch();

	if (examLoad && !examData) return <LoadingLoader />;

	// const QuestionsArr = [
	// 	{
	// 		question: "102 + 3 =",
	// 		options: ["6", "5", "105", "200"],
	// 		isCorrect: "105",
	// 	},
	// 	{
	// 		question: "102 + 3 =",
	// 		options: ["6", "5", "105", "200"],
	// 		isCorrect: "105",
	// 	},
	// 	{
	// 		question: "102 + 3 =",
	// 		options: ["6", "5", "105", "200"],
	// 		isCorrect: "105",
	// 	},
	// ];

	const currentQuestion = examData[isQuestion];

	return (
		<section className="tw-bg-[#F5F5F5] tw-py-20 tw-w-full">
			<div className="container tw-bg-[#FAFAFA] tw-p-8">
				<h1 className="tw-text-xl segoe tw-font-bold tw-text-[#1b1b1b]">
					{isQuestion < examData?.length ? "Questions" : ""}
				</h1>
				{currentQuestion ? (
					<div className="lg:tw-w-3/4 tw-mx-auto tw-mt-6 myShadow tw-py-8 tw-px-4 tw-rounded-lg tw-bg-[#fdfdfd]">
						<h2 className="tw-text-xl tw-font-semibold poppins tw-text-[#162023]">
							Question {isQuestion + 1}
						</h2>
						<QuessComponent
							isQuestion={isQuestion}
							currentQuestion={currentQuestion}
							newAnswer={newAnswer}
							setNewAnswer={setNewAnswer}
						/>
						<div className="tw-flex tw-mt-10 tw-justify-center tw-gap-8">
							<div>
								{/* <button
									onClick={handlePrev}
									className={`tw-h-14 tw-w-44 tw-bg-[#F0F7F9] myShadow tw-rounded-2xl tw-text-[#373535] poppins tw-font-semibold tw-text-base ${
										isQuestion === 0 ? "tw-hidden" : "tw-block"
									}`}>
									Previous
								</button> */}
							</div>
							{/* <button
								onClick={handleNextQuess}
								className={`tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB] 
           
                    `}>
								{isQuestion === examData?.length + 1 ? "Submit" : "Next"}
							</button> */}
							<Button
								onClick={handleNextQuess}
								loading={loading}
								title={isQuestion === examData?.length - 1 ? "Submit" : "Next"}
								width={
									"tw-h-14 tw-w-44 myShadow btngradient tw-text-white tw-rounded-2xl tw-text-base poppins tw-font-semibold tw-border tw-border-[#0966AB]"
								}
							/>
						</div>
					</div>
				) : (
					<ScoreBoard info={result} examData={examData} />
				)}
			</div>
		</section>
	);
};

export default WordGuesser;

export let WordArrayShuffler = ({
	answer,
	newAnswer,
	setNewAnswer,
	currentQuestion,
}) => {
	let [optionsArr, setOptionArr] = useState(null),
		handleSelect = (item, i) => {
			let newArr = optionsArr;

			newArr[i] = { item: item?.item, isSelected: true, index: i };
			setNewAnswer([...newAnswer, { ...item, index: i }]);
			setOptionArr(newArr);
		};

	useEffect(() => {
		if (answer) {
			setOptionArr(
				shuffleArray(
					answer?.split("")?.map((it, i) => {
						return { item: it, isSelected: false, index: i };
					})
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [answer, currentQuestion]);

	const handleDeleteRowForMutipleItem = (item, index) => {
		setNewAnswer(prevRows => {
			const newRows = [...prevRows];
			newRows.splice(index, 1);
			return newRows;
		});
		let newArr = optionsArr;

		newArr[item?.index] = { ...item, isSelected: false };
		setOptionArr(newArr);
	};

	return (
		<>
			<div className="tw-grid tw-grid-cols-5 tw-gap-4 tw-mt-6 tw-mx-auto">
				{optionsArr?.map((item, i) => (
					<div
						key={i}
						onClick={item?.isSelected ? null : () => handleSelect(item, i)}
						className={`tw-py-3 tw-rounded-xl myShadow ${
							item?.isSelected ? "tw-bg-[#FFE5FB]" : "tw-bg-white"
						} tw-cursor-pointer`}>
						<h5 className="tw-text-base tw-uppercase krub tw-font-semibold tw-text-center tw-text-[#1b1b1b]">
							{item?.item}
						</h5>
					</div>
				))}
			</div>
			<h3 className="tw-text-sm krub tw-font-semibold tw-text-[#fff] tw-text-center tw-mt-3">
				Click on the boxes above to rearrange the Word
			</h3>
			<div className="tw-grid tw-grid-cols-5 tw-gap-4 tw-mt-6 tw-mx-auto">
				{newAnswer?.map((item, i) => (
					<div
						key={i}
						onClick={() => handleDeleteRowForMutipleItem(item, i)}
						className={`tw-py-3 tw-rounded-xl myShadow tw-bg-[#E9FFE7] tw-cursor-pointer`}>
						<h5 className="tw-text-base tw-uppercase krub tw-font-semibold tw-text-center tw-text-[#1b1b1b]">
							{item?.item}
						</h5>
					</div>
				))}
			</div>
		</>
	);
};

export const shuffleArray = array => {
	let currentIndex = array?.length,
		randomIndex;

	// While there remain elements to shuffle.
	while (currentIndex !== 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		];
	}

	return array;
};

export const QuessComponent = ({
	currentQuestion,
	newAnswer,
	setNewAnswer,
	isQuestion,
}) => {
	return (
		<>
			<div className="lg:tw-w-96 tw-mx-auto">
				<WordArrayShuffler
					answer={currentQuestion?.answer}
					newAnswer={newAnswer}
					setNewAnswer={setNewAnswer}
					currentQuestion={currentQuestion}
					isQuestion={isQuestion}
				/>
			</div>
		</>
	);
};