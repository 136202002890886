/* eslint-disable react/prop-types */
import { createContext, useEffect } from "react";
import { BiCategoryAlt, BiBook } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
	const [nav, setNav] = useState(false);
	let handleCapitalize = word => {
		let splitter = word.trim().split(" ");
		let firstCap = splitter[0].split("");
		let remaining = splitter.slice(1, splitter.length).join(" ");

		let firstCapOne = firstCap[0].toUpperCase();
		let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

		let joinFirst = `${firstCapOne}${firstCapTwo}`;

		return `${joinFirst} ${remaining}`;
	};
	let [country, setCountry] = useState(null),
		[msisdn, setMsisdn] = useState(null);

	useEffect(() => {
		const fetchCountry = async () => {
			try {
				const response = await fetch("https://ipapi.co/json/");
				const data = await response.json();
				console.log({ data }, "fetch");
				setCountry(data.country_name);
			} catch (error) {
				console.error("Error fetching country:", error);
			}
		};

		fetchCountry();
	}, []);

	let numberWithCommas = x => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const toggleNav = () => {
		setNav(!nav);
	};

	const Quizdata = [
		{
			question:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
			options: ["answer1", "answer2", "answer3", "answer4"],
			answer: "answer1",
		},
		{
			question:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
			options: ["answer1", "answer2", "answer3", "answer4"],
			answer: "answer1",
		},
		{
			question:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
			options: ["answer1", "answer2", "answer3", "answer4"],
			answer: "answer1",
		},
	];
	const Quizinfo = [
		{
			title: "Score accuracy",
			value: "67%",
		},
		{
			title: "Total Question",
			value: "5",
		},
		{
			title: "Correct",
			value: "2",
		},
		{
			title: "Wrong",
			value: "1",
		},
	];

		let convertCamelCase = str => {
			const result = str.replace(/([A-Z])/g, " $1");
			const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

			return finalResult;
		};

		let { auth } = useSelector(state => state);

		useEffect(() => {
			const fetchNetwork = async () => {
				try {
					const response = await axios.post(
						"https://get2know.martadholdings.com/g2k/",
						{
							action: "GRW",
							msisdn: auth?.user?.phone || auth?.user?.createdBy?.phone,
						},
						{
							headers: {
								"Authorization": null,
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					);
					console.log({ data: response?.data }, "network");
					setMsisdn(response?.data);
				} catch (error) {
					console.error("Error fetching country:", error);
				}
			};
			if (auth?.user) fetchNetwork();
		}, [auth?.user]);

		let nairaSign = <span className="fontInherit">&#8358;</span>;
		let nairaSignNeutral = "₦";

		let sidebarList = [
			{
				name: "Dashboard",
				url: "/dashboard",
				icon: <BiCategoryAlt className="icon" size={24} />,
				permission: ["admin", "user", "organisation", "superadmin"],
			},
			{
				name: "Employee",
				url: "/",
				icon: <BiBook className="icon" size={24} />,
				links: [
					{ name: "Directory", url: "/directory" },
					{ name: "Profile", url: "/profile" },
					{ name: "Audit", url: "/audit" },
					{ name: "Onboarding", url: "/onboarding" },
					{ name: "Offbaording", url: "/offboarding" },
				],
				type: "button",
				permission: ["user", "organisation"],
			},
			{
				name: "Engagement",
				url: "/",
				icon: <BiBook className="icon" size={24} />,
				links: [
					{ name: "Announcements", url: "/announcements" },
					{ name: "Survey", url: "/survey" },
					{ name: "Feedback", url: "/audit" },
					{ name: "Onboarding", url: "/feedback" },
				],
				type: "button",
				permission: ["user", "organisation"],
			},
			{
				name: "Leave",
				url: "/leave",
				icon: <BiCategoryAlt className="icon" size={24} />,
				permission: ["admin", "user", "organisation", "superadmin"],
			},
			{
				name: "Finance",
				url: "/",
				icon: <BiBook className="icon" size={24} />,
				links: [
					{ name: "Payroll", url: "/payroll" },
					{ name: "Invoice", url: "/invoice" },
				],
				type: "button",
				permission: ["user", "organisation"],
			},
			{
				name: "Recruitment",
				url: "/",
				icon: <BiBook className="icon" size={24} />,
				links: [
					{ name: "Sub Menu", url: "/sub-menu" },
					{ name: "Sub Menu", url: "/sub-menu" },
					{ name: "Sub Menu", url: "/sub-menu" },
					{ name: "Sub Menu", url: "/sub-menu" },
				],
				type: "button",
				permission: ["user", "organisation"],
			},
			{
				name: "Request",
				url: "/",
				icon: <BiBook className="icon" size={24} />,
				links: [
					{ name: "Sub Menu", url: "/sub-menu" },
					{ name: "Sub Menu", url: "/sub-menu" },
					{ name: "Sub Menu", url: "/sub-menu" },
					{ name: "Sub Menu", url: "/sub-menu" },
				],
				type: "button",
				permission: ["user", "organisation"],
			},
			{
				name: "Performance",
				url: "/",
				icon: <BiBook className="icon" size={24} />,
				links: [
					{ name: "Sub Menu", url: "/sub-menu" },
					{ name: "Sub Menu", url: "/sub-menu" },
					{ name: "Sub Menu", url: "/sub-menu" },
					{ name: "Sub Menu", url: "/sub-menu" },
				],
				type: "button",
				permission: ["user", "organisation"],
			},
		];

		const state = {
			handleCapitalize,

			numberWithCommas,

			sidebarList,
			auth,
			nav,
			toggleNav,
			Quizdata,
			Quizinfo,
			nairaSign,
			nairaSignNeutral,
			country,
			msisdn,
			convertCamelCase,
		};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
