import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { QuizBtn } from "./new-quiz";
import { useSelector } from "react-redux";
import Avatar from "../assets/avatar.svg";
import { FaCoins } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const BuyCoin = () => {
	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	let { auth } = useSelector(s => s);

	const navigate = useNavigate();

	useEffect(() => {
		if (!auth?.isAuth) navigate(-1);
	}, [navigate, auth?.isAuth]);

	const Arr = [
		{
			amount: "50",
			coins: "30",
			link: "http://ng-app.com/MARTAD/GET2KNOWCoinPurchase50-0-Yes-23410220000028112-web?trxId=xxx",
		},
		{
			amount: "100",
			coins: "70",
			link: "http://ng-app.com/MARTAD/GET2KNOWCoinPurchase100-0-Yes-23410220000028113-web?trxId=xxx",
		},
		{
			amount: "200",
			coins: "175",
			link: "http://ng-app.com/MARTAD/GET2KNOWCoinPurchase200-0-Yes-23410220000028114-web?trxId=xxx",
		},
		{
			amount: "500",
			coins: "480",
			link: "http://ng-app.com/MARTAD/GET2KNOWCoinPurchase500-0-Yes-23410220000028115-web?trxId=xxx",
		},
		{
			amount: "1,000",
			coins: "1000",
		},
		{
			amount: "5,000",
			coins: "6000",
		},
		{
			amount: "10,000",
			coins: "20000",
		},
	];

	return (
		<div className="tw-w-full tw-min-h-screen tw-py-16 gamebg">
			<div className="tw-p-12 tw-rounded-xl category-bg container">
				<div className="lg:tw-w-5/6 tw-mx-auto">
					<div className="tw-flex tw-justify-between tw-items-center">
						<div className="tw-h-14 tw-min-w-40 tw-rounded-3xl tw-ring tw-ring-[#ccc] tw-flex tw-gap-4 tw-justify-between tw-items-center tw-px-4">
							<img src={Avatar} alt="" className="" />
							<div>
								<small className="tw-text-base tw-pt-1 tw-font-normal satoshi tw-text-[#06094F]">
									{auth?.user?.lastName} {auth?.user?.firstName}
								</small>
							</div>
						</div>
						<QuizBtn text={"Get in touch"} />
					</div>
					<div className="tw-mt-8 tw-w-3/4 tw-mx-auto tw-space-y-4">
						{Arr?.map((it, i) => (
							<div
								key={i}
								className="tw-w-full tw-bg-[#D9C880] lg:tw-h-24 tw-py-4 lg:tw-py-0 tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-justify-between tw-px-5 tw-rounded-xl">
								<div className="tw-flex tw-gap-3 tw-items-center">
									<FaCoins color="white" />
									<strong className="lg:tw-text-xl tw-text-sm tw-text-center lg:tw-text-left tw-font-bold nexa tw-text-plain">
										{it?.amount}NGN to get{" "}
										<span className="tw-font-extrabold">{it?.coins} Coins</span>
									</strong>
								</div>
								<div className="tw-mt-4 tw-flex lg:tw-block lg:tw-justify-normal tw-justify-center lg:tw-mt-0">
									<QuizBtn
										text={"Buy Now"}
										onClick={() =>
											it?.link ? window.open(it?.link, "_blank") : {}
										}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BuyCoin;
