import Header from "../../components/Header";
import ExamQuiz from "../../components/Quiz/exam-quiz";

const ExamPage = () => {
  return (
    <section>
      <Header />
      <ExamQuiz />
    </section>
  );
};

export default ExamPage;
