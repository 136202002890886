import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadUser, login, setUserFail } from "../../data/Reducers/UserReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/buttons/button";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Register = () => {
  return (
    <div>
      <Header />
      <MainSection />
      <Footer />
    </div>
  );
};

const MainSection = () => {
  const [show, setShow] = useState(false),
		navigate = useNavigate(),
		init = {
			password: "",
			type: "individual",
		},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		[terms, setTerms] = useState(false),
		{ auth } = useSelector(state => state),
		dispatch = useDispatch();

	useEffect(() => {
		if (auth?.isLoggedIn && submit) {
			navigate("/");
		}
	}, [auth, submit, navigate]);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (
			!state?.email ||
			!state?.password ||
			!state?.firstName ||
			!state?.lastName ||
			!state?.phone
		)
			return toast.info("Please fill out all fields");

		if (state?.password && state?.confirmPassword)
			if (state?.password !== state?.confirmPassword)
				return toast.error("Password do not match");

		setLoading(true);
		try {
			let newState = state;
			delete newState?.confirmPassword;

			let res = await axios.post(`/api/v1/auth/register`, { ...newState });
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			dispatch(login(res?.data?.data));
			dispatch(loadUser());
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
			dispatch(setUserFail());
		}
		setLoading(false);
		setSubmit(true);
	};

	return (
		<div className="tw-min-h-screen tw-w-full tw-grid lg:tw-grid-cols-2">
			<div className="register-main tw-hidden lg:tw-block tw-h-full tw-w-full"></div>
			<div className="tw-h-full category-bg tw-py-16 tw-w-full tw-flex tw-justify-center tw-items-center">
				<form action="" onSubmit={handleSubmit}>
					<h2 className="tw-text-3xl tw-font-bold tw-text-[#002224] satoshi">
						Sign up
					</h2>
					{/* <small className="tw-text-base tw-font-normal satoshi tw-text-[#002224]">
						nobis est eligendi optio cumque nihil impedit quo{" "}
					</small> */}
					<div className="tw-space-y-4 tw-mt-8">
						<div className="">
							<small className="tw-text-base nexa tw-text-plain">
								First name
							</small>
							<div>
								{" "}
								<input
									type="text"
									name="firstName"
									onChange={textChange}
									style={{
										border: "0.6px solid rgba(0, 85, 90, 0.50)",
									}}
									className="tw-h-12 tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-96 tw-rounded-xl"
								/>
							</div>
						</div>
						<div className="">
							<small className="tw-text-base nexa tw-text-plain">
								Last name
							</small>
							<div>
								{" "}
								<input
									type="text"
									name="lastName"
									onChange={textChange}
									style={{
										border: "0.6px solid rgba(0, 85, 90, 0.50)",
									}}
									className="tw-h-12 tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-96 tw-rounded-xl"
								/>
							</div>
						</div>
						<div className="">
							<small className="tw-text-base nexa tw-text-plain">
								Phone number
							</small>
							<div>
								{" "}
								<input
									type="text"
									name="phone"
									onChange={textChange}
									style={{
										border: "0.6px solid rgba(0, 85, 90, 0.50)",
									}}
									maxLength={11}
									className="tw-h-12 tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-96 tw-rounded-xl"
								/>
							</div>
						</div>
						<div className="">
							<small className="tw-text-base nexa tw-text-plain">
								Email Address
							</small>
							<div>
								{" "}
								<input
									type="text"
									name="email"
									onChange={textChange}
									style={{
										border: "0.6px solid rgba(0, 85, 90, 0.50)",
									}}
									className="tw-h-12 tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-96 tw-rounded-xl"
								/>
							</div>
						</div>
						<div className="">
							<small className="tw-text-base nexa tw-text-plain">
								Password
							</small>
							<div
								style={{
									border: "0.6px solid rgba(0, 85, 90, 0.50)",
								}}
								className="tw-w-96 tw-h-12 tw-relative tw-text-plain nexa tw-font-normal satoshi tw-rounded-xl">
								{" "}
								<input
									onChange={textChange}
									type={show ? "text" : "password"}
									name="password"
									className="tw-h-full tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-full tw-rounded-xl"
								/>
								<div
									onClick={() => setShow(!show)}
									className="tw-absolute tw-right-2 tw-cursor-pointer tw-top-4">
									{show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
								</div>
							</div>
						</div>
						<div className="">
							<small className="tw-text-base nexa tw-text-plain">
								Confirm Password
							</small>
							<div
								style={{
									border: "0.6px solid rgba(0, 85, 90, 0.50)",
								}}
								className="tw-w-96 tw-h-12 tw-relative tw-text-plain nexa tw-font-normal satoshi tw-rounded-xl">
								{" "}
								<input
									onChange={textChange}
									type={show ? "text" : "password"}
									name="confirmPassword"
									className="tw-h-full tw-pl-6 tw-text-plain tw-text-sm tw-fontnormal satoshi tw-w-full tw-rounded-xl"
								/>
								<div
									onClick={() => setShow(!show)}
									className="tw-absolute tw-right-2 tw-cursor-pointer tw-top-4">
									{show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
								</div>
							</div>
						</div>
					</div>
					<h6 className="tw-font-normal tw-cursor-pointer tw-mt-4 mulish tw-text-base tw-text-[#9FA2B4] tw-text-center">
						Forgot password?
					</h6>
					<div className="tw-flex tw-gap-4">
						<input
							type="checkbox"
							value={terms}
							onChange={e => setTerms(e.target.checked)}
							className="tw-rounded tw-border tw-border-[#2F75FD] tw-bg-[#CDE1FF]"
						/>
						<span className="tw-text-xs tw-text-opacity-80 tw-text-black general tw-font-normal">
							I agree to the{" "}
							<span
								onClick={() => navigate("/terms")}
								className="tw-underline tw-text-xs tw-text-opacity-80 tw-text-black general tw-font-normal tw-cursor-pointer">
								terms and condition
							</span>{" "}
							and{" "}
							<span
								onClick={() => navigate("/policy")}
								className="tw-underline tw-text-xs tw-text-opacity-80 tw-text-black general tw-font-normal tw-cursor-pointer">
								privacy policy
							</span>{" "}
							<br />
							governing the get-2-know service{" "}
						</span>
					</div>
					<div className="tw-flex tw-justify-center tw-mt-4">
						{/* <button
							className="tw-h-10 tw-bg-main tw-w-80 tw-rounded-xl tw-font-semibold tw-text-white satoshi tw-text-bbase"
							type="submit">
							Sign UP
						</button> */}
						<Button
							onClick={handleSubmit}
							loading={loading}
							disabled={!terms}
							title={"Sign UP"}
							width={
								"tw-h-10 tw-bg-main tw-w-80 tw-rounded-xl tw-font-semibold tw-text-white satoshi tw-text-bbase"
							}
							// style={{
							// 	boxShadow: "0px 4px 12px 0px rgba(55, 81, 255, 0.24)",
							// }}
						/>
					</div>
					<p className="tw-text-center nexa tw-text-[#06094F] tw-pt-2">
						Already have an account?{" "}
						<span
							onClick={() => navigate("/login")}
							className="tw-font-bold tw-cursor-pointer">
							Log In
						</span>
					</p>
				</form>
			</div>
		</div>
	);
};
export default Register;
